import { DefaultLayout } from '../layout/default';
import { NotFound, SettingLayout, StandardParent } from '../components';
import {
    AdministrationPath,
    ConfigurationPath,
    FinancePath,
    GestionUserPath,
    Grouped,
    PaiementPath,
    ReportPath,
    StockPath,
    SuperDealerPath,
    VentePath
} from './registration';
import { HomePage } from '../components/home/home-page';
import { SignInLayout } from '../layout/sign-in-layout';

export default [
    {
        isServiceConstraint: true,
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <HomePage />,
                code: [{ code: 'DEFAULT_WEB_USER_SERVICE' }]
            },
            {
                path: '/',
                dependencies: ['PrincipalMenu'],
                isVisible: true,
                name: 'Dashboard',
                element: <HomePage />,
                code: [{ code: 'DEFAULT_WEB_USER_SERVICE' }]
            },
            {
                isLayout: true,
                element: <SettingLayout />,
                children: [
                    {
                        index: true,
                        code: GestionUserPath.MonCompteViewLayoutPath.code,
                        element: (
                            <GestionUserPath.MonCompteViewLayoutPath.Component />
                        )
                    },
                    {
                        ...GestionUserPath.MonCompteViewLayoutPath
                    },
                    {
                        ...AdministrationPath.DroitAccesViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .DroitAccesViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.DroitAccesViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.DroitAccesCreatePath
                            },
                            {
                                ...AdministrationPath.DroitAccesDetailPath
                            }
                        ]
                    },
                    // {
                    //     name: 'Test Config',
                    //     dependencies: ['ConfigMenu'],
                    //     isVisible: true,
                    //     isDropDownMenu: true,
                    //     children: [
                    //         {
                    //             name: 'Admin Test',
                    //             path: '/test-checkp/ttest-checkp1',
                    //             isVisible: true,
                    //             isChildren: true,
                    //             dependencies: ['ConfigMenu'],
                    //             code: [
                    //                 { code: 'test' },
                    //                 CODE_SERVICE.SERVICE_CODE_REVENDEUR
                    //                     .CODE_SERVICE_MODULE_VENTE
                    //                     .INITIER_DISPATCHING_SIM
                    //             ],
                    //             element: <StandardParent />,
                    //             children: [
                    //                 {
                    //                     index: true,
                    //                     code: AdministrationPath
                    //                         .DroitAccesOrganisationViewLayoutPath
                    //                         .code,
                    //                     element: (
                    //                         <AdministrationPath.DroitAccesOrganisationViewLayoutPath.Component />
                    //                     )
                    //                 },
                    //                 {
                    //                     ...AdministrationPath.DroitAccesOrganisationDetailPath
                    //                 }
                    //             ]
                    //         }
                    //     ]
                    // },
                    {
                        ...AdministrationPath.DroitAccesOrganisationViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .DroitAccesOrganisationViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.DroitAccesOrganisationViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.DroitAccesOrganisationDetailPath
                            }
                        ]
                    },
                    {
                        ...AdministrationPath.SystServiceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .SystServiceViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.SystServiceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.SystServiceDetailPath
                            },
                            {
                                ...AdministrationPath.ServiceCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ProfilUserDedierPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ProfilUserDedierPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ProfilUserDedierPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ProfilUserDedierDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ProfilPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ProfilPath.code,
                                element: (
                                    <ConfigurationPath.ProfilPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ProfilCreatePath
                            },
                            {
                                ...ConfigurationPath.ProfilDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TunnelViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.TunnelViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.TunnelViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TunnelDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MarchandGeneralSettingPath
                    },
                    {
                        ...ConfigurationPath.CreationAgenceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .CreationAgenceViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.CreationAgenceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CreationAgenceDetailPath
                            },
                            {
                                ...ConfigurationPath.CreationAgenceCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.AffiliationAgentLivreurViewLayoutSettingPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .AffiliationAgentLivreurViewLayoutSettingPath
                                    .code,
                                element: (
                                    <ConfigurationPath.AffiliationAgentLivreurViewLayoutSettingPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.AffiliationAgentLivreurDetailSettingPath
                            },
                            {
                                ...ConfigurationPath.AffiliationAgentLivreurCreationSettingPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TypeStockViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.TypeStockViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.TypeStockViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TypeStockDetailPath
                            },
                            {
                                ...ConfigurationPath.TypeStockCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ImputationStockViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ImputationStockViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ImputationStockViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ImputationStockDetailPath
                            },
                            {
                                ...ConfigurationPath.ImputationStockCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ImputationCaisseViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ImputationCaisseViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ImputationCaisseViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ImputationCaisseDetailPath
                            },
                            {
                                ...ConfigurationPath.ImputationCaisseCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TransfertStockViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .TransfertStockViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.TransfertStockViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TransfertStockDetailPath
                            },
                            {
                                ...ConfigurationPath.TransfertStockCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.PresenceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.PresenceViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.PresenceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.PresenceDetailPath
                            },
                            {
                                ...ConfigurationPath.PresenceCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TarifRessourceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .TarifRessourceViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.TarifRessourceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TarifRessourceDetailPath
                            },
                            {
                                ...ConfigurationPath.TarifRessourceCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TarifChargeViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .TarifChargeViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.TarifChargeViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TarifChargeDetailPath
                            },
                            {
                                ...ConfigurationPath.TarifChargeCreationPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CaisseViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.CaisseViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.CaisseViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CaisseDetailPath
                            },
                            {
                                ...ConfigurationPath.CaisseCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.PartenaireViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.PartenaireViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.PartenaireViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.PartenaireDetailPath
                            },
                            {
                                ...ConfigurationPath.PartenaireCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ServiceGenerateurViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ServiceGenerateurViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ServiceGenerateurViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ServiceGenerateurDetailPath
                            },
                            {
                                ...ConfigurationPath.ServiceGenerateurCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.RessourceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.RessourceViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.RessourceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.RessourceDetailPath
                            },
                            {
                                ...ConfigurationPath.RessourceCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CompteImputationViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .CompteImputationViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.CompteImputationViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CompteImputationDetailPath
                            },
                            {
                                ...ConfigurationPath.CompteImputationCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ChargeViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ChargeViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ChargeViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ChargeDetailPath
                            },
                            {
                                ...ConfigurationPath.ChargeCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.FonctionViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.FonctionViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.FonctionViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.FonctionDetailPath
                            },
                            {
                                ...ConfigurationPath.FonctionCreatePath
                            }
                            // {
                            //     ...ConfigurationPath.FonctionUpdatePath
                            // }
                        ]
                    },
                    {
                        ...ConfigurationPath.FonctionUpdateViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .FonctionUpdateViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.FonctionUpdateViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.FonctionUpdateDetailPath
                            },
                            {
                                ...ConfigurationPath.FonctionUpdateCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.BanqueViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.BanqueViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.BanqueViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.BanqueDetailPath
                            },
                            {
                                ...ConfigurationPath.BanqueCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MonnaieLocaleViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .MonnaieLocaleViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.MonnaieLocaleViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.MonnaieLocaleDetailPath
                            },
                            {
                                ...ConfigurationPath.MonnaieLocaleCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ProduitViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ProduitViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ProduitViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ModifierColonneProduitAirtimePath
                            },
                            {
                                ...ConfigurationPath.ProduitDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.DirectionPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.DirectionPath.code,
                                element: (
                                    <ConfigurationPath.DirectionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.DirectionCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.SecteurPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.SecteurPath.code,
                                element: (
                                    <ConfigurationPath.SecteurPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.SecteurCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.IngredientPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.IngredientPath.code,
                                element: (
                                    <ConfigurationPath.IngredientPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.IngredientCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MoyenRetracagePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.MoyenRetracagePath.code,
                                element: (
                                    <ConfigurationPath.MoyenRetracagePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.MoyenRetracageCreatePath
                            },
                            {
                                ...ConfigurationPath.MoyenRetracageDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ArticleColisPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ArticleColisPath.code,
                                element: (
                                    <ConfigurationPath.ArticleColisPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ArticleColisCreatePath
                            },
                            {
                                ...ConfigurationPath.ArticleColisDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CrediterCompteViaInstitutFinViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .CrediterCompteViaInstitutFinViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.CrediterCompteViaInstitutFinViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CrediterCompteViaInstitutFinDetailPath
                            },
                            {
                                ...ConfigurationPath.CrediterCompteViaInstitutFinCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ProgrammerPausePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ProgrammerPausePath
                                    .code,
                                element: (
                                    <ConfigurationPath.ProgrammerPausePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ProgrammerPauseCreatePath
                            },
                            {
                                ...ConfigurationPath.ProgrammerPauseDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.PointLivraisonPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.PointLivraisonPath.code,
                                element: (
                                    <ConfigurationPath.PointLivraisonPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.PointLivraisonCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ApprovisionnementStockMarchandProduitMaterielPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ApprovisionnementStockMarchandProduitMaterielPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ApprovisionnementStockMarchandProduitMaterielPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ApprovisionnementStockMarchandProduitMaterielCreatePath
                            },
                            {
                                ...ConfigurationPath.ApprovisionnementStockMarchandProduitMaterielDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CashCollectionViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .CashCollectionViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.CashCollectionViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CashCollectionDetailPath
                            },
                            {
                                ...ConfigurationPath.CashCollectionCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ArticleProduitMaterielPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ArticleProduitMaterielPath.code,
                                element: (
                                    <ConfigurationPath.ArticleProduitMaterielPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ArticleProduitMaterielCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.AbonnementMarchandPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.AbonnementMarchandPath
                                    .code,
                                element: (
                                    <ConfigurationPath.AbonnementMarchandPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.AbonnementMarchandCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.DeclarationRessourcePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.DeclarationRessourcePath
                                    .code,
                                element: (
                                    <ConfigurationPath.DeclarationRessourcePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.DeclarationRessourceCreatePath
                            },
                            {
                                ...ConfigurationPath.DeclarationRessourceDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CommanderEnLignePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.CommanderEnLignePath
                                    .code,
                                element: (
                                    <ConfigurationPath.CommanderEnLignePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CommanderEnLigneCreatePath
                            },
                            {
                                ...ConfigurationPath.CommanderEnLigneDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ShutDownAndUpApiPartnerViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ShutDownAndUpApiPartnerViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ShutDownAndUpApiPartnerViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ShutDownAndUpApiPartnerDetailPath
                            },
                            {
                                ...ConfigurationPath.ShutDownAndUpApiPartnerCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.RestitutionPertePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.RestitutionPertePath
                                    .code,
                                element: (
                                    <ConfigurationPath.RestitutionPertePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.RestitutionPerteCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ComptesBancairePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ComptesBancairePath
                                    .code,
                                element: (
                                    <ConfigurationPath.ComptesBancairePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ComptesBancaireCreatePath
                            },
                            {
                                ...ConfigurationPath.ComptesBancaireDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ComptesDistributeurPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ComptesDistributeurPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ComptesDistributeurPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ComptesDistributeurCreatePath
                            },
                            {
                                ...ConfigurationPath.ComptesDistributeurDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ComptesMobileMoneyPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ComptesMobileMoneyPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ComptesMobileMoneyPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ComptesMobileMoneyCreatePath
                            },
                            {
                                ...ConfigurationPath.ComptesMobileMoneyDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.AchatMarchandToMarchandPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .AchatMarchandToMarchandPath.code,
                                element: (
                                    <ConfigurationPath.AchatMarchandToMarchandPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.DepotPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.DepotPath.code,
                                element: (
                                    <ConfigurationPath.DepotPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.OperationBancaireCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.HomologuerPointLivraisonPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .HomologuerPointLivraisonPath.code,
                                element: (
                                    <ConfigurationPath.HomologuerPointLivraisonPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.HomologuerPointLivraisonCreatePath
                            },
                            {
                                ...ConfigurationPath.HomologuerPointLivraisonDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.TableMangerPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.TableMangerPath.code,
                                element: (
                                    <ConfigurationPath.TableMangerPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.TableMangerCreatePath
                            },
                            {
                                ...ConfigurationPath.TableMangerDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.NotificationEvolutionRevendeurPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .NotificationEvolutionRevendeurPath.code,
                                element: (
                                    <ConfigurationPath.NotificationEvolutionRevendeurPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.NotificationEvolutionRevendeurCreatePath
                            },
                            {
                                ...ConfigurationPath.NotificationEvolutionRevendeurDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.PaiementCompteExecutionPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .PaiementCompteExecutionPath.code,
                                element: (
                                    <ConfigurationPath.PaiementCompteExecutionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.PaiementCompteExecutionDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.DispatchingPaiementCompteExecutionPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .DispatchingPaiementCompteExecutionPath
                                    .code,
                                element: (
                                    <ConfigurationPath.DispatchingPaiementCompteExecutionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.DispatchingPaiementCompteExecutionDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.PaiementCaisseSectoriellePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .PaiementCaisseSectoriellePath.code,
                                element: (
                                    <ConfigurationPath.PaiementCaisseSectoriellePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.PaiementCaisseSectorielleDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.InstructionPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.InstructionPath.code,
                                element: (
                                    <ConfigurationPath.InstructionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.InstructionDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.SuperDealerViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .SuperDealerViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.SuperDealerViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.SuperDealerDetailPath
                            },
                            {
                                ...ConfigurationPath.SuperDealerCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MarchandShowRoomSettingPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .MarchandShowRoomSettingPath.code,
                                element: (
                                    <ConfigurationPath.MarchandShowRoomSettingPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.MarchandShowRoomCreateSettingPath
                            },
                            {
                                ...ConfigurationPath.MarchandShowRoomDetailSettingPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MarchandArticlePath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.MarchandArticlePath
                                    .code,
                                element: (
                                    <ConfigurationPath.MarchandArticlePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.MarchandDetailArticlePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MarchandAbonnementSettingPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .MarchandAbonnementSettingPath.code,
                                element: (
                                    <ConfigurationPath.MarchandAbonnementSettingPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.UploadFichierPresenceViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .UploadFichierPresenceViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.UploadFichierPresenceViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.UploadFichierPresenceCreatePath
                            },
                            {
                                ...ConfigurationPath.UploadFichierPresenceDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.UploadFichierReleveFlashViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .UploadFichierReleveFlashViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.UploadFichierReleveFlashViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.UploadFichierReleveFlashCreatePath
                            },
                            {
                                ...ConfigurationPath.UploadFichierReleveFlashDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ActivateurViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ActivateurViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ActivateurViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ActivateurDetailPath
                            },
                            {
                                ...ConfigurationPath.ActivateurCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.MarchandConfigSimSettingPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .MarchandConfigSimSettingPath.code,
                                element: (
                                    <ConfigurationPath.MarchandConfigSimSettingPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.LimitCreditViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .LimitCreditViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.LimitCreditViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.LimitCreditDetailPath
                            },
                            {
                                ...ConfigurationPath.LimitCreditCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.DerogationSettingPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.DerogationSettingPath
                                    .code,
                                element: (
                                    <ConfigurationPath.DerogationSettingPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.DerogationCreateSettingPath
                            },
                            {
                                ...ConfigurationPath.DerogationDetailSettingPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ConfigurationKindistributionViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ConfigurationKindistributionViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ConfigurationKindistributionViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ConfigurationKindistributionDetailPath
                            },
                            {
                                ...ConfigurationPath.ConfigurationKindistributionCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ConfigEquipeTechniqueViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ConfigEquipeTechniqueViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ConfigEquipeTechniqueViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ConfigEquipeTechniqueCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ConfigurationSenderViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ConfigurationSenderViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ConfigurationSenderViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ConfigurationSenderDetailPath
                            },
                            {
                                ...ConfigurationPath.ConfigurationSenderCreatePath
                            }
                        ]
                    },
                    {
                        ...FinancePath.CompteBancaireOrganisationViewLayoutPath,
                        element: <StandardParent />,
                        children: [
                            {
                                index: true,
                                code: FinancePath
                                    .CompteBancaireOrganisationViewLayoutPath
                                    .code,
                                element: (
                                    <FinancePath.CompteBancaireOrganisationViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.CompteBancaireOrganisationDetailPath
                            },
                            {
                                ...FinancePath.CompteBancaireOrganisationCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.ServiceClient,
                element: <StandardParent />,
                children: [
                    {
                        ...AdministrationPath.NotificationPushViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .NotificationPushViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.NotificationPushViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.NotificationPushCreatePath
                            }
                        ]
                    },
                    {
                        ...AdministrationPath.OperationServiceClientViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .OperationServiceClientViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.OperationServiceClientViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...AdministrationPath.ServiceClientFormulaireViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .ServiceClientFormulaireViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.ServiceClientFormulaireViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.ServiceClientFormDetailPath
                            },
                            {
                                ...AdministrationPath.ServiceClientFormulaireCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.ApplicationMobile,
                element: <StandardParent />,
                children: [
                    {
                        ...AdministrationPath.ApplicationMobileViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .ApplicationMobileViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.ApplicationMobileViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.ApplicationMobileCreatePath
                            }
                        ]
                    },
                    {
                        ...AdministrationPath.VersionApplicationViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: AdministrationPath
                                    .VersionApplicationViewLayoutPath.code,
                                element: (
                                    <AdministrationPath.VersionApplicationViewLayoutPath.Component />
                                )
                            },
                            {
                                ...AdministrationPath.VersionApplicationDetailPath
                            },
                            {
                                ...AdministrationPath.VersionApplicationCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.KinDistribution,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.FondsRoulementKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .FondsRoulementKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.FondsRoulementKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportStockKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportStockKinDBViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportStockKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportStockSimKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportStockSimKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportStockSimKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCaisseKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCaisseKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportCaisseKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportRetraitNonDeclareKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportRetraitNonDeclareKinDBViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportRetraitNonDeclareKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportPartenaireKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportPartenaireKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportPartenaireKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },

                    {
                        ...ReportPath.RapportAgentKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportAgentKinDBViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportAgentKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportBalanceKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportBalanceKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportBalanceKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportInstitutionFinanciereKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportInstitutionFinanciereKinDBViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportInstitutionFinanciereKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportLiaisonKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportLiaisonKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportLiaisonKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCautionKinDBViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCautionKinDBViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportCautionKinDBViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportComptabilisationKinDBPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportComptabilisationKinDBPath.code,
                                element: (
                                    <ReportPath.RapportComptabilisationKinDBPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.Revendeur,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.VenteRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.VenteRevendeurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.VenteRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.AgentRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.AgentRevendeurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.AgentRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.CaisseRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.CaisseRevendeurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.CaisseRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportBalanceRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportBalanceRevendeurViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportBalanceRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportLiaisonRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportLiaisonRevendeurViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportLiaisonRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCommissionRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCommissionRevendeurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportCommissionRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportRecruteurRevendeurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportRecruteurRevendeurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportRecruteurRevendeurViewLayoutPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.CashCollecteur,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.FondsRoulementCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .FondsRoulementCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.FondsRoulementCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCaisseCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCaisseCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportCaisseCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportPartenaireCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportPartenaireCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportPartenaireCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportAgentCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportAgentCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportAgentCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportBalanceCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportBalanceCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportBalanceCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportInstitutionFinanciereCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportInstitutionFinanciereCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportInstitutionFinanciereCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportLiaisonCashCollecteurViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportLiaisonCashCollecteurViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportLiaisonCashCollecteurViewLayoutPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.RapportGeneral,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.RapportEvolutionBalanceOrganisationViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportEvolutionBalanceOrganisationViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportEvolutionBalanceOrganisationViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCaisseEmoneyOrganisationViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCaisseEmoneyOrganisationViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportCaisseEmoneyOrganisationViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportEvolutionStockOrganisationViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportEvolutionStockOrganisationViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportEvolutionStockOrganisationViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportPresenceAgentViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportPresenceAgentViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportPresenceAgentViewLayoutPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.ReportRegularisation,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.RapportRegularisationProduitPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportRegularisationProduitPath.code,
                                element: (
                                    <ReportPath.RapportRegularisationProduitPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportRegularisationCaissePath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportRegularisationCaissePath
                                    .code,
                                element: (
                                    <ReportPath.RapportRegularisationCaissePath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportRegularisationStockPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportRegularisationStockPath
                                    .code,
                                element: (
                                    <ReportPath.RapportRegularisationStockPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.MonnaieLocalMenu,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.RapportGainPerteChangePath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportGainPerteChangePath
                                    .code,
                                element: (
                                    <ReportPath.RapportGainPerteChangePath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...FinancePath.TauxKindistributionViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath
                                    .TauxKindistributionViewLayoutPath.code,
                                element: (
                                    <FinancePath.TauxKindistributionViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.TauxKindistributionDetailPath
                            },
                            {
                                ...FinancePath.TauxKindistributionCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...ReportPath.ReleveEmoneyViewLayoutPath,
                element: <StandardParent />,
                isChildren: true,
                children: [
                    {
                        index: true,
                        code: ReportPath.ReleveEmoneyViewLayoutPath.code,
                        element: (
                            <ReportPath.ReleveEmoneyViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ConfigurationPath.UploadFichierReleveEmoneyViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath
                            .UploadFichierReleveEmoneyViewLayoutPath.code,
                        element: (
                            <ConfigurationPath.UploadFichierReleveEmoneyViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.UploadFichierReleveEmoneyCreatePath
                    },
                    {
                        ...ConfigurationPath.UploadFichierReleveEmoneyDetailPath
                    }
                ]
            },
            {
                ...FinancePath.CashbookViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.CashbookViewLayoutPath.code,
                        element: (
                            <FinancePath.CashbookViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ReportPath.ListMouvementPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath.ListMouvementPath.code,
                        element: <ReportPath.ListMouvementPath.Component />
                    },
                    {
                        ...ReportPath.DetailMouvementPath
                    }
                ]
            },
            {
                ...ConfigurationPath.MarchandVersementListSettingPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath.MarchandVersementListSettingPath
                            .code,
                        element: (
                            <ConfigurationPath.MarchandVersementListSettingPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.MarchandVersementDetailSettingPath
                    },
                    {
                        ...ConfigurationPath.MarchandVersementCreationSettingPath
                    }
                ]
            },
            {
                ...FinancePath.ApproCashListPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.ApproCashListPath.code,
                        element: <FinancePath.ApproCashListPath.Component />
                    },
                    {
                        ...FinancePath.ApproCashDetailPath
                    },
                    {
                        ...FinancePath.ApproCashCreationPath
                    }
                ]
            },
            {
                ...FinancePath.TauxActifsViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.TauxActifsViewLayoutPath.code,
                        element: (
                            <FinancePath.TauxActifsViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ReportPath.RapportActiviteViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath.RapportActiviteViewLayoutPath.code,
                        element: (
                            <ReportPath.RapportActiviteViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ReportPath.ListMouvementOperationPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath.ListMouvementOperationPath.code,
                        element: (
                            <ReportPath.ListMouvementOperationPath.Component />
                        )
                    },
                    {
                        ...ReportPath.DetailMouvementOperationPath
                    }
                ]
            },
            {
                ...ReportPath.ReleveCompteViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath.ReleveCompteViewLayoutPath.code,
                        element: (
                            <ReportPath.ReleveCompteViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ReportPath.ReleveCaisseViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath.ReleveCaisseViewLayoutPath.code,
                        element: (
                            <ReportPath.ReleveCaisseViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ConfigurationPath.GestionRevendeurViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath.GestionRevendeurViewLayoutPath
                            .code,
                        element: (
                            <ConfigurationPath.GestionRevendeurViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.GestionRevendeurDetailPath
                    },
                    {
                        ...ConfigurationPath.GestionRevendeurCreatePath
                    }
                ]
            },
            {
                ...FinancePath.CrediterBalanceCautionViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.CrediterBalanceCautionViewLayoutPath
                            .code,
                        element: (
                            <FinancePath.CrediterBalanceCautionViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.CrediterBalanceCautionDetailPath
                    },
                    {
                        ...FinancePath.CrediterBalanceCautionCreatePath
                    }
                ]
            },
            {
                ...Grouped.TransfertCaisse,
                element: <StandardParent />,
                children: [
                    {
                        ...FinancePath.TransfertInterInstitutionnelViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath
                                    .TransfertInterInstitutionnelViewLayoutPath
                                    .code,
                                element: (
                                    <FinancePath.TransfertInterInstitutionnelViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.TransfertInterInstitutionnelDetailPath
                            },
                            {
                                ...FinancePath.TransfertInterInstitutionnelCreatePath
                            }
                        ]
                    },
                    // {
                    //     ...FinancePath.VirementKinDBViewLayoutPath,
                    //     element: <StandardParent />,
                    //     isChildren: true,
                    //     children: [
                    //         {
                    //             index: true,
                    //             code: FinancePath.VirementKinDBViewLayoutPath
                    //                 .code,
                    //             element: (
                    //                 <FinancePath.VirementKinDBViewLayoutPath.Component />
                    //             )
                    //         },
                    //         {
                    //             ...FinancePath.VirementKinDBDetailPath
                    //         },
                    //         {
                    //             ...FinancePath.VirementKinDBCreatePath
                    //         }
                    //     ]
                    // },
                    {
                        ...FinancePath.TransfertViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath.TransfertViewLayoutPath.code,
                                element: (
                                    <FinancePath.TransfertViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.TransfertDetailPath
                            },
                            {
                                ...FinancePath.TransfertCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.VerificationSolde,
                element: <StandardParent />,
                children: [
                    {
                        ...ConfigurationPath.ConfirmationSoldeCompteViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ConfirmationSoldeCompteViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.ConfirmationSoldeCompteViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ConfirmationSoldeCompteDetailPath
                            },
                            {
                                ...ConfigurationPath.ConfirmationSoldeCompteCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.Caisse,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.ReleveCaisseEmoneyViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .ReleveCaisseEmoneyViewLayoutPath.code,
                                element: (
                                    <ReportPath.ReleveCaisseEmoneyViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.RegularisationCaisseEmoneyViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .RegularisationCaisseEmoneyViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.RegularisationCaisseEmoneyViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.RegularisationCaisseEmoneyDetailPath
                            },
                            {
                                ...ConfigurationPath.RegularisationCaisseEmoneyCreatePath
                            }
                        ]
                    },
                    {
                        ...ReportPath.CasLitigieuxEmoneyViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .CasLitigieuxEmoneyViewLayoutPath.code,
                                element: (
                                    <ReportPath.CasLitigieuxEmoneyViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ReportPath.CasLitigieuxEmoneyDetailPath
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportCommandeEmoneyViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportCommandeEmoneyViewLayoutPath.code,
                                element: (
                                    <ReportPath.RapportCommandeEmoneyViewLayoutPath.Component />
                                )
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.InstitutionFinanciereRegroupement,
                element: <StandardParent />,
                children: [
                    {
                        ...ReportPath.RapportOperationBancaireViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath
                                    .RapportOperationBancaireViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportOperationBancaireViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ConfigurationInstructionViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .ConfigurationInstructionViewLayoutPath
                                    .code,
                                element: (
                                    <ConfigurationPath.ConfigurationInstructionViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ConfigurationInstructionDetailPath
                            },
                            {
                                ...ConfigurationPath.ConfigurationInstructionCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.CrediterCautionPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.CrediterCautionPath
                                    .code,
                                element: (
                                    <ConfigurationPath.CrediterCautionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.CrediterCautionCreatePath
                            },
                            {
                                ...ConfigurationPath.CrediterCautionDetailPath
                            }
                        ]
                    },
                    {
                        ...FinancePath.OperationBancaireViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath
                                    .OperationBancaireViewLayoutPath.code,
                                element: (
                                    <FinancePath.OperationBancaireViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.OperationBancaireDetailPath
                            },
                            {
                                ...FinancePath.OperationBancaireCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.UploadFichierViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath
                                    .UploadFichierViewLayoutPath.code,
                                element: (
                                    <ConfigurationPath.UploadFichierViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.UploadFichierCreatePath
                            },
                            {
                                ...ConfigurationPath.UploadFichierDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.RemboursementCautionPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.RemboursementCautionPath
                                    .code,
                                element: (
                                    <ConfigurationPath.RemboursementCautionPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.RemboursementCautionCreatePath
                            }
                        ]
                    },
                    {
                        ...FinancePath.TransfertFondsViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath.TransfertFondsViewLayoutPath
                                    .code,
                                element: (
                                    <FinancePath.TransfertFondsViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.TransfertFondsDetailPath
                            },
                            {
                                ...FinancePath.TransfertFondsCreatePath
                            }
                        ]
                    },
                    {
                        ...FinancePath.VirementViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath.VirementViewLayoutPath.code,
                                element: (
                                    <FinancePath.VirementViewLayoutPath.Component />
                                )
                            },
                            {
                                ...FinancePath.VirementDetailPath
                            },
                            {
                                ...FinancePath.VirementCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...Grouped.StockRegroupement,
                element: <StandardParent />,
                children: [
                    {
                        ...StockPath.ApprovisionnementSimViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: StockPath
                                    .ApprovisionnementSimViewLayoutPath.code,
                                element: (
                                    <StockPath.ApprovisionnementSimViewLayoutPath.Component />
                                )
                            },
                            {
                                ...StockPath.ApprovisionnementSimDetailPath
                            },
                            {
                                ...StockPath.ApprovisionnementSimCreatePath
                            }
                        ]
                    },
                    {
                        ...ReportPath.CasLitigieuxViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.CasLitigieuxViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.CasLitigieuxViewLayoutPath.Component />
                                )
                            },
                            {
                                ...ReportPath.CasLitigieuxDetailPath
                            }
                        ]
                    },
                    {
                        ...ReportPath.RapportTropPercuViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.RapportTropPercuViewLayoutPath
                                    .code,
                                element: (
                                    <ReportPath.RapportTropPercuViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...StockPath.FacturationStockViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: StockPath.FacturationStockViewLayoutPath
                                    .code,
                                element: (
                                    <StockPath.FacturationStockViewLayoutPath.Component />
                                )
                            },
                            {
                                ...StockPath.FacturationStockDetailPath
                            },
                            {
                                ...StockPath.FacturationStockCreatePath
                            }
                        ]
                    },
                    {
                        ...FinancePath.ReportStockSuperDealerViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: FinancePath
                                    .ReportStockSuperDealerViewLayoutPath.code,
                                element: (
                                    <FinancePath.ReportStockSuperDealerViewLayoutPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...ReportPath.CommandeFlashPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ReportPath.CommandeFlashPath.code,
                                element: (
                                    <ReportPath.CommandeFlashPath.Component />
                                )
                            }
                        ]
                    },
                    {
                        ...SuperDealerPath.RegularisationSuperDealerViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: SuperDealerPath
                                    .RegularisationSuperDealerViewLayoutPath
                                    .code,
                                element: (
                                    <SuperDealerPath.RegularisationSuperDealerViewLayoutPath.Component />
                                )
                            },
                            {
                                ...SuperDealerPath.RegularisationSuperDealerDetailPath
                            },
                            {
                                ...SuperDealerPath.RegularisationSuperDealerCreatePath
                            }
                        ]
                    },
                    {
                        ...SuperDealerPath.RegularisationSpecialeViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: SuperDealerPath
                                    .RegularisationSpecialeViewLayoutPath.code,
                                element: (
                                    <SuperDealerPath.RegularisationSpecialeViewLayoutPath.Component />
                                )
                            },
                            {
                                ...SuperDealerPath.RegularisationSpecialeDetailPath
                            },
                            {
                                ...SuperDealerPath.RegularisationSpecialeCreatePath
                            }
                        ]
                    },
                    {
                        ...StockPath.TransfertSimViewLayoutPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: StockPath.TransfertSimViewLayoutPath.code,
                                element: (
                                    <StockPath.TransfertSimViewLayoutPath.Component />
                                )
                            },
                            {
                                ...StockPath.TransfertSimDetailPath
                            },
                            {
                                ...StockPath.TransfertSimCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...ReportPath.RapportComptabilisationCompteOrganisationViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ReportPath
                            .RapportComptabilisationCompteOrganisationViewLayoutPath
                            .code,
                        element: (
                            <ReportPath.RapportComptabilisationCompteOrganisationViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...ConfigurationPath.CrediterBalanceLotViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath.CrediterBalanceLotViewLayoutPath
                            .code,
                        element: (
                            <ConfigurationPath.CrediterBalanceLotViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.CrediterBalanceLotDetailPath
                    },
                    {
                        ...ConfigurationPath.CrediterBalanceLotCreatePath
                    }
                ]
            },
            {
                ...ConfigurationPath.AutorisationLotReferenceViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath
                            .AutorisationLotReferenceViewLayoutPath.code,
                        element: (
                            <ConfigurationPath.AutorisationLotReferenceViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.AutorisationLotReferenceDetailPath
                    },
                    {
                        ...ConfigurationPath.AutorisationLotReferenceCreatePath
                    }
                ]
            },
            {
                ...Grouped.BudgetRegroupement,
                element: <StandardParent />,
                children: [
                    {
                        ...ConfigurationPath.ExercicePath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ExercicePath.code,
                                element: (
                                    <ConfigurationPath.ExercicePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ExerciceDetailPath
                            },
                            {
                                ...ConfigurationPath.ExerciceCreatePath
                            }
                        ]
                    },

                    {
                        ...ConfigurationPath.ReevaluationChargePath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ReevaluationChargePath
                                    .code,
                                element: (
                                    <ConfigurationPath.ReevaluationChargePath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ReevaluationChargeFixeCreatePath
                            },
                            {
                                ...ConfigurationPath.ReevaluationChargeVariableCreatePath
                            },
                            {
                                ...ConfigurationPath.ReevaluationChargeDetailPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.BudgetPrevisionnelPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.BudgetPrevisionnelPath
                                    .code,
                                element: (
                                    <ConfigurationPath.BudgetPrevisionnelPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.BudgetPrevisionnelAmendementChargeFixeCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetPrevisionnelAmendementChargeVariableCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetPrevisionnelAmendementRessourceCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetPrevisionnelDetailPath
                            },
                            {
                                ...ConfigurationPath.AmendementElaborationSettingPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.BudgetAdditionnelPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.BudgetAdditionnelPath
                                    .code,
                                element: (
                                    <ConfigurationPath.BudgetAdditionnelPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.BudgetAdditionnelAmendementChargeFixeCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetAdditionnelAmendementChargeVariableCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetAdditionnelAmendementRessourceCreatePath
                            },
                            {
                                ...ConfigurationPath.BudgetAdditionnelDetailPath
                            },
                            {
                                ...ConfigurationPath.AmendementElaborationAdditionnelSettingPath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.ElaborationPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.ElaborationPath.code,
                                element: (
                                    <ConfigurationPath.ElaborationPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.ElaborationDetailPath
                            },
                            {
                                ...ConfigurationPath.ElaborationAdditionnelCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationPrevisionnelCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationVoirPlusPath
                            },
                            {
                                ...ConfigurationPath.ElaborationVoirPlusChargeFixePath
                            },
                            {
                                ...ConfigurationPath.ElaborationVoirPlusChargeVariablePath
                            },
                            {
                                ...ConfigurationPath.ElaborationVoirPlusRessourcePath
                            },
                            {
                                ...ConfigurationPath.ElaborationAmendementPath
                            },
                            {
                                ...ConfigurationPath.ElaborationCompletionPath
                            },
                            {
                                ...ConfigurationPath.ElaborationAdditionnelFixeCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationAdditionnelVariableCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationAdditionnelRessourceCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationPrevisionnelFixeCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationPrevisionnelVariableCreatePath
                            },
                            {
                                ...ConfigurationPath.ElaborationPrevisionnelRessourceCreatePath
                            }
                        ]
                    },
                    {
                        ...ConfigurationPath.AmenedementPath,
                        element: <StandardParent />,
                        isChildren: true,
                        children: [
                            {
                                index: true,
                                code: ConfigurationPath.AmenedementPath.code,
                                element: (
                                    <ConfigurationPath.AmenedementPath.Component />
                                )
                            },
                            {
                                ...ConfigurationPath.AmenedementDetailPath
                            },
                            {
                                ...ConfigurationPath.AmenedementCreatePath
                            }
                        ]
                    }
                ]
            },
            {
                ...ConfigurationPath.ObjectifsViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath.ObjectifsViewLayoutPath.code,
                        element: (
                            <ConfigurationPath.ObjectifsViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.ObjectifsCreatePath
                    },
                    {
                        ...ConfigurationPath.ObjectifsDetailPath
                    }
                ]
            },
            {
                ...ConfigurationPath.HeureSupplementaireViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath
                            .HeureSupplementaireViewLayoutPath.code,
                        element: (
                            <ConfigurationPath.HeureSupplementaireViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.HeureSupplementaireCreatePath
                    },
                    {
                        ...ConfigurationPath.HeureSupplementaireDetailPath
                    }
                ]
            },
            {
                ...FinancePath.CrediterCompteViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.CrediterCompteViewLayoutPath.code,
                        element: (
                            <FinancePath.CrediterCompteViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.CrediterCompteDetailPath
                    },
                    {
                        ...FinancePath.CrediterCompteCreatePath
                    }
                ]
            },
            {
                ...FinancePath.BalanceOrganisationViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.BalanceOrganisationViewLayoutPath
                            .code,
                        element: (
                            <FinancePath.BalanceOrganisationViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...FinancePath.CompteCautionOrganisationViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath
                            .CompteCautionOrganisationViewLayoutPath.code,
                        element: (
                            <FinancePath.CompteCautionOrganisationViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...FinancePath.CompteTransfertOrganisationViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath
                            .CompteTransfertOrganisationViewLayoutPath.code,
                        element: (
                            <FinancePath.CompteTransfertOrganisationViewLayoutPath.Component />
                        )
                    }
                ]
            },
            {
                ...FinancePath.LigneCreditViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.LigneCreditViewLayoutPath.code,
                        element: (
                            <FinancePath.LigneCreditViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.LigneCreditDetailPath
                    },
                    {
                        ...FinancePath.LigneCreditCreatePath
                    }
                ]
            },
            {
                ...FinancePath.ChangeViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.ChangeViewLayoutPath.code,
                        element: <FinancePath.ChangeViewLayoutPath.Component />
                    },
                    {
                        ...FinancePath.ChangeDetailPath
                    },
                    {
                        ...FinancePath.ChangeCreatePath
                    },
                    {
                        ...FinancePath.ChangeCompteCreatePath
                    }
                ]
            },
            {
                ...FinancePath.ChangeGuichetViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.ChangeGuichetViewLayoutPath.code,
                        element: (
                            <FinancePath.ChangeGuichetViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.ChangeGuichetDetailPath
                    },
                    {
                        ...FinancePath.ChangeGuichetCreatePath
                    }
                ]
            },
            {
                ...FinancePath.EncaissementDecaissementViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.EncaissementDecaissementViewLayoutPath
                            .code,
                        element: (
                            <FinancePath.EncaissementDecaissementViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.EncaissementDecaissementDetailPath
                    },
                    {
                        ...FinancePath.EncaissementDecaissementCreatePath
                    }
                ]
            },
            {
                ...FinancePath.DecaissementViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.DecaissementViewLayoutPath.code,
                        element: (
                            <FinancePath.DecaissementViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.DecaissementDetailPath
                    },
                    {
                        ...FinancePath.DecaissementCreatePath
                    }
                ]
            },
            {
                ...FinancePath.RaisonViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.RaisonViewLayoutPath.code,
                        element: <FinancePath.RaisonViewLayoutPath.Component />
                    },
                    {
                        ...FinancePath.RaisonDetailPath
                    },
                    {
                        ...FinancePath.RaisonCreatePath
                    }
                ]
            },
            {
                ...FinancePath.DepensesViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.DepensesViewLayoutPath.code,
                        element: (
                            <FinancePath.DepensesViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.DepensesDetailPath
                    },
                    {
                        ...FinancePath.DepensesCreatePath
                    }
                ]
            },
            {
                ...FinancePath.DeviseViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.DeviseViewLayoutPath.code,
                        element: <FinancePath.DeviseViewLayoutPath.Component />
                    },
                    {
                        ...FinancePath.DeviseDetailPath
                    },
                    {
                        ...FinancePath.DeviseCreatePath
                    }
                ]
            },
            {
                ...FinancePath.ApproCompteEmoneyViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.ApproCompteEmoneyViewLayoutPath.code,
                        element: (
                            <FinancePath.ApproCompteEmoneyViewLayoutPath.Component />
                        )
                    },
                    {
                        ...FinancePath.ApproCompteEmoneyDetailPath
                    },
                    {
                        ...FinancePath.ApproCompteEmoneyCreatePath
                    }
                ]
            },
            {
                ...VentePath.ClientViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.ClientViewLayoutPath.code,
                        element: <VentePath.ClientViewLayoutPath.Component />
                    },
                    {
                        ...VentePath.ClientDetailPath
                    },
                    {
                        ...VentePath.ClientCreatePath
                    }
                ]
            },
            {
                ...VentePath.CommandeFlashViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.CommandeFlashViewLayoutPath.code,
                        element: (
                            <VentePath.CommandeFlashViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.CommandeFlashDetailPath
                    },
                    {
                        ...VentePath.CommandeFlashCreatePath
                    }
                ]
            },
            {
                ...VentePath.CommandeFlashSenderViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.CommandeFlashSenderViewLayoutPath.code,
                        element: (
                            <VentePath.CommandeFlashSenderViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.CommandeFlashSenderDetailPath
                    }
                ]
            },
            {
                ...VentePath.CommandeEmoneySenderViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.CommandeEmoneySenderViewLayoutPath.code,
                        element: (
                            <VentePath.CommandeEmoneySenderViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.CommandeEmoneySenderDetailPath
                    }
                ]
            },
            {
                ...VentePath.RetraitCashViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.RetraitCashViewLayoutPath.code,
                        element: (
                            <VentePath.RetraitCashViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.RetraitCashDetailPath
                    }
                ]
            },
            {
                ...VentePath.DepotEmoneyViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.DepotEmoneyViewLayoutPath.code,
                        element: (
                            <VentePath.DepotEmoneyViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.DepotEmoneyCreatePath
                    },
                    {
                        ...VentePath.DepotEmoneyDetailPath
                    }
                ]
            },
            {
                ...VentePath.RecouvrementEmoneyViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: VentePath.RecouvrementEmoneyViewLayoutPath.code,
                        element: (
                            <VentePath.RecouvrementEmoneyViewLayoutPath.Component />
                        )
                    },
                    {
                        ...VentePath.RecouvrementEmoneyDetailPath
                    }
                ]
            },
            {
                ...ConfigurationPath.RegularisationStockViewLayoutSettingPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath
                            .RegularisationStockViewLayoutSettingPath.code,
                        element: (
                            <ConfigurationPath.RegularisationStockViewLayoutSettingPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.RegularisationStockDetailSettingPath
                    },
                    {
                        ...ConfigurationPath.RegularisationStockCreationSettingPath
                    }
                ]
            },
            {
                ...StockPath.VenteSimViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: StockPath.VenteSimViewLayoutPath.code,
                        element: <StockPath.VenteSimViewLayoutPath.Component />
                    },
                    {
                        ...StockPath.VenteSimDetailPath
                    },
                    {
                        ...StockPath.VenteSimCreatePath
                    }
                ]
            },
            {
                ...FinancePath.TauxViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: FinancePath.TauxViewLayoutPath.code,
                        element: <FinancePath.TauxViewLayoutPath.Component />
                    },
                    {
                        ...FinancePath.TauxDetailPath
                    },
                    {
                        ...FinancePath.TauxCreatePath
                    }
                ]
            },
            {
                ...PaiementPath.ListCheckingPaiementPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: PaiementPath.ListCheckingPaiementPath.code,
                        element: (
                            <PaiementPath.ListCheckingPaiementPath.Component />
                        )
                    },
                    {
                        ...PaiementPath.PaiementDetailPath
                    },
                    {
                        ...PaiementPath.CreateCheckingPaiementPath
                    }
                ]
            },
            {
                ...ConfigurationPath.FormulaireDynamiqueSimFlashViewLayoutPath,
                element: <StandardParent />,
                children: [
                    {
                        index: true,
                        code: ConfigurationPath
                            .FormulaireDynamiqueSimFlashViewLayoutPath.code,
                        element: (
                            <ConfigurationPath.FormulaireDynamiqueSimFlashViewLayoutPath.Component />
                        )
                    },
                    {
                        ...ConfigurationPath.FormulaireDynamiqueSimFlashDetailPath
                    }
                ]
            }
        ]
    },
    {
        isServiceConstraint: true,
        element: <SignInLayout />,
        children: [
            {
                ...GestionUserPath.SignInPath,
                isAlwaysAllow: true
            },
            {
                ...GestionUserPath.SignUpPath,
                isAlwaysAllow: true
            },
            {
                ...GestionUserPath.ForgotPasswordPath,
                isAlwaysAllow: true
            },
            {
                ...GestionUserPath.ConfirmSignUpPath,
                isAlwaysAllow: true
            },
            {
                ...ConfigurationPath.CashCollectionRegisterPath,
                isAlwaysAllow: true
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
];
