import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Div, ETAT, Sub, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import {
    CustomDropdown,
    CustomRow,
    NumberDisplayer
} from '../../../../components';

export function RapportRegularisationCaisseForm() {
    const { deviseReference, taux } = useSelector((reduxState) => ({
        deviseReference: reduxState.deviseReference,
        taux: reduxState.taux.find((item) => item.type === 'TauxSystem')
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedSelector: { code: 'ALL', designation: 'Tous' },
            deviseFilter: [],
            listHeaders: []
        }
    });
    const { fields } = formState;

    const groupDataByKey = useCallback(
        (array) =>
            (array || []).map((item) => ({
                ...item,
                [`montant${item.typeOperation}${item.devise}`]: item.montant
            })),
        []
    );

    const listFormater = useCallback(
        (donnees) => {
            const data = donnees?.[0]?.length
                ? (donnees?.[0] || [])?.filter(
                      (item) => item.etat === ETAT.ETAT_VALIDER
                  )
                : (donnees || [])?.filter(
                      (item) => item.etat === ETAT.ETAT_VALIDER
                  ) || [];
            const resulat = groupDataByKey(
                (data || []).map((item) => ({
                    ...item,
                    montantENTREECDF: 0,
                    montantENTREEUSD: 0,
                    montantSORTIECDF: 0,
                    montantSORTIEUSD: 0
                }))
            );
            return resulat.filter((item) =>
                fields.selectedSelector.code === 'ALL'
                    ? true
                    : item.devise === fields.selectedSelector.code
            );
        },
        [fields.selectedSelector.code, groupDataByKey]
    );

    const cutomTableHeader = () => (
        <>
            <tr className="border text-center text-justify">
                <th rowSpan={2} className="border">
                    #
                </th>
                <th rowSpan={2} className="border">
                    Organisation
                </th>
                <th rowSpan={2} className="border">
                    Imputation
                </th>
                <th rowSpan={2} className="border">
                    Caisse
                </th>
                <th rowSpan={2} className="border">
                    Date Creation
                </th>
                <th colSpan={2} className="border">
                    Entrée
                </th>
                <th colSpan={2} className="border">
                    Sortie
                </th>
                {/* <th rowSpan={2} className="border">
                    Résultat
                </th> */}
            </tr>
            <tr className="border text-center text-justify">
                <th className="border">CDF</th>
                <th className="border">USD</th>
                <th className="border">CDF</th>
                <th className="border">USD</th>
            </tr>
        </>
    );

    const mapper = [
        {
            displayName: 'Organisation',
            field: 'valeurTypeEntite.designation'
        },
        {
            displayName: 'Imputation',
            field: 'imputation.designation'
        },
        {
            displayName: 'Caisse',
            field: 'caisse.designation'
        },
        {
            displayName: 'Date Creation',
            field: 'dateCreation'
        },
        {
            displayName: 'EntréeCDF',
            field: 'montantENTREECDF',
            isTotal: true
        },
        {
            displayName: 'EntréeUSD',
            field: 'montantENTREEUSD',
            isTotal: true
        },
        {
            displayName: 'Sortie',
            field: 'montantSORTIECDF',
            isTotal: true
        },
        {
            displayName: 'Sortie',
            field: 'montantSORTIEUSD',
            isTotal: true
        }
    ];

    const titleToRender = (code) => {
        if (code === 'ALL') return 'Tous';
        return `Régularisation ${code} | ${deviseReference?.code || ''} 1 = ${
            taux?.valeur || ''
        } ${taux?.devise || ''}`;
    };
    const dynamicBottom = useCallback(
        (data) => {
            const allData = (data?.[0]?.length ? data?.[0] : data) || [];
            const elements = (
                allData.filter((item) => item.etat === ETAT.ETAT_VALIDER) || []
            ).map((item) => ({
                ...item,
                [item.typeOperation]:
                    (item?.devise === deviseReference.code
                        ? item.montant
                        : Div(item.montant, taux?.valeur || 1)) || 0,
                devise: item?.devise
            }));
            // const getKeys = Object.keys(elements || {});

            return (
                <>
                    <tr className="lead fw-bold border">
                        <td className="border fw-bold" colSpan={5}>
                            Total Général
                        </td>
                        <td className="border fw-bold" colSpan={2}>
                            <NumberDisplayer
                                value={elements.reduce(
                                    (a, b) => a + (b?.ENTREE || 0),
                                    0
                                )}
                                nombreApresVirgule={5}
                            />
                        </td>
                        <td className="border fw-bold" colSpan={2}>
                            <NumberDisplayer
                                value={elements.reduce(
                                    (a, b) => a + (b?.SORTIE || 0),
                                    0
                                )}
                                nombreApresVirgule={5}
                            />
                        </td>
                    </tr>
                    <tr className="lead fw-bold border">
                        <td className="border fw-bold" colSpan={5}>
                            Résultat
                        </td>
                        <td className="border fw-bold text-end" colSpan={4}>
                            <NumberDisplayer
                                value={Sub(
                                    elements.reduce(
                                        (a, b) => a + (b?.ENTREE || 0),
                                        0
                                    ),
                                    elements.reduce(
                                        (a, b) => a + (b?.SORTIE || 0),
                                        0
                                    )
                                )}
                                nombreApresVirgule={5}
                            />
                        </td>
                    </tr>
                </>
            );
        },
        [deviseReference.code, taux?.valeur]
    );

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                dynamicBottom={dynamicBottom}
                cutomTableHeader={cutomTableHeader}
                listTitle={titleToRender(fields.selectedSelector.code)}
                dynamicNodeName={`evolutionsNewRegularisationCaisse-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_REGULARISATION_CAISSE_INITIALS}
                listFormater={listFormater}
                mapper={mapper}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959())
                }}
                defaultPayloadMapperValues={{}}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        isSort={false}
                        options={[
                            { code: 'ALL', designation: 'Tous' },
                            { code: 'USD', designation: 'USD' },
                            { code: 'CDF', designation: 'CDF' }
                        ]}
                        label="Valeur : "
                        defaultOption="Selectionner une valeur"
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedSelector"
                        id="selectedSelectortypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
