import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import {
    // BilletageComponent,
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomInput,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailOperationBancaireForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        uid
    } = useGetDetailForm({
        nodeName: 'operationsBancaires',
        defaultFields: {
            reference: '',
            numeroReference: '',
            actionEncours: ''
        }
    });
    // console.log(reduxProps.allCoupures)
    const isDecaisseur = entity?.userDecaissement === uid;
    const isGestionnaire = entity?.userValidateurNumeroReference === uid;
    const operation = entity?.typeProprietaire;
    const checkValidationEmoney =
        entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
        isDecaisseur &&
        entity?.caisse?.typeEntite === TYPE_ORGANISATION.SUPER_DEALER &&
        entity?.caisse?.estEmoney;
    const { form, fields } = formState;
    const updateEtat = (etat) => {
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : etat;
        const payloadToOut = {};
        if (fields.reference) {
            payloadToOut.numeroReference = fields.reference;
        }
        if (!fields.numeroReference && checkValidationEmoney) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Veuillez saisir le numéro de référence Emoney`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (fields.numeroReference) {
            payloadToOut.numeroReferenceEmoney = fields.numeroReference;
        }
        const payload = {
            idOperationBancaire: entity?.id,
            etat,
            ...payloadToOut
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_OPERATION_BANCAIRE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_BANCAIRE,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_OPERATION_BANCAIRE_BY_ID}
            nodeName={REDUX_NODE_NAME.OPERATION_BANCAIRE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Détail Opération Bancaire"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.FINANCE_ORGANISATION
                        .DECAISSEMENT_OPERATION_BANCAIRE,
                    CODE_SERVICE.FINANCE_ORGANISATION
                        .ATTENTE_VALIDATION_OPERATION_BANCAIRE,
                    CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE
                ]}
            >
                <CustomRow
                    isShouldBeRendered={
                        ((entity?.etat === ETAT.ETAT_ENCOURS &&
                            operation === 'Retrait') ||
                            (entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT &&
                                operation === 'Depot')) &&
                        isGestionnaire
                    }
                >
                    <CustomInput
                        type="text"
                        label="Référence* :"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="N° Reference"
                        formDispatcher={formDispatcher}
                        name="reference"
                        id="creationDepotreferenceId"
                        formState={formState}
                    />
                </CustomRow>
                <CustomRow isShouldBeRendered={checkValidationEmoney}>
                    <CustomInput
                        type="text"
                        label="Référence* :"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder={`Numéro Référence du ${operation} Emoney`}
                        formDispatcher={formDispatcher}
                        name="numeroReference"
                        id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                    >
                        <CustomCol className="offset-4 offset-md-8 col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-danger"
                                onClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                                text="Annuler"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.FINANCE_ORGANISATION
                                .ATTENTE_VALIDATION_OPERATION_BANCAIRE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_PRE_ENCOURS
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ENCOURS)
                                    }
                                    text="Valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ENCOURS
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>

                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.FINANCE_ORGANISATION
                                .VALIDER_OPERATION_BANCAIRE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_ENCOURS &&
                                operation === 'Retrait'
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="Mettre en attente"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.FINANCE_ORGANISATION
                                .ATTENTE_VALIDATION_OPERATION_BANCAIRE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_ENCOURS
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    text="Mettre en attente"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.FINANCE_ORGANISATION
                                .DECAISSEMENT_OPERATION_BANCAIRE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
                                operation === 'Depot' &&
                                isDecaisseur
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(
                                            ETAT.ETAT_VALIDER_DECAISSEMENT
                                        )
                                    }
                                    text="valider Décaissement"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER_DECAISSEMENT
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.FINANCE_ORGANISATION
                                .DECAISSEMENT_OPERATION_BANCAIRE
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                (entity?.etat ===
                                    ETAT.ETAT_ATTENTE_VALIDATION &&
                                    operation === 'Retrait' &&
                                    isDecaisseur) ||
                                (entity?.etat ===
                                    ETAT.ETAT_VALIDER_DECAISSEMENT &&
                                    operation === 'Depot')
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomButtonLoader
                                    type="button"
                                    className="btn-success"
                                    onClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    text="valider"
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
