import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    FORM_CONST,
    LIST_TYPE_TAUX,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, useDebounce } from '../../../../util';

export function CreationTaux({
    idToken,
    typeEntite,
    initialState,
    uiValidator,
    organisation
}) {
    const reduxDispatcher = useDispatch();
    const {
        monnaiesLocales,
        etatMonnaieLocale,
        superDealerKinDB,
        etatSuperDealerKinDB,
        allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB,
        deviseReference
    } = useSelector((reduxState) => ({
        monnaiesLocales: reduxState.monnaiesLocale.filter(
            (monnaie) => !monnaie.estReference
        ),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat,
        allCashCollecteurKinDB: reduxState.allCashCollecteurKinDB,
        etatAllCashCollecteurKinDB: reduxState.etat.allCashCollecteurKinDB.etat,
        deviseReference: reduxState.deviseReference
    }));
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite },
                isCondition:
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.REVENDEUR
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_CASH_COLLECTEUR_KIN_DB,
                etat: etatAllCashCollecteurKinDB,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            }
        ]
    });

    const renderUseDebounceNameProperty = ({ type, valeurOrganisation }) => {
        if (
            type === TYPE_ORGANISATION.SUPER_DEALER &&
            !!valeurOrganisation?.code
        )
            return 'selectedSuperDealer';
        if (
            type === TYPE_ORGANISATION.CASH_COLLECTEUR &&
            !!valeurOrganisation?.code
        )
            return 'selectedCashCollecteur';
        return 'fictifName';
    };

    const ternaryCondition = ({ condition, then, otherwise }) =>
        condition ? then : otherwise;

    /**
     * Debounce
     */
    useDebounce({
        name: renderUseDebounceNameProperty({
            type: fields.selectedTypeOrganisation?.code,
            valeurOrganisation: ternaryCondition({
                condition:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER,
                then: fields.selectedSuperDealer,
                otherwise:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.CASH_COLLECTEUR
                        ? fields.selectedCashCollecteur
                        : {}
            })
        }),
        preview: 'previewValue',
        propertyInName: 'code',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1000,
        idToken,
        functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
        elementPropertyName: 'monnaies',
        payloadToAdd: {
            typeEntite: fields.selectedTypeOrganisation?.code,
            codeValeurTypeEntite: ternaryCondition({
                condition:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER,
                then: fields.selectedSuperDealer?.code,
                otherwise:
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.CASH_COLLECTEUR
                        ? fields.selectedCashCollecteur?.code
                        : ''
            })
        },
        dataLength: 0,
        condition:
            typeEntite === TYPE_ORGANISATION.NAPP ||
            typeEntite === TYPE_ORGANISATION.REVENDEUR
    });

    const onAddDeviseSollicite = () => {
        if (!fields.selectedType?.code || !fields.montant) return;
        const data = {
            designation: fields.selectedType?.designation,
            type: fields.selectedType?.code,
            valeur: fields.montant
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                field: 'list',
                data,
                identifier: 'type'
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedMonnaie, list } = fields;
        if (
            list?.find((item) => item.type === 'TauxAchat')?.valeur >
            list?.find((item) => item.type === 'TauxVente')?.valeur
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Le taux d'achat doit être inférieur ou égal au taux de vente",
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            devise: selectedMonnaie?.devise,
            list: list.map((item) => ({
                type: item.type,
                valeur: item.valeur
            }))
        };
        if (
            fields.selectedTypeOrganisation?.code &&
            fields.selectedTypeOrganisation?.code !== TYPE_ORGANISATION.NAPP
        ) {
            payload.typeEntite = fields.selectedTypeOrganisation?.code;
        }
        if (
            fields.selectedTypeOrganisation?.code ===
                TYPE_ORGANISATION.SUPER_DEALER ||
            typeEntite === TYPE_ORGANISATION.REVENDEUR
        ) {
            payload.codeValeurTypeEntite = fields.selectedSuperDealer.code;
        }
        if (
            fields.selectedTypeOrganisation?.code ===
            TYPE_ORGANISATION.CASH_COLLECTEUR
        ) {
            payload.codeValeurTypeEntite = fields.selectedCashCollecteur.code;
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_TAUX,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TAUX_VALIDATION,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'monnaieLocale',
                    etat: etatMonnaieLocale
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.NAPP ||
                        typeEntite === TYPE_ORGANISATION.REVENDEUR
                },
                {
                    dependency: 'cashCollecteur',
                    etat: etatAllCashCollecteurKinDB,
                    isCondition: typeEntite === TYPE_ORGANISATION.NAPP
                }
            ]}
        >
            <CustomRow
                isShouldBeRendered={typeEntite === TYPE_ORGANISATION.NAPP}
            >
                <CustomDropdown
                    options={[
                        {
                            code: TYPE_ORGANISATION.CASH_COLLECTEUR,
                            designation: 'Cash Collecteur'
                        },
                        {
                            code: TYPE_ORGANISATION.SUPER_DEALER,
                            designation: 'Super Dealer'
                        },
                        {
                            code: TYPE_ORGANISATION.NAPP,
                            designation: 'Napp'
                        }
                    ]}
                    label="Type Organisation* :"
                    defaultOption="Sélectionner Type Organisation"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOrganisation"
                    id="selectedTypeOrganisationcreationTauxOrganisationKindistributionId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={typeEntite === TYPE_ORGANISATION.REVENDEUR}
            >
                <CustomDropdown
                    options={[
                        {
                            code: organisation?.code || '',
                            designation: organisation?.designation || ''
                        },
                        {
                            code: TYPE_ORGANISATION.SUPER_DEALER,
                            designation: 'Mes Organisations'
                        }
                    ]}
                    isSort={false}
                    label="Type Organisation* :"
                    defaultOption="Sélectionner Type Organisation"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeOrganisation"
                    id="selectedTypeOrganisationcreationTauxOrganisationKindistributionId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.SUPER_DEALER
                }
            >
                <CustomDropdown
                    options={superDealerKinDB}
                    disabled={!superDealerKinDB?.length}
                    defaultOption="Selectionner Organisation"
                    label="Organisations* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="creationTauxOrganisationKindistributionselectedSuperDealerId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code ===
                    TYPE_ORGANISATION.CASH_COLLECTEUR
                }
            >
                <CustomDropdown
                    options={allCashCollecteurKinDB}
                    disabled={!allCashCollecteurKinDB?.length}
                    defaultOption="Selectionner Organisation"
                    label="Organisations* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCashCollecteur"
                    id="creationTauxOrganisationKindistributionselectedCashCollecteurId"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code ===
                        TYPE_ORGANISATION.CASH_COLLECTEUR ||
                    fields.selectedTypeOrganisation?.code ===
                        TYPE_ORGANISATION.SUPER_DEALER
                }
            >
                {elements?.monnaies?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                    <div className="placeholder-glow">
                        <span className="placeholder col-5" />
                        <span className="placeholder col-12" />
                    </div>
                ) : null}
                <CustomRow
                    isShouldBeRendered={!!elements?.monnaies?.value?.length}
                >
                    <CustomDropdown
                        formDispatcher={formDispatcher}
                        formState={formState}
                        // // uiValidator={uiValidator}
                        name="selectedMonnaie"
                        id="creationTauxOrganisationKindistributionMontantselectedMonnaieSolliciteId"
                        options={
                            elements?.monnaies?.value?.filter(
                                (item) =>
                                    item?.devise !== deviseReference?.devise
                            ) || []
                        }
                        disabled={
                            !elements?.monnaies?.value?.filter(
                                (item) =>
                                    item?.devise !== deviseReference?.devise
                            )?.length
                        }
                        selectionClassName="form-select-md"
                        defaultOption="Selectionner Devise"
                        label="Devise* : "
                        propertyToDisplay="devise"
                        idProperty="id"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow
                isShouldBeRendered={
                    fields.selectedTypeOrganisation?.code !==
                        TYPE_ORGANISATION.CASH_COLLECTEUR &&
                    fields.selectedTypeOrganisation?.code !==
                        TYPE_ORGANISATION.SUPER_DEALER
                }
            >
                <CustomDropdown
                    options={
                        monnaiesLocales?.filter(
                            (item) => item?.devise !== deviseReference?.devise
                        ) || []
                    }
                    label="Monnaie* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Choisir Monnaie"
                    formDispatcher={formDispatcher}
                    name="selectedMonnaie"
                    id="selectedMonnaieCreationTaux"
                    formState={formState}
                    propertyToDisplay="devise"
                    idProperty="id"
                    uiValidator={uiValidator}
                    disabled={
                        !monnaiesLocales?.filter(
                            (item) => item?.devise !== deviseReference?.devise
                        )?.length
                    }
                />
            </CustomRow>
            <CustomRow>
                <CustomLabel text="Taux* :" className="col-sm-2" />
                <CustomDropdown
                    options={LIST_TYPE_TAUX}
                    divClassName="col-sm-4"
                    defaultOption="Choisir Type"
                    formDispatcher={formDispatcher}
                    name="selectedType"
                    id="selectedTypeCreationTaux"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomInput
                    type="number"
                    isFloat
                    isInputGroup={false}
                    divClassName="col-sm-4"
                    min={1}
                    placeholder="Saisissez le taux"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="montantCreationTaux"
                    formState={formState}
                    uiValidator={uiValidator}
                />
                <CustomAddRemoveButton
                    className="col-sm-1"
                    callback={onAddDeviseSollicite}
                />
            </CustomRow>
            <CustomCollapse isIn={!!fields?.list?.length}>
                <SimpleTable
                    identifier="type"
                    data={fields?.list}
                    formDispatcher={formDispatcher}
                    listSelectedItemName="list"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm w-100"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            field: 'designation',
                            displayName: 'designation'
                        },
                        {
                            position: 2,
                            field: 'valeur',
                            displayName: 'Valeur'
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
