// FIXME: POUR L'INSTANT LA DEVISE EST FIXE, MAIS PEUT CHANGER A TOUT MOMENT

import { fromStringToTimestamp } from '@napp-inc/jnapp-util';

// TODO: LAISSER LA POSSIBILITE DE RENSEIGNER DES OBJECTIFS EN MONNAIE LOCALS (mettre la devise dans la tâche pour ne pas devoir renseigner cela depuis l'interface)
export const transformedData = ({ data, devise }) =>
    data
        .filter((task) => !!task.codeTache)
        .map((task) => ({
            codeTache: task.codeTache,
            details: task.details.map((detail) => ({
                dateDebut: detail.dateDebut,
                agents: detail.agents.map((agent) => ({
                    reference: agent.reference,
                    target: agent.target,
                    devise: agent.devise || devise
                }))
            }))
        }));

const castTypeOfData = ({ type, data }) => {
    if (type === 'date') return fromStringToTimestamp(data);
    if (type === 'number') return Number(data);
    return data;
};

const getNames = ({ name }) => {
    if (name.includes('|')) return name.split('|');
    if (name.includes('.')) return name.split('.');
    return name;
};

// For input
export const handleChange = ({
    index,
    event,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    setFormFields
}) => {
    const values = [...formFields];
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        values[index][event.target.name] = castTypeOfData({
            type: event.target.type,
            data: event.target.value
        });
    }
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        if (
            event.target.attributes?.customproperty?.nodeValue === 'dateDebut'
        ) {
            values[index].details[indexAtSecondLevel].dateDebut =
                castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
        }
        if (event.target.name === 'dateString') {
            values[index].details[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    // type: event.target.type,
                    data: event.target.value
                });
        } else {
            values[index].details[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
        }
    }
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number'
    ) {
        values[index].details[indexAtSecondLevel].agents[indexAtThirdLevel][
            event.target.name
        ] = castTypeOfData({
            type: event.target.type,
            data: event.target.value
        });
    }
    setFormFields(values);
};

// For Dropdown
export const handleSelectChange = ({
    index,
    event,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    setFormFields
}) => {
    const values = [...formFields];
    const names = getNames({ name: event.target.name });
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au premier niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        /**
         * @desc: serie de tests, pour traiter les select input avec des name de type
         * (property),
         * (property|property),
         * (object.property),
         * (object.property1|object.property2)
         */
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            if (!f.includes('.') && !s.includes('.')) {
                values[index][f] = event.target.value;
                values[index][s] =
                    typeof event.target.value === 'string'
                        ? event.target.selectedOptions['0'].innerText
                        : event.target.value + 9999;
            }
        } else {
            values[index][event.target.name] = event.target.value;
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au deuxième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number'
    ) {
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel][f] = event.target.value;
            values[index].details[indexAtSecondLevel][s] =
                typeof event.target.value === 'string'
                    ? event.target.selectedOptions['0'].innerText
                    : event.target.value + 9999;
        } else {
            values[index].details[indexAtSecondLevel][event.target.name] =
                event.target.value;
        }
    }
    /**
     * @desc: A ce stade, nous traitons toutes les valeurs
     * au troisième niveau de l'arborescence (Voir le state)
     */
    if (
        typeof index === 'number' &&
        event &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number'
    ) {
        if (names.length > 1 && event.target.name.includes('|')) {
            const [f, s] = names;
            values[index].details[indexAtSecondLevel].agents[indexAtThirdLevel][
                f
            ] = event.target.value;
            values[index].details[indexAtSecondLevel].agents[indexAtThirdLevel][
                s
            ] = event.target.selectedOptions['0'].innerText;
        } else {
            values[index].details[indexAtSecondLevel].agents[indexAtThirdLevel][
                event.target.name
            ] = event.target.value;
        }
    }
    setFormFields(values);
};

// For Adding Element
export const handleAddHighLevel = ({
    type,
    ancestorId,
    indexAtSecondLevel,
    formFields,
    setFormFields
}) => {
    if (type === 'Objectif') {
        if (!formFields?.[formFields.length - 1]?.codeTache) return;
        const newFormFields = formFields.map((field) => ({
            ...field,
            isTooggled: false,
            isVisible: false
        }));
        setFormFields([
            ...newFormFields,
            {
                codeTache: '',
                designationTache: '',
                isVisible: false,
                isTooggled: true,
                details: [
                    {
                        dateDebut: 0,
                        dateString: '',
                        isVisible: false,
                        isTooggled: true,
                        agents: [
                            {
                                reference: '',
                                designation: '',
                                target: 0,
                                devise: ''
                            }
                        ]
                    }
                ]
            }
        ]);
    }
    if (type === 'Detail' && typeof ancestorId === 'number') {
        const values = [...formFields];
        values[ancestorId].details.push({
            dateDebut: 0,
            dateString: '',
            isVisible: false,
            isTooggled: true,
            agents: [
                {
                    reference: '',
                    designation: '',
                    target: 0,
                    devise: ''
                }
            ]
        });
        setFormFields(values);
    }
    if (
        type === 'Agent' &&
        typeof ancestorId === 'number' &&
        typeof indexAtSecondLevel === 'number'
    ) {
        const values = [...formFields];
        values[ancestorId].details[indexAtSecondLevel].agents.push({
            reference: '',
            designation: '',
            target: 0,
            devise: ''
        });
        setFormFields(values);
    }
};

// For Removing Element
export const handleRemove = ({
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    setFormFields
}) => {
    const values = [...formFields];
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number' &&
        values.length > 1
    ) {
        values.splice(index, 1);
    }
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number' &&
        values[index].details.length > 1
    ) {
        // delete values[index].details[indexAtSecondLevel]
        values[index].details.splice(indexAtSecondLevel, 1);
        // values[index].details.filter(
        //     (_el, innerIndex) => innerIndex !== indexAtSecondLevel
        // );
    }
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number' &&
        values[index].details[indexAtSecondLevel].agents.length > 1
    ) {
        values[index].details[indexAtSecondLevel].agents.splice(
            indexAtThirdLevel,
            1
        );
    }
    setFormFields(values);
};

// // For Getting Beneficiaire
// const getTypeBeneficiaire = useCallback(
//     ({ id }) => {
//         if (id === BENEFICIAIRE?.EMPLOYE?.code) return employesOrganisation;
//         if (id === BENEFICIAIRE?.USER?.code) return users;
//         if (id === BENEFICIAIRE?.PARTENAIRE?.code) return partenaire;
//         return [];
//     },
//     [employesOrganisation, partenaire, users]
// );

/**
 * End Region
 */

export const toggledView = ({
    code,
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    formFields,
    setFormFields
}) => {
    if (
        code &&
        typeof index !== 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const findedIndex = formFields
            .map((item) => item.codeTache)
            .indexOf(code);
        if (
            !formFields?.[findedIndex]?.isTooggled &&
            !formFields.at(-1)?.codeTache
        ) {
            const values = formFields.splice(-1, 1);
            setFormFields([...values]);
        }
        if (
            !formFields?.[findedIndex]?.isTooggled &&
            formFields.at(-1)?.codeTache
        ) {
            handleChange({
                index: formFields[formFields.length - 1],
                event: {
                    target: {
                        name: 'isTooggled',
                        value: false
                    }
                },
                setFormFields,
                formFields
            });
        }
        handleChange({
            index: findedIndex,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields?.[findedIndex]?.isTooggled
                }
            },
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        if (!formFields?.[index]?.isTooggled && !formFields.at(-1)?.codeTache) {
            const values = formFields.splice(-1, 1);
            setFormFields([...values]);
        }
        if (!formFields?.[index]?.isTooggled && formFields.at(-1)?.codeTache) {
            handleChange({
                index: formFields[formFields.length - 1],
                event: {
                    target: {
                        name: 'isTooggled',
                        value: false
                    }
                },
                setFormFields,
                formFields
            });
        }
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields?.[index]?.isTooggled
                }
            },
            setFormFields,
            formFields
        });
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value: !formFields[index].details[indexAtSecondLevel]
                        .isTooggled
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
    }
};

export const toggledVisibilityView = ({
    code,
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    formFields,
    setFormFields
    // view = 'ChargeFixe',
    // type,
    // handleAddHighLevel
}) => {
    if (
        code &&
        typeof index !== 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const findedIndex = formFields
            .map((item) => item.codeTache)
            .indexOf(code);
        const value = !formFields?.[findedIndex].isVisible;
        handleChange({
            index: findedIndex,
            event: {
                target: {
                    name: 'isVisible',
                    value
                }
            },
            setFormFields,
            formFields
        });
    }
    if (
        code &&
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number'
    ) {
        const value = !formFields[index].details[indexAtSecondLevel].isVisible;
        handleChange({
            index,
            event: {
                target: {
                    name: 'isVisible',
                    value
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
        handleChange({
            index,
            event: {
                target: {
                    name: 'isTooggled',
                    value
                }
            },
            indexAtSecondLevel,
            setFormFields,
            formFields
        });
    }
};
