import {
    CODE_SERVICE,
    ETAT,
    FORM_CONST,
    getMonthByDate
} from '@napp-inc/jnapp-util';
import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailObjectifsForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'objectifs',
            defaultElements: ['details']
        });

    const { fields, form, elements } = formState;

    const onFetchDetailRetraitById = () => {
        if (!entity.id) return;
        const { status: etat } = elements.details;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_DETAIL_DEFINITION_OBJECTIF,
                elementPropertyName: 'details',
                etat,
                payload: {
                    idObjectif: entity.id
                },
                reduxDispatcher
            })
        );
    };

    const handleSubmit = (etat) => {
        const payload = {
            idObjectif: entity.id,
            etat
        };
        const actionEncours =
            ETAT.ETAT_ANNULER === etat ? ETAT.ETAT_ANNULER : ETAT.ETAT_VALIDER;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_DEFINITION_OBJECTIF,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OBJECTIFS,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_DEFINITION_OBJECTIF_BY_ID}
            nodeName={REDUX_NODE_NAME.OBJECTIFS}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Details objectif"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2"
                    onClick={onFetchDetailRetraitById}
                    text="Voir Détails"
                    disabled={
                        elements?.details?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <SimpleTable
                    tableClassName=""
                    isShowDropButton={false}
                    data={
                        elements?.details?.value?.map((item) => ({
                            ...item,
                            agentIdentite: `${item?.agent?.prenom || ''} ${
                                item?.agent?.nom || ''
                            }`
                        })) || []
                    }
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Agent',
                            field: 'agentIdentite'
                        },
                        {
                            displayName: 'Tache',
                            field: 'tache.designation'
                        },
                        {
                            displayName: 'Periode',
                            dataExtractor: (item) =>
                                getMonthByDate(item?.periode?.dateDebut)
                            // field: 'periode.debut'
                        },
                        {
                            field: 'target',
                            displayName: 'Objectif'
                        },
                        {
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'date création'
                        }
                    ]}
                    filter={false}
                    isLinkToDetail={false}
                />
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .CONFIGURATION_OBJECTIF.VALIDER_OBJECTIF
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity.etat === ETAT.ETAT_ENCOURS}
                >
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() =>
                                handleSubmit(ETAT.ETAT_ANNULER)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() =>
                                handleSubmit(ETAT.ETAT_VALIDER)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
