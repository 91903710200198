import { CODE_SERVICE, ETAT, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, useHasService } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper } from '../container';
import { CustomAlert } from '../alert';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function ReportCommandeFlashSelector({
    formDispatcher,
    formState,
    estMoney = false
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        superDealerKinDB,
        etatSuperDealerKinDB,
        nappUser,
        etatSuperDealerEmoneyKinDB,
        superDealerEmoneyKinDB
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        superDealerKinDB: reduxState.superDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        superDealerEmoneyKinDB: reduxState.superDealerEmoneyKinDB.map(
            (item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })
        ),
        etatSuperDealerEmoneyKinDB: reduxState.etat.superDealerEmoneyKinDB.etat
    }));
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.STOCK.COMMANDE_MARCHAND.LECTURE_COMMANDE_FLASH_MARCHAND
        ]
    });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_EMONEY_KIN_DB,
                etat: etatSuperDealerEmoneyKinDB,
                payload: { estEmoney: true, typeEntite: nappUser?.typeEntite },
                isCondition: isNapp && estMoney
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { typeEntite: nappUser?.typeEntite },
                isCondition:
                    !!hasService[
                        CODE_SERVICE.STOCK.COMMANDE_MARCHAND
                            .LECTURE_COMMANDE_FLASH_MARCHAND?.code
                    ] &&
                    isNapp &&
                    !estMoney
            }
        ]
    });
    const renderContent = () => {
        if (
            (!estMoney &&
                ((!!hasService[
                    CODE_SERVICE.STOCK.COMMANDE_MARCHAND
                        .LECTURE_COMMANDE_FLASH_MARCHAND?.code
                ] &&
                    etatSuperDealerKinDB ===
                        GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) ||
                    !isNapp)) ||
            (estMoney &&
                (etatSuperDealerEmoneyKinDB ===
                    GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS ||
                    !isNapp))
        ) {
            return (
                <>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            (!estMoney &&
                                !!hasService[
                                    CODE_SERVICE.STOCK.COMMANDE_MARCHAND
                                        .LECTURE_COMMANDE_FLASH_MARCHAND?.code
                                ] &&
                                isNapp) ||
                            (estMoney && isNapp)
                        }
                    >
                        <CustomDropdown
                            options={
                                !estMoney
                                    ? superDealerKinDB
                                    : superDealerEmoneyKinDB
                            }
                            defaultOption="Selectionner..."
                            label="Mes organisations: "
                            labelClassName="col-12"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedSuperDealer"
                            id="selectedSuperDealerCreationApproCompteEmoney"
                            // uiValidator={CreationApproCompteEmoneySchema}
                            formState={formState}
                        />
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper>
                        <CustomDropdown
                            options={
                                !estMoney
                                    ? [
                                          {
                                              code: ETAT.ETAT_ENCOURS,
                                              designation: 'ENCOURS'
                                          },
                                          {
                                              code: ETAT.ETAT_ATTENTE_VALIDATION,
                                              designation: 'ATTENTE VALIDATION'
                                          },
                                          {
                                              code: ETAT.ETAT_LIVRER,
                                              designation: 'LIVRER'
                                          },
                                          {
                                              code: ETAT.ETAT_ANNULER,
                                              designation: 'ANNULER'
                                          }
                                      ]
                                    : [
                                          {
                                              code: ETAT.ETAT_ENCOURS,
                                              designation: 'ENCOURS'
                                          },
                                          {
                                              code: ETAT.ETAT_ATTENTE_VALIDATION,
                                              designation: 'ATTENTE VALIDATION'
                                          },
                                          {
                                              code: ETAT.ETAT_VALIDER,
                                              designation: 'VALIDER'
                                          },
                                          {
                                              code: ETAT.ETAT_ANNULER,
                                              designation: 'ANNULER'
                                          },
                                          {
                                              code: ETAT.ETAT_NON_UTILISER,
                                              designation: 'NON UTILISER'
                                          }
                                      ]
                            }
                            label="Etat: "
                            labelClassName="col-12"
                            defaultOption="Selectionner état"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedEtat"
                            id="selectedEtatCreationApproCompteEmoney"
                            // uiValidator={CreationApproCompteEmoneySchema}
                            formState={formState}
                        />
                    </ConditionalRenderingWrapper>
                </>
            );
        }
        if (
            (!estMoney &&
                !!hasService[
                    CODE_SERVICE.STOCK.COMMANDE_MARCHAND
                        .LECTURE_COMMANDE_FLASH_MARCHAND?.code
                ] &&
                etatSuperDealerKinDB ===
                    GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) ||
            (estMoney &&
                etatSuperDealerEmoneyKinDB ===
                    GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC)
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
