import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les superDealers emoney
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function SuperDealerSelector({
    formDispatcher,
    formState,
    estNonEmoney = false,
    estNonAirtime = false,
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, superDealerKinDB, etatSuperDealerKinDB } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            superDealerKinDB: reduxState.allSuperDealerKinDB
                .filter((item) => {
                    if (estNonEmoney && item.estEmoney) return false;
                    if (estNonAirtime && !item.estEmoney) return false;
                    return true;
                })
                .map((item) => ({
                    ...item,
                    code: item.entreprise.code,
                    designation: item.entreprise.designation
                })),
            etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat
        }));

    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: { estAll: true, typeEntite: nappUser?.typeEntite }
            }
        ]
    });

    const renderContent = () => {
        if (
            etatSuperDealerKinDB ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomRow>
                    <CustomDropdown
                        options={superDealerKinDB}
                        defaultOption="Selectionner organisation"
                        label="Organisations: "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedSuperDealer"
                        id="selectedSuperDealerCreationApproCompteEmoney"
                        // uiValidator={CreationApproCompteEmoneySchema}
                        formState={formState}
                        idProperty={idProperty}
                        keyProperty="id"
                        additionnalOptions={
                            isAddAditionalOptions ? additionnalOptions : []
                        }
                    />
                </CustomRow>
            );
        }
        if (
            etatSuperDealerKinDB === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
