import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { BsFillCaretDownFill, BsFillCaretRightFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';

/**
 * Menu du site ou des autres layouts
 * @param {Object} param0 objet qui contient les props du composant:
 * @param {Object} param0.item objet qui contient représente un elemet du menu
 * @param {string} param0.menuClass Classe CSS
 * @param {string} param0.menuClassActive Classe CSS lorsque l'option est active
 * @param {string} param0.menuClassText Classe CSS pour le texte
 * @param {string} param0.submenuClass Classe CSS pour le sous menu
 * @param {string} param0.submenuClassActive Classe CSS pour le sous menu lorsque l'option est active
 * @returns {React.Component}
 */
export function Menu({
    item,
    menuClass,
    menuClassActive,
    menuClassText,
    submenuClass,
    submenuClassActive
}) {
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { typeEntite } = useGetUserDetail({ nappUser });
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const iconToRender = () => {
        if (item.children && subnav) {
            return <BsFillCaretDownFill />;
        }
        if (item.children) {
            return <BsFillCaretRightFill />;
        }
        return undefined;
    };
    const nameToRender = (name) => {
        if (
            typeEntite !== TYPE_ORGANISATION.NAPP &&
            name === 'Kin distribution'
        )
            return 'Distributeur';
        return name;
    };
    const renderContent = () => (
        <>
            {item.children &&
            item.children.find((child) => child.isChildren) ? (
                <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={item.children && showSubnav}
                    onClick={item.children && showSubnav}
                    className={menuClass}
                >
                    <div className="d-flex justify-content-start align-items-center mb-2 mb-md-0">
                        {item.icon}
                        <span className={menuClassText}>
                            {nameToRender(item.name)}
                            {iconToRender()}
                        </span>
                    </div>
                    {/* <div>{iconToRender()}</div> */}
                </span>
            ) : (
                <NavLink
                    to={item.path}
                    onClick={item.children && showSubnav}
                    className={({ isActive }) =>
                        isActive
                            ? `${menuClass} ${menuClassActive}`
                            : `${menuClass}`
                    }
                >
                    <div className="d-flex justify-content-start align-items-center mb-2 mb-md-0">
                        {item.icon}
                        <span className={menuClassText}>{item.name}</span>
                    </div>
                    {/* <div>{iconToRender()}</div> */}
                </NavLink>
            )}
            {subnav &&
                item.children
                    .filter(({ isVisible, index }) => isVisible && !index)
                    .map((element) => (
                        <NavLink
                            to={element.path}
                            key={`${element.name}${element.path}`}
                            className={({ isActive }) =>
                                isActive
                                    ? `${submenuClass} ${submenuClassActive}`
                                    : `${submenuClass}`
                            }
                        >
                            {element.icon}
                            <span className={menuClassText}>
                                {element.name}
                            </span>
                        </NavLink>
                    ))}
        </>
    );
    return renderContent();
}

Menu.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired
};
