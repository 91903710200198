import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ADMINISTRATION_CONST, Array } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

const groupementCompteByOrganisation = (comptes = []) => {
    const group = {};
    comptes
        .filter((item) => item?.typeEntite === ADMINISTRATION_CONST.NAPP)
        .forEach((compte) => {
            const codeGroup = compte?.valeurTypeProprietaire?.code;
            if (Object.prototype.hasOwnProperty.call(group, codeGroup)) {
                group?.[codeGroup].balances.push(compte);
            } else {
                group[codeGroup] = {
                    designation: compte?.valeurTypeProprietaire?.designation,
                    code: codeGroup,
                    balances: [compte]
                };
            }
        });
    const result = [];
    Object.getOwnPropertyNames(group).forEach((item) => {
        const element = {
            id: item,
            designation: group[item]?.designation
        };
        const getKeys = Array.groupByProperty({
            array: group[item]?.balances,
            property: 'devise'
        });

        const keys = Object.keys(getKeys);

        keys.forEach((key) => {
            element[key] = getKeys?.[key]?.[0];
        });

        result.push(element);
    });
    return result;
};

export function ListBalanceOrganisationForm() {
    const { nappUser, allBalanceOrganisation } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        allBalanceOrganisation: reduxState.allBalanceOrganisation
    }));
    const mapper = useCallback(() => {
        const getKeys = Array.groupByProperty({
            array: allBalanceOrganisation,
            property: 'devise'
        });

        const keys = Object.keys(getKeys).sort();
        const addOtherPaths = keys.map((item) => ({
            displayName: `Balance ${item}`,
            field: `${item}.solde`,
            isTotal: true
        }));
        const newMapper = [
            {
                displayName: 'Organisation',
                field: 'designation',
                position: 1
            },
            ...addOtherPaths
        ];

        return newMapper;
    }, [allBalanceOrganisation])();
    return (
        <ListGenericForm
            isAddTotalTr
            reduxPropertyName="allBalanceOrganisation"
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            nodeName={REDUX_NODE_NAME.ALL_BALANCE_ORGANISATION}
            payload={{
                estOrganisation: true,
                typeProprietaire: 'SuperDealer',
                typesEntite: ['Napp'],
                typeOrganisation: nappUser?.typeEntite
            }}
            isLinkToDetail={false}
            listFormater={groupementCompteByOrganisation}
            mapper={mapper}
        />
    );
}
