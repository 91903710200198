import { BiCategory, BiMailSend } from 'react-icons/bi';
import { BsBank, BsPinMap, BsCart4, BsGraphUp } from 'react-icons/bs';
import { GrMoney, GrCoffee, GrGroup } from 'react-icons/gr';
import {
    SiCoronaengine,
    SiBmcsoftware,
    SiGoogletagmanager,
    SiLogstash
} from 'react-icons/si';
import { TiGroup } from 'react-icons/ti';
import {
    GiTeamDowngrade,
    GiMoneyStack,
    GiRoundTable,
    GiAlarmClock,
    GiCardExchange,
    GiWallet,
    GiTakeMyMoney,
    GiPayMoney,
    GiAbacus,
    GiFamilyTree
} from 'react-icons/gi';
import {
    AiOutlineUngroup,
    AiOutlineSnippets,
    AiOutlineCluster,
    AiOutlineFundProjectionScreen
} from 'react-icons/ai';
import {
    MdOutlineManageAccounts,
    MdAddRoad,
    MdFastfood,
    MdSell,
    MdAccountBalance,
    MdMobileScreenShare,
    MdNotificationsActive
} from 'react-icons/md';
import {
    FaWarehouse,
    FaStoreAlt,
    FaWallet,
    FaCalendarAlt
} from 'react-icons/fa';
import { FiPackage } from 'react-icons/fi';
import { IoLogoSlack } from 'react-icons/io';
import { HiOutlineCreditCard } from 'react-icons/hi';

/**
 * @desc Directions Rel paths
 */
export const DirectionRelPath = {
    name: 'Directions',
    path: 'cndirections',
    icon: <MdOutlineManageAccounts className="icon-custom" />
};

export const DirectionCreateRelPath = {
    name: 'directions',
    path: 'create'
};

/**
 * @desc Secteur Rel paths
 */
export const SecteurRelPath = {
    name: 'Secteurs',
    path: 'cnsecteurs',
    icon: <SiGoogletagmanager className="icon-custom" />
};

export const SecteurCreateRelPath = {
    name: 'Secteurs',
    path: 'create'
};

/**
 * @desc Ingredient Rel paths
 */
export const IngredientRelPath = {
    name: 'Ingredients',
    path: 'cningredients',
    icon: <BiCategory className="icon-custom" />
};

export const IngredientCreateRelPath = {
    name: 'ingredients',
    path: 'create'
};

/**
 * @desc moyen de retracage Rel paths
 */
export const MoyenRetracageRelPath = {
    name: 'Moyen de Retracage',
    path: 'cnmoyretra',
    icon: <SiLogstash className="icon-custom" />
};

export const MoyenRetracageCreateRelPath = {
    name: 'MoyenRetracages',
    path: 'create'
};

export const MoyenRetracageDetailRelPath = {
    name: 'DetailMoyenRetracages',
    path: ':id'
};

/**
 * @desc moyen de Article Colis Rel paths
 */
export const ArticleColisRelPath = {
    name: 'Article Colis',
    path: 'cnartcolis',
    icon: <FiPackage className="icon-custom" />
};

export const ArticleColisCreateRelPath = {
    name: 'ArticleColiss',
    path: 'create'
};

export const ArticleColisDetailRelPath = {
    name: 'DetailArticleColis',
    path: ':id'
};

/**
 * @desc Profil Rel paths
 */
export const ProfilRelPath = {
    name: 'Profil Conf',
    path: 'cnprofil',
    icon: <IoLogoSlack className="icon-custom" />
};

export const ProfilCreateRelPath = {
    name: 'Profil create',
    path: 'create'
};

export const ProfilDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Depot Rel paths
 */
export const DepotRelPath = {
    name: 'Depot',
    path: 'cndepot',
    icon: <FaWarehouse className="icon-custom" />
};

export const OperationBancaireCreateRelPath = {
    name: 'Depot create',
    path: 'create'
};

/**
 * @desc moyen de Homologuer point livraison Rel paths
 */
export const HomologuerPointLivraisonRelPath = {
    name: 'Homologuer Point de livraison',
    path: 'cnhomoptlivraison',
    icon: <MdAddRoad className="icon-custom" />
};

export const HomologuerPointLivraisonCreateRelPath = {
    name: 'HomologuerPointLivraisons',
    path: 'create'
};

export const HomologuerPointLivraisonDetailRelPath = {
    name: 'DetailHomologuerPointLivraison',
    path: ':id'
};

/**
 * @desc Table manger Rel paths
 */
export const SalleMangerRelPath = {
    name: 'Table Manger',
    path: 'cntablemanger',
    icon: <GiRoundTable className="icon-custom" />
};

export const SalleMangerCreateRelPath = {
    name: 'SalleMangers',
    path: 'create'
};

export const SalleMangerDetailRelPath = {
    name: 'DetailSalleManger',
    path: ':id'
};
/**
 * @desc Caisse Rel paths
 */
export const CaisseViewLayoutRelPath = {
    name: 'Caisse',
    path: 'caisses',
    icon: <GrMoney className="icon-custom" />
};

export const CaisseCreateRelPath = {
    name: '',
    path: 'create'
};

export const CaisseDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Partenaire Rel paths
 */
export const PartenaireViewLayoutRelPath = {
    name: 'Partenaire',
    path: 'partenaires',
    icon: <GiTeamDowngrade className="icon-custom" />
};

export const PartenaireCreateRelPath = {
    name: '',
    path: 'create'
};

export const PartenaireDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Service generateur Rel paths
 */
export const ServiceGenerateurViewLayoutRelPath = {
    name: 'Service générateur',
    path: 'service-gen',
    icon: <SiCoronaengine className="icon-custom" />
};

export const ServiceGenerateurCreateRelPath = {
    name: '',
    path: 'create'
};

export const ServiceGenerateurDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Charge Rel paths
 */
export const ChargeViewLayoutRelPath = {
    name: 'Charge',
    path: 'charges',
    icon: <GrCoffee className="icon-custom" />
};

export const ChargeCreateRelPath = {
    name: '',
    path: 'create'
};

export const ChargeDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Ressource Rel paths
 */
export const RessourceViewLayoutRelPath = {
    name: 'Ressource',
    path: 'ressources',
    icon: <AiOutlineUngroup className="icon-custom" />
};

export const RessourceCreateRelPath = {
    name: '',
    path: 'create'
};

export const RessourceDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Compte Imputation Rel paths
 */
export const CompteImputationViewLayoutRelPath = {
    name: 'Compte imputation',
    path: 'compte-imputation',
    icon: <AiOutlineSnippets className="icon-custom" />
};

export const CompteImputationCreateRelPath = {
    name: '',
    path: 'create'
};

export const CompteImputationDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Fonctions Rel paths
 */
export const FonctionViewLayoutRelPath = {
    name: 'Fonction',
    path: 'fonctions',
    icon: <SiBmcsoftware className="icon-custom" />
};

export const FonctionCreateRelPath = {
    name: '',
    path: 'create'
};

export const FonctionDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Fonctions Mise à jour Rel paths
 */
export const FonctionUpdateViewLayoutRelPath = {
    name: 'Fonction Mise à Jour',
    path: 'fonction-updates',
    icon: <SiBmcsoftware className="icon-custom" />
};

export const FonctionUpdateCreateRelPath = {
    name: '',
    path: 'create'
};

export const FonctionUpdateDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Banque Rel paths
 */
export const BanqueViewLayoutRelPath = {
    name: 'Banque',
    path: 'banques',
    icon: <BsBank className="icon-custom" />
};

export const BanqueCreateRelPath = {
    name: '',
    path: 'create'
};

export const BanqueDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Monnaie Locale paths
 */
export const MonnaieLocaleViewLayoutRelPath = {
    name: 'Monnaie locale',
    path: 'monnaie-locale',
    icon: <GiMoneyStack className="icon-custom" />
};

export const MonnaieLocaleCreateRelPath = {
    name: '',
    path: 'create'
};

export const MonnaieLocaleDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Programmer Pause paths
 */
export const ProgrammerPauseRelPath = {
    name: 'Programmer Pause',
    path: 'programmer-pause',
    icon: <GiAlarmClock className="icon-custom" />
};

export const ProgrammerPauseCreateRelPath = {
    name: '',
    path: 'create'
};

export const ProgrammerPauseDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Point de Livraison paths
 */
export const PointLivraisonRelPath = {
    name: 'Point Livraison',
    path: 'pt-livraison',
    icon: <BsPinMap className="icon-custom" />
};

export const PointLivraisonCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Reevaluation Charge paths
 */
export const ReevaluationChargeRelPath = {
    name: 'Réévaluation',
    path: 'reevaluate-charge',
    icon: <GiCardExchange className="icon-custom" />
};

export const ReevaluationChargeFixeCreateRelPath = {
    name: '',
    path: 'createfx'
};

export const ReevaluationChargeVariableCreateRelPath = {
    name: '',
    path: 'createvr'
};

export const ReevaluationChargeDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Appro Stock Produit Materiel
 */
export const ApprovisionnementStockMarchandProduitMaterielRelPath = {
    name: 'Appro Produit Materiel',
    path: 'appro-prod-materiel',
    icon: <FaStoreAlt className="icon-custom" />
};

export const ApprovisionnementStockMarchandProduitMaterielCreateRelPath = {
    name: '',
    path: 'create'
};

export const ApprovisionnementStockMarchandProduitMaterielDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Creation Produit Materiel
 */
export const ArticleProduitMaterielRelPath = {
    name: 'Produit Materiel',
    path: 'prod-materiel',
    icon: <MdFastfood className="icon-custom" />
};

export const ArticleProduitMaterielCreateRelPath = {
    name: '',
    path: 'create'
};

// export const ArticleProduitMaterielDetailRelPath = {
//     name: '',
//     path: ':id'
// };

/**
 * @desc Creation Abonnement Marchand
 */
export const AbonnementMarchandRelPath = {
    name: 'Abonnement Marchand',
    path: 'abonnement-marchand',
    icon: <GrGroup className="icon-custom" />
};

export const AbonnementMarchandCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Declaration ressource
 */
export const DeclarationRessourceRelPath = {
    name: 'Declaration Ressource',
    path: 'declare-ressource',
    icon: <GiWallet className="icon-custom" />
};

export const DeclarationRessourceCreateRelPath = {
    name: '',
    path: 'create'
};

export const DeclarationRessourceDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Commander en ligne
 */
export const CommanderEnLigneRelPath = {
    name: 'Commander en ligne',
    path: 'commander-en-ligne',
    icon: <MdSell className="icon-custom" />
};

export const CommanderEnLigneCreateRelPath = {
    name: '',
    path: 'create'
};

export const CommanderEnLigneDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Remboursement
 */
export const RemboursementCautionRelPath = {
    name: 'Remboursement',
    path: 'remboursement',
    icon: <GiTakeMyMoney className="icon-custom" />
};

export const RemboursementCautionCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Restitution
 */
export const RestitutionPerteRelPath = {
    name: 'Restitution',
    path: 'restitution',
    icon: <GiPayMoney className="icon-custom" />
};

export const RestitutionPerteCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Compte Externes
 */

/**
 * @desc Compte Externes Bancaire
 */

export const ComptesBancaireRelPath = {
    name: 'Compte Bancaire',
    path: 'compte-bancaire',
    icon: <MdAccountBalance className="icon-custom" />
};

export const ComptesBancaireCreateRelPath = {
    name: '',
    path: 'create'
};

export const ComptesBancaireDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Compte Externes Distributeurs
 */

export const ComptesDistributeurRelPath = {
    name: 'Compte Distributeur',
    path: 'compte-distributeur',
    icon: <AiOutlineCluster className="icon-custom" />
};

export const ComptesDistributeurCreateRelPath = {
    name: '',
    path: 'create'
};

export const ComptesDistributeurDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Compte Externes Mobile Money
 */

export const ComptesMobileMoneyRelPath = {
    name: 'Compte Money',
    path: 'compte-money',
    icon: <MdMobileScreenShare className="icon-custom" />
};

export const ComptesMobileMoneyCreateRelPath = {
    name: '',
    path: 'create'
};

export const ComptesMobileMoneyDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Budget Prévisionnel
 */

export const BudgetPrevisionnelRelPath = {
    name: 'Prévisionnel',
    path: 'budget-previsionnel',
    icon: <GiAbacus className="icon-custom" />
};

export const BudgetPrevisionnelAmendementChargeFixeCreateRelPath = {
    name: '',
    path: 'create-amendfx'
};

export const BudgetPrevisionnelAmendementChargeVariableCreateRelPath = {
    name: '',
    path: 'create-amendvr'
};

export const BudgetPrevisionnelAmendementRessourceCreateRelPath = {
    name: '',
    path: 'create-amendrs'
};

export const BudgetPrevisionnelDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Budget Additionnel
 */

export const BudgetAdditionnelRelPath = {
    name: 'Additionnel',
    path: 'budget-additionnel',
    icon: <GiAbacus className="icon-custom" />
};

export const BudgetAdditionnelAmendementChargeFixeCreateRelPath = {
    name: '',
    path: 'create-amendfx'
};

export const BudgetAdditionnelAmendementChargeVariableCreateRelPath = {
    name: '',
    path: 'create-amendvr'
};

export const BudgetAdditionnelAmendementRessourceCreateRelPath = {
    name: '',
    path: 'create-amendrs'
};

export const BudgetAdditionnelDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Achat Marchand
 */

export const AchatMarchandToMarchandRelPath = {
    name: 'Achat M2M',
    path: 'achat-marchand',
    icon: <BsCart4 className="icon-custom" />
};

// export const AchatMarchandToMarchandCreateRelPath = {
//     name: '',
//     path: 'create'
// };

// export const AchatMarchandToMarchandDetailRelPath = {
//     name: '',
//     path: ':id'
// };

/**
 * @desc Notification Evolution Revendeur
 */

export const NotificationEvolutionRevendeurRelPath = {
    name: 'Notification évolution Revendeur',
    path: 'notif-zone',
    icon: <MdNotificationsActive className="icon-custom" />
};

export const NotificationEvolutionRevendeurDetailRelPath = {
    name: '',
    path: ':id'
};

export const NotificationEvolutionRevendeurCreateRelPath = {
    name: '',
    path: 'create'
};

/**
 * @desc Paiement caisse execution charge
 */

export const PaiementCompteExecutionRelPath = {
    name: 'Accusé réception exécution charge',
    path: 'accuse-reception-charge',
    icon: <FaWallet className="icon-custom" />
};

export const PaiementCompteExecutionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Dispatching Paiement caisse execution charge
 */

export const DispatchingPaiementCompteExecutionRelPath = {
    name: 'Dispatching Paiement exécution charge',
    path: 'dispatching-paiement-exec-charge',
    icon: <GiFamilyTree className="icon-custom" />
};

export const DispatchingPaiementCompteExecutionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Instruction
 */

export const InstructionRelPath = {
    name: 'Instructions',
    path: 'instruction',
    icon: <BiMailSend className="icon-custom" />
};

export const InstructionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Exercice
 */

export const ExerciceRelPath = {
    name: 'Exercices',
    path: 'exercice',
    icon: <FaCalendarAlt className="icon-custom" />
};

export const ExerciceCreateRelPath = {
    name: '',
    path: 'create'
};

export const ExerciceDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Profil User dedié
 */

export const ProfilUserDedierRelPath = {
    name: 'Profil Dédié',
    path: 'profil-dedie',
    icon: <TiGroup className="icon-custom" />
};

export const ProfilUserDedierDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Paiement Caisse Sectorielle
 */

export const PaiementCaisseSectorielleRelPath = {
    name: 'Paiement Caisse Sectorielle',
    path: 'paiement-caisse-secto',
    icon: <TiGroup className="icon-custom" />
};

export const PaiementCaisseSectorielleDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Paiement Caisse Sectorielle
 */

export const CrediterCautionRelPath = {
    name: 'Créditer Caution',
    path: 'credit-caution',
    icon: <TiGroup className="icon-custom" />
};

export const CrediterCautionCreateRelPath = {
    name: '',
    path: 'create'
};

export const CrediterCautionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Configuration kindistribution rel paths
 */
export const ConfigurationKindistributionViewLayoutRelPath = {
    name: 'Configuration kindistribution',
    path: 'configuration-kindistribution',
    icon: <BiMailSend className="icon-custom" />
};

export const ConfigurationKindistributionCreateRelPath = {
    name: '',
    path: 'create'
};

export const ConfigurationKindistributionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Config equipe technique rel paths
 */
export const ConfigEquipeTechniqueViewLayoutRelPath = {
    name: 'Config equipe technique',
    path: 'config-equipe-technique',
    icon: <BiMailSend className="icon-custom" />
};

export const ConfigEquipeTechniqueCreateRelPath = {
    name: '',
    path: 'create'
};

export const ConfigEquipeTechniqueDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Configuration sender rel paths
 */
export const ConfigurationSenderViewLayoutRelPath = {
    name: 'Configuration sender',
    path: 'configuration-sender',
    icon: <BiMailSend className="icon-custom" />
};

export const ConfigurationSenderCreateRelPath = {
    name: '',
    path: 'create'
};

export const ConfigurationSenderDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Upload fichier Releve Bancaire rel paths
 */
export const UploadFichierViewLayoutRelPath = {
    name: 'Relevé Bancaire',
    path: 'upload-fichier',
    icon: <BiMailSend className="icon-custom" />
};

export const UploadFichierCreateRelPath = {
    name: '',
    path: 'create'
};

export const UploadFichierDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Upload fichier Releve Emoney rel paths
 */
export const UploadFichierReleveEmoneyViewLayoutRelPath = {
    name: 'Importation Relevé Emoney',
    path: 'import-rlv-emoney',
    icon: <BiMailSend className="icon-custom" />
};

export const UploadFichierReleveEmoneyCreateRelPath = {
    name: '',
    path: 'create'
};

export const UploadFichierReleveEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Upload fichier Releve Emoney rel paths
 */
export const UploadFichierPresenceViewLayoutRelPath = {
    name: 'Importation Presence',
    path: 'import-presence-file',
    icon: <BiMailSend className="icon-custom" />
};

export const UploadFichierPresenceCreateRelPath = {
    name: '',
    path: 'create'
};

export const UploadFichierPresenceDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Upload fichier Releve Flash rel paths
 */
export const UploadFichierReleveFlashViewLayoutRelPath = {
    name: 'Importation Relevé Flash',
    path: 'import-rlv-flash',
    icon: <BiMailSend className="icon-custom" />
};

export const UploadFichierReleveFlashCreateRelPath = {
    name: '',
    path: 'create'
};

export const UploadFichierReleveFlashDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Limit credit rel paths
 */
export const LimitCreditViewLayoutRelPath = {
    name: 'Limit credit',
    path: 'limit-credit',
    icon: <BiMailSend className="icon-custom" />
};

export const LimitCreditCreateRelPath = {
    name: '',
    path: 'create'
};

export const LimitCreditDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Cash collection rel paths
 */
export const CashCollectionViewLayoutRelPath = {
    name: 'Cash collection',
    path: 'cash-collection',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const CashCollectionCreateRelPath = {
    name: '',
    path: 'create'
};

export const CashCollectionDetailRelPath = {
    name: '',
    path: ':id'
};

export const CashCollectionRegisterRelPath = {
    name: '',
    path: 'v1/add/create'
};

/**
 * @desc Arret service vente marchand rel paths
 */
export const ShutDownAndUpApiPartnerViewLayoutRelPath = {
    name: 'Api-Partenaire',
    path: 'down-up-api',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ShutDownAndUpApiPartnerCreateRelPath = {
    name: '',
    path: 'create'
};

export const ShutDownAndUpApiPartnerDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Configuration instruction rel paths
 */
export const ConfigurationInstructionViewLayoutRelPath = {
    name: 'Config instruction',
    path: 'configuration-instruction',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ConfigurationInstructionCreateRelPath = {
    name: '',
    path: 'create'
};

export const ConfigurationInstructionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Regularisation caisse emoney rel paths
 */
export const RegularisationCaisseEmoneyViewLayoutRelPath = {
    name: 'Regularisation caisse',
    path: 'rgl-caisse-emoney',
    icon: <BiMailSend className="icon-custom" />
};

export const RegularisationCaisseEmoneyCreateRelPath = {
    name: '',
    path: 'create'
};

export const RegularisationCaisseEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Tunnel rel paths
 */
export const TunnelViewLayoutRelPath = {
    name: 'Tunnel',
    path: 'tunnel',
    icon: <BiMailSend className="icon-custom" />
};

export const TunnelDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Budget
 */

/**
 * Elaboration
 */
export const ElaborationRelPath = {
    name: 'Elaboration',
    path: 'elaboration-budget',
    icon: <AiOutlineFundProjectionScreen className="icon-custom" />
};

export const ElaborationAdditionnelCreateRelPath = {
    name: '',
    path: 'add-create'
};

export const ElaborationAdditionnelFixeCreateRelPath = {
    name: '',
    path: 'add-create-fix'
};
export const ElaborationAdditionnelVariableCreateRelPath = {
    name: '',
    path: 'add-create-var'
};
export const ElaborationAdditionnelRessourceCreateRelPath = {
    name: '',
    path: 'add-create-ressource'
};

export const ElaborationPrevisionnelCreateRelPath = {
    name: '',
    path: 'prev-create'
};

export const ElaborationPrevisionnelFixeCreateRelPath = {
    name: '',
    path: 'prev-create-fix'
};
export const ElaborationPrevisionnelVariableCreateRelPath = {
    name: '',
    path: 'prev-create-var'
};
export const ElaborationPrevisionnelRessourceCreateRelPath = {
    name: '',
    path: 'prev-create-ressource'
};

export const ElaborationDetailRelPath = {
    name: '',
    path: ':id'
};

export const ElaborationCompletionRelPath = {
    name: '',
    path: ':id/create'
};

export const ElaborationAmendementRelPath = {
    name: '',
    path: ':id/amend'
};

export const ElaborationVoirPlusRelPath = {
    name: '',
    path: ':id/more'
};

export const ElaborationVoirPlusChargeFixeRelPath = {
    name: '',
    path: ':id/more/fixe'
};

export const ElaborationVoirPlusChargeVariableRelPath = {
    name: '',
    path: ':id/more/variable'
};

export const ElaborationVoirPlusRessourceRelPath = {
    name: '',
    path: ':id/more/ressource'
};

/**
 * Amendement
 */

export const AmenedementRelPath = {
    name: 'Amendement',
    path: 'amendement-budget',
    icon: <BsGraphUp className="icon-custom" />
};

export const AmenedementCreateRelPath = {
    name: '',
    path: ':id/create'
};

export const AmenedementDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 *  @desc Gestion revendeur rel paths
 */
export const GestionRevendeurViewLayoutRelPath = {
    name: 'Status Revendeur',
    path: 'stats-rev',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const GestionRevendeurCreateRelPath = {
    name: '',
    path: 'create'
};

export const GestionRevendeurDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Formulaire dynamique sim flash rel paths
 */
export const FormulaireDynamiqueSimFlashViewLayoutRelPath = {
    name: 'Configuration Sim',
    path: 'dmd-sim',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const FormulaireDynamiqueSimFlashDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Activateur rel paths
 */
export const ActivateurViewLayoutRelPath = {
    name: 'Importation Paiement Vendeur',
    path: 'imp-activ',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ActivateurCreateRelPath = {
    name: '',
    path: 'create'
};

export const ActivateurDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Taches performance agent rel paths
 */
export const TachesPerformanceAgentViewLayoutRelPath = {
    name: 'Taches performance agent',
    path: 'taches-performance-agent',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const TachesPerformanceAgentCreateRelPath = {
    name: '',
    path: 'create'
};

export const TachesPerformanceAgentDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Objectifs rel paths
 */
export const ObjectifsViewLayoutRelPath = {
    name: 'Objectifs',
    path: 'objectifs',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ObjectifsCreateRelPath = {
    name: '',
    path: 'create'
};

export const ObjectifsDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Confirmation solde rel paths
 */
export const ConfirmationSoldeCompteViewLayoutRelPath = {
    name: 'Comptes',
    path: 'confirm-solde-compte',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ConfirmationSoldeCompteCreateRelPath = {
    name: '',
    path: 'create'
};

export const ConfirmationSoldeCompteDetailRelPath = {
    name: '',
    path: ':id'
};
