/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    TIME,
    formInitialState,
    BUDGET_ELABORATION,
    TYPE_BUDGET_ADDITIONNEL,
    TYPE_BUDGET_PREVISIONNEL,
    CHARGE,
    BUDGET_AMENDEMENT,
    BENEFICIAIRE,
    GenericModel,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    BackComponent,
    ConditionalRenderingWrapper,
    CustomButton,
    CustomCenteredModal,
    CustomToast,
    FormWrapper
    // StateShowerComponent
} from '../../../../../components';
import { ChargeFixeBaseForm, LeftSidePannel } from '../../common/base';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    toggledView,
    toggledVisibilityView,
    useGetTypeBeneficiaires,
    useGeneratePayloadAndDataList,
    useGetPeriodesExercice,
    tablePreviewData
} from '../../common/util';
import { getCustomUrl } from '../../../../../helpers';
import { elaborartionReformat } from '../utils';
import { TrashDataPannel } from '../trash-data';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    estPrevisionnel: true,
    isDraft: false,
    draftNetWorkBehavior: {}
};

/**
 * @description dependencies du state du formulaire
 */
const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        // 'charges',
        'caisses',
        'exercicesFinancier',
        'employes',
        'users',
        'partenaires',
        // 'brouillon',
        'detail'
    ]
};

/**
 * Principale vue des vues pour les charges fixes
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @param {String} param0.isCompletion: qui sera du type true | False, pour savoir si on fait la completion d'une élaboration existante
 * @returns
 */
export function DetailChargeFixes({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = true
}) {
    const { id: idEntity } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search.replace('?', ''));
    const payloadUrl = {
        start: Number(searchParams.get('start')),
        end: Number(searchParams.get('end')),
        codeCharge: searchParams.get('charge')
    };

    // console.log(idEntity)
    const reduxDispatcher = useDispatch();
    // const navigate = useNavigate();
    // const path = useLocation();
    const [show, setShow] = useState(false);
    // const [idTarget, setIdTarget] = useState(null);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            element: {
                code: '',
                designation: '' /** Property to add for displaying purpose */
            },
            typeMoyenPaiement: '',
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
            estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
            prix: 0,
            isTooggled: true,
            isVisible: false,
            isDeleted: false,
            details: [
                {
                    direction: '',
                    directionDesignation:
                        '' /** Property to add for displaying purpose */,
                    secteur: {
                        code: '',
                        designation: ''
                    },
                    isTooggled: true,
                    isVisible: false,
                    idCaisse: '',
                    caisseDesignation:
                        '' /** Property to add for displaying purpose */,
                    prix: 0,
                    beneficiaires: [
                        {
                            typeEntite: '',
                            typeEntiteDesignation:
                                '' /** Property to add for displaying purpose */,
                            valeurTypeEntite: {
                                reference: '',
                                designation:
                                    '' /** Property to add for displaying purpose */
                            },
                            idCaisse: '',
                            caisseDesignation:
                                '' /** Property to add for displaying purpose */,
                            prix: 0,
                            quantite: 0,
                            montant: 0,
                            isTooggled: true,
                            isVisible: false,
                            periodes: [
                                {
                                    dateDebut: 0,
                                    dateFin: 0,
                                    datePaiement: 0,
                                    quantite: 0,
                                    montant: 0,
                                    datePaiementString: '',
                                    mois: '' /** Property to add for displaying month */
                                    // isTooggled: true,
                                    // isVisible: false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);
    // End Region

    const {
        firebaseUser,
        secteurs,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier,
        users,
        etatUsers,
        employesOrganisation,
        etatEmployesOrganisation,
        partenaire,
        etatPartenaire,
        // brouillons,
        // etatBrouillon,
        dynamicNode,
        dynamicEtatNode
        // ecouteurBrouillon
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        secteurs: reduxState.secteurs,
        etatSecteurs: reduxState.etat.secteurs.etat,
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation}-${item?.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier,
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,

        users: reduxState.users.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.USER.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatUsers: reduxState.etat.users.etat,
        employesOrganisation: reduxState.employesOrganisation
            .filter((item) => !item?.idUser)
            .map((item) => ({
                ...item,
                typeBeneficiaire: BENEFICIAIRE.EMPLOYE.code,
                designation: `${item?.personne?.prenom || ' '} ${
                    item?.personne?.nom || ' '
                }`
            })),
        etatEmployesOrganisation: reduxState.etat.employesOrganisation.etat,
        partenaire: reduxState.beneficiaires.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.PARTENAIRE.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatPartenaire: reduxState.etat.beneficiaires.etat,
        // brouillons: reduxState.brouillonChargeFixe.filter(
        //     (item) =>
        //         item.type ===
        //             (isAdditionnel
        //                 ? TYPE_BUDGET_ADDITIONNEL.code
        //                 : TYPE_BUDGET_PREVISIONNEL.code) &&
        //         item.typeEntite ===
        //             (isAmendement ? BUDGET_AMENDEMENT : BUDGET_ELABORATION) &&
        //         !item.estVariable &&
        //         item.etat === ETAT.ETAT_ENCOURS
        // ),
        // etatBrouillon: reduxState.etat.brouillonChargeFixe.etat,
        dynamicNode: reduxState.dynamicNode,
        dynamicEtatNode: reduxState.dynamicEtatNode
        // ecouteurBrouillon: reduxState.etat.brouillonChargeFixe.ecouteur
    }));

    /**
     * @description: exercice financier format
     */
    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const getTypeBeneficiaire = useGetTypeBeneficiaires({
        employes: employesOrganisation,
        users,
        partenaires: partenaire
    });
    const { fields, form /** elements */ } = formState;

    const exerciceCallback = useCallback(
        ({ data }) =>
            data.find(
                (item) =>
                    item.id === fields?.selectedExercice.id ||
                    (item.dateDebut === payloadUrl?.start &&
                        item.dateFin === payloadUrl?.end)
            ),
        [fields?.selectedExercice.id, payloadUrl?.end, payloadUrl?.start]
    );

    const exerciceCible = exerciceCallback({ data: exercices });

    const periodes = useGetPeriodesExercice({
        exercice: exerciceCible,
        payloadUrl
    });

    const dynamicNodeName = `${idEntity}-amendement`;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUsers
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLOYE_ORGANISATION,
                nodeName: DB_NODE.EMPLOYE,
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatPartenaire
            },
            {
                isOnlyFetch: true,
                dynamicNodeName,
                isDynamicNode: true,
                functionName: URL_CONST.GET_LIST_ELEMENT_ELABORATION_AMENDEMENT,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    reference: idEntity,
                    property:
                        'elaborationAmendementPeriode.idElaborationAmendement',
                    collection: 'DetailElaborationAmendement'
                },
                isCondition: !!idEntity
            }
        ]
    });

    /**
     * End Region
     */

    const { payloadList } = useGeneratePayloadAndDataList({
        formFields,
        tablePreviewData
    });

    /**
     * Fonction qui permet de scroller jusqu'à un élément précis
     */
    // const smoothScroll = ({ idDocument }) => {
    //     const element = document.getElementById(idDocument);
    //     if (element) {
    //         element.scrollIntoView({top: 56, behavior: 'smooth', block: 'start' });
    //     }
    // };

    // useEffect(() => {
    //     if (path.pathname && idTarget) {
    //         smoothScroll({ idDocument: idTarget });
    //     }
    // }, [path, idTarget]);

    /**
     * //FIXME: Inside this fonction, I will try to mixup draft logical and detailElaboration
     */

    const memoisedDraft = useCallback(() => {
        if (
            dynamicNode[dynamicNodeName] &&
            dynamicNode?.[dynamicNodeName]?.length
            // ((exerciceCible?.dateDebut && exerciceCible?.dateFin) ||
            //     (payloadUrl?.start && payloadUrl?.end))
        ) {
            // const payloadChecker = {
            //     dateDebut: exerciceCible?.dateDebut || payloadUrl?.start,
            //     dateFin: exerciceCible?.dateFin || payloadUrl?.end,
            //     estPrevisionnel: idEntity
            //         ? isAdditionnel
            //         : fields.estPrevisionnel
            // };
            // const findDraft = draft.find(
            //     (item) =>
            //         item?.periode?.dateDebut ===
            //             payloadChecker?.dateDebut &&
            //         item?.periode?.dateFin === payloadChecker?.dateFin &&
            //         item?.estPrevisionnel ===
            //             payloadChecker?.estPrevisionnel
            // );
            const parsedBruillon =
                elaborartionReformat({
                    liste: dynamicNode[dynamicNodeName] || [],
                    codeCharge: payloadUrl.codeCharge
                }) || [];

            if (payloadUrl.codeCharge) {
                setFormFields([...parsedBruillon]);
            } else {
                setFormFields([
                    ...parsedBruillon,
                    {
                        element: {
                            code: '',
                            designation:
                                '' /** Property to add for displaying purpose */
                        },
                        typeMoyenPaiement: '',
                        idCaisse: '',
                        caisseDesignation:
                            '' /** Property to add for displaying purpose */,
                        estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                        estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                        prix: 0,
                        isTooggled: true,
                        isVisible: false,
                        isDeleted: false,
                        details: [
                            {
                                direction: '',
                                directionDesignation:
                                    '' /** Property to add for displaying purpose */,
                                secteur: {
                                    code: '',
                                    designation: ''
                                },
                                isTooggled: true,
                                isVisible: false,
                                idCaisse: '',
                                caisseDesignation:
                                    '' /** Property to add for displaying purpose */,
                                prix: 0,
                                beneficiaires: [
                                    {
                                        typeEntite: '',
                                        typeEntiteDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        valeurTypeEntite: {
                                            reference: '',
                                            designation:
                                                '' /** Property to add for displaying purpose */
                                        },
                                        idCaisse: '',
                                        caisseDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        prix: 0,
                                        quantite: 0,
                                        montant: 0,
                                        isTooggled: true,
                                        isVisible: false,
                                        periodes: [
                                            {
                                                dateDebut: 0,
                                                dateFin: 0,
                                                datePaiement: 0,
                                                quantite: 0,
                                                datePaiementString: '',
                                                mois: '' /** Property to add for displaying month */
                                                // isTooggled: true,
                                                // isVisible: false
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]);
            }
        }
    }, [dynamicNode, dynamicNodeName, payloadUrl.codeCharge]);

    /**
     * UseEffect pour mettre à jour le state au cas où un brouillon existe
     */
    useEffect(() => {
        memoisedDraft({
            draft:
                elaborartionReformat({
                    liste: dynamicNode[dynamicNodeName] || []
                }) || []
        });
        return memoisedDraft({ draft: [] });
    }, [dynamicNode, dynamicNodeName, memoisedDraft]);

    const handlePostDraft = ({ isDraft = false }) => {
        if (isDraft) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'draftNetWorkBehavior',
                    data: {}
                }
            });
            const payload = {
                periode: {
                    dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible.dateFin || payloadUrl?.end
                },
                newElaborationAmendement: {
                    typeEntite: isAmendement
                        ? BUDGET_AMENDEMENT
                        : BUDGET_ELABORATION,
                    typeProprietaire: CHARGE.code,
                    estVariable: false,
                    type: isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code
                },
                creator
            };
            if (isAdditionnel) {
                payload.newElaborationAmendement.estPrevisionnel =
                    fields.estPrevisionnel;
            }
            if ((isCompletion || isAmendement) && idEntity) {
                payload.idElaborationAmendement = idEntity;
            }
            if (formFields.length) {
                payload.elemUpdate = {
                    list: formFields
                        .filter((item) => item.element.code)
                        .map((item) => ({ ...item, isTooggled: false }))
                };
            }
            // if (findDraft?.id) {
            //     payload.idBrouillonElaborationAmendement = findDraft?.id;
            // }
            // console.log(payload, findDraft);
            GenericModel.postObjetViaCF({
                idToken,
                customUrl: getCustomUrl({
                    functionName:
                        URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT,
                ...payload
            })
                .then((response) => {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: 'draftNetWorkBehavior',
                            data: {
                                error: undefined,
                                message: response.data.message,
                                isSuccess: true
                            }
                        }
                    });
                })
                .catch((error) => {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: 'draftNetWorkBehavior',
                            data: {
                                error: error.message,
                                message: 'Echec lors de la mise à jour!',
                                isSuccess: false
                            }
                        }
                    });
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * @description : Payload
         */
        const payload = {
            periode: {
                dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: false,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator
        };
        if (isAdditionnel) {
            payload.newElaborationAmendement.estPrevisionnel =
                fields.estPrevisionnel;
        }
        if ((isCompletion || isAmendement) && idEntity) {
            payload.idElaborationAmendement = idEntity;
        }
        if (formFields.length) {
            payload.list = payloadList.filter((item) => item.isDeleted);
        }
        handlePostDraft({ isDraft: false });
        formDispatcher(
            submitPostAction({
                idToken,
                customUrl: getCustomUrl({
                    functionName: URL_CONST.POST_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            isRedirect={false}
            onSubmit={handleSubmit}
            formState={formState}
            isStepBack={false}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'exercicesFinancier',
                    etat: etatExerciceFinancier
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                },
                {
                    dependency: 'employes',
                    etat: etatEmployesOrganisation
                },
                {
                    dependency: 'users',
                    etat: etatUsers
                },
                {
                    dependency: 'partenaires',
                    etat: etatPartenaire
                },
                {
                    dependency: 'detail',
                    etat: dynamicEtatNode[dynamicNodeName]?.etat,
                    isCondition: !!idEntity
                }
            ]}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <BackComponent
                        params={`?start=${Number(
                            searchParams.get('start')
                        )}&end=${Number(searchParams.get('end'))}`}
                        className="btn btn-primary"
                    />
                </div>
                <div>
                    <span className="h1">Amendement</span>
                </div>
            </div>
            <hr />
            {fields.draftNetWorkBehavior.message ? (
                <CustomToast
                    error={
                        fields.draftNetWorkBehavior.error
                            ? fields.draftNetWorkBehavior.message
                            : undefined
                    }
                    successMessage={
                        fields.draftNetWorkBehavior.isSuccess
                            ? fields.draftNetWorkBehavior.message
                            : undefined
                    }
                />
            ) : null}
            <div className="d-flex">
                <LeftSidePannel
                    onShow={onShow}
                    formFields={formFields}
                    toggledVisibilityView={toggledVisibilityView}
                    toggledView={toggledView}
                    handleSubmit={handleSubmit}
                    form={form}
                    setFormFields={setFormFields}
                    handleRemove={handleRemove}
                    notFormatedData={dynamicNode[dynamicNodeName] || []}
                    isViewDetailAction
                />
                <div className="col-9 ms-2">
                    <CustomCenteredModal
                        show={show}
                        onHide={onHide}
                        header="Charges à amander"
                        size="lg"
                        // backdrop={backdrop}
                    >
                        <TrashDataPannel
                            onShow={onShow}
                            formFields={formFields}
                            toggledVisibilityView={toggledVisibilityView}
                            toggledView={toggledView}
                            handleSubmit={handleSubmit}
                            form={form}
                            setFormFields={setFormFields}
                            handleRemove={handleRemove}
                            notFormatedData={dynamicNode[dynamicNodeName] || []}
                            isViewDetailAction
                        />
                    </CustomCenteredModal>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={!exerciceCible?.id}
                    >
                        <div
                            className="alert alert-warning d-flex align-items-center mt-3"
                            role="alert"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                viewBox="0 0 16 16"
                                role="img"
                                aria-label="Warning:"
                            >
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                            <div>
                                Cette action ne pointe vers aucun exercice,
                                reprenez le processus!
                            </div>
                        </div>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={!!exerciceCible?.id}
                    >
                        {formFields.map((field, index) => (
                            <Fragment key={index}>
                                {field.isTooggled ? (
                                    <ChargeFixeBaseForm
                                        caisses={caisses}
                                        charges={formFields
                                            .filter(
                                                (item) =>
                                                    item.isTooggled &&
                                                    !!item.element.code
                                            )
                                            .map((item) => ({
                                                code: item?.element?.code,
                                                designation:
                                                    item?.element?.designation,
                                                devise: item?.devise,
                                                estQuantite:
                                                    item?.estQuantite || true,
                                                estRempliAutomatique:
                                                    item?.estRempliAutomatique ||
                                                    false,
                                                typeMoyenPaiement:
                                                    item?.typeMoyenPaiement
                                            }))}
                                        directions={directions}
                                        field={field}
                                        formFields={formFields}
                                        setFormFields={setFormFields}
                                        getTypeBeneficiaire={
                                            getTypeBeneficiaire
                                        }
                                        handleAddHighLevel={handleAddHighLevel}
                                        handleChange={handleChange}
                                        handleRemove={handleRemove}
                                        handleSelectChange={handleSelectChange}
                                        index={index}
                                        periodes={periodes}
                                        secteurs={secteurs}
                                        onToggleView={toggledView}
                                        isViewDetailAction
                                    />
                                ) : null}
                            </Fragment>
                        ))}
                        <div className="d-flex justify-content-center">
                            <div className="btn-group me-2">
                                <CustomButton
                                    // modalText="Voulez-vous soumettre cette élaboration?"
                                    // modalChildren={draftComponentAction}
                                    type="button"
                                    text="+ Ajouter"
                                    className="btn-warning btn"
                                    onClick={() => {
                                        handleAddHighLevel({
                                            type: 'Charge',
                                            setFormFields,
                                            formFields
                                        });
                                        handlePostDraft({ isDraft: false });
                                    }}
                                />
                                <CustomButton
                                    // modalText="Voulez-vous soumettre cette élaboration?"
                                    // modalChildren={draftComponentAction}
                                    type="button"
                                    text="+ Amender"
                                    className="btn-success btn ms-2"
                                    onClick={() => {
                                        toggledVisibilityView({
                                            view: 'ChargeFixe',
                                            setFormFields,
                                            formFields,
                                            type: 'trash',
                                            handleAddHighLevel
                                        });
                                        handlePostDraft({ isDraft: false });
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-between">
                            <div className="col-5">
                                <StateShowerComponent state={formFields} />
                            </div>
                            <div className="col-5">
                                <StateShowerComponent state={payloadList} />
                            </div>
                        </div> */}
                    </ConditionalRenderingWrapper>
                </div>
            </div>
        </FormWrapper>
    );
    return renderContent();
}
