import { useState } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    DB_NODE,
    CONST_PERFORMANCE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    toggledView,
    toggledVisibilityView,
    transformedData
} from './helpers';
import { LeftPannel, RightPannel } from './screens';
import { FormWrapper } from '../../../components';

const defaultFields = {};

const defaultDependencies = {
    fields: ['tachesPerformance', 'users']
};

export function CreationObjectifsForm() {
    const reduxDispatcher = useDispatch();
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            codeTache: '',
            designationTache: '',
            isVisible: false,
            isTooggled: true,
            details: [
                {
                    dateDebut: 0,
                    dateString: '',
                    isVisible: false,
                    isTooggled: true,
                    agents: [
                        {
                            reference: '',
                            designation: '',
                            target: 0,
                            devise: ''
                        }
                    ]
                }
            ]
        }
    ]);
    // End Region
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        nappUser,
        users,
        etatUser,
        // monnaiesLocales,
        // etatMonnaieLocale,
        deviseReference,
        dynamicNode,
        dynamicEtatNode
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        deviseReference: reduxState.deviseReference,
        nappUse: reduxState.nappUser,
        users: reduxState.users.map((item) => ({
            ...item,
            reference: item?.uid || item?.id,
            designation: `${item?.personne?.prenom || ''} ${
                item?.personne?.nom || ''
            }`
        })),
        etatUser: reduxState.etat.users.etat,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            ...item,
            code: item.code || item.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat,
        dynamicNode: reduxState.dynamicNode,
        dynamicEtatNode: reduxState.dynamicEtatNode
    }));
    /**
     * Common form processes
     */
    const { idToken, creator } = useGetUserDetail({ firebaseUser, nappUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const dynamicNodeName = `tachePerformancesObjectifs`;
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
            //     nodeName: DB_NODE.MONNAIE_LOCALE,
            //     etat: etatMonnaieLocale
            // },
            {
                isOnlyFetch: true,
                dynamicNodeName,
                isDynamicNode: true,
                functionName: URL_CONST.GET_LIST_TACHE_PERFORMANCE_AGENT,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload: {
                    typeIndicePerformance: CONST_PERFORMANCE.POURCENTAGE
                }
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            }
        ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            creator
        };
        payload.list = transformedData({
            data: formFields,
            devise: deviseReference.code
        });
        if (!payload?.list?.length) return;
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEFINITION_OBJECTIF,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OBJECTIFS,
                reduxDispatcher
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'tachesPerformance',
                    etat: dynamicEtatNode[dynamicNodeName]?.etat
                },
                {
                    dependency: 'users',
                    etat: etatUser
                }
                // {
                //     dependency: 'monnaieLocale',
                //     etat: etatMonnaieLocale
                // }
            ]}
        >
            <div className="d-flex">
                <div className="col-3 my-3 me-2">
                    <LeftPannel
                        form={form}
                        formFields={formFields}
                        handleRemove={handleRemove}
                        handleSubmit={handleSubmit}
                        setFormFields={setFormFields}
                        toggledView={toggledView}
                        toggledVisibilityView={toggledVisibilityView}
                    />
                </div>
                <div className="col-9 my-3">
                    <RightPannel
                        formFields={formFields}
                        handleAddHighLevel={handleAddHighLevel}
                        handleChange={handleChange}
                        handleRemove={handleRemove}
                        handleSelectChange={handleSelectChange}
                        setFormFields={setFormFields}
                        users={users}
                        tasks={dynamicNode[dynamicNodeName]}
                    />
                </div>
            </div>
        </FormWrapper>
    );
    return renderContent();
}
