import React, { useState, useMemo } from 'react';
import { matchSorter } from 'match-sorter';
import { useAsyncDebounce } from 'react-table';
import { Array } from '@napp-inc/jnapp-util';
import { ExcelExporter } from '../special-components/excel-exporter';
import { RefetchListData } from '../special-components/refetch-list-data';
import { ConditionalRenderingWrapper } from '../container';
import { SenderMultirowsValidationButton } from '../molecules';

// FIXME: Redo input logic

// Here we define all the filter that could be used

// Define global filtering

/**
 * GlobalFilter pour les filtres de type text
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Array} param0.preGlobalFilteredRows Nombre de lignes avant filtrage
 * @param {Array} param0.globalFilter Nombre de lignes après filtrage
 * @param {Function} param0.setGlobalFilter Fonction qui permet de modifier le filtre global
 * @returns {Array} Array des lignes filtrées
 */
export function GlobalFilter({
    // preGlobalFilteredRows = [],
    globalFilter,
    setGlobalFilter,
    isExportAllowed,
    isRefetchAllowed,
    dataList,
    exportMapper,
    mapper,
    fileName,
    sheetName,
    format,
    refetchFunctionName,
    functionName,
    nodeName,
    isDynamicNode,
    dynamicNodeName,
    payload,
    formater,
    onError,
    listTitle = '',
    isRowSelector,
    formState = {},
    selectedFlatRows = [],
    reduxDispatcher,
    idToken,
    formDispatcher,
    isSenderFlash,
    isSenderEmoney,
    viewSender,
    transformExportDataFunction,
    filterComplement
}) {
    // const count =
    //     typeof preGlobalFilteredRows === 'object' &&
    //     preGlobalFilteredRows.length !== 0
    //         ? preGlobalFilteredRows.length
    //         : 0;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(
        (val) => setGlobalFilter(val || undefined),
        200
    );

    const getResultFlatenRows = (data) => {
        const groupedData = Array.groupByProperty({
            array: data.map((dt) => ({ ...dt.original })),
            property: 'devise'
        });
        const dataKeys = Object.keys(groupedData);
        const resultat = dataKeys.map((item) => ({
            devise: item,
            value: groupedData[item].reduce((a, b) => a + b.montant, 0)
        }));
        return resultat;
    };

    const classNameToSend = () => {
        if (listTitle || (!!isRowSelector && isSenderEmoney))
            return 'justify-content-between';
        return 'justify-content-end';
    };

    return (
        <div className={`mb-1 d-flex ${classNameToSend()} `}>
            {listTitle ? (
                <div>
                    <span className="lead fs-5">{listTitle}</span>{' '}
                </div>
            ) : null}
            <ConditionalRenderingWrapper
                isShouldBeRendered={!!isRowSelector && isSenderEmoney}
            >
                <div className="fw-bold">
                    #
                    {getResultFlatenRows(selectedFlatRows).map(
                        (item, index) => (
                            <span key={item.devise}>
                                {index !== 0 ? '|' : null}
                                {item.devise}: {item.value}
                            </span>
                        )
                    )}
                </div>
            </ConditionalRenderingWrapper>
            <div className="mb-1 d-flex justify-content-end">
                {isRefetchAllowed && (
                    <div className="pe-1">
                        <RefetchListData
                            functionName={refetchFunctionName || functionName}
                            nodeName={nodeName}
                            isDynamicNode={isDynamicNode}
                            dynamicNodeName={dynamicNodeName}
                            payload={payload}
                            formater={formater}
                            onError={onError}
                        />
                    </div>
                )}
                {isExportAllowed && (
                    <div className="mx-3 pe-1">
                        <ExcelExporter
                            dataList={dataList}
                            mapper={exportMapper || mapper}
                            fileName={fileName}
                            sheetName={sheetName}
                            format={format}
                            transformExportDataFunction={
                                transformExportDataFunction
                            }
                        />
                    </div>
                )}
                <div className="">
                    <input
                        className="form-control form-control-sm"
                        value={value || ''}
                        id="customTableRechercheInputId"
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        // placeholder={`${count} enregistrement(s)...`}
                        placeholder="Rechercher..."
                        style={{
                            fontSize: '.85rem',
                            border: '1'
                        }}
                    />
                </div>
                {filterComplement || null}
                <ConditionalRenderingWrapper
                    isShouldBeRendered={!!isRowSelector}
                >
                    <SenderMultirowsValidationButton
                        reduxDispatcher={reduxDispatcher}
                        nodeName={nodeName}
                        idToken={idToken}
                        formDispatcher={formDispatcher}
                        selectedFlatRows={selectedFlatRows}
                        formState={formState}
                        isRowSelector={isRowSelector}
                        isSenderFlash={isSenderFlash}
                        isSenderEmoney={isSenderEmoney}
                        viewSender={viewSender}
                    />
                </ConditionalRenderingWrapper>
            </div>
        </div>
    );
}

/**
 * Filtre de type text pour les colonnes spécifiques
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Object} column Object qui contient les props de la colonne:
 * @param {String} column.filterValue Valeur du filtre
 * @param {Array} column.preFilteredRows Nombre de lignes avant filtrage
 * @param {Function} column.setFilter Fonction qui permet de modifier le filtre
 * @returns {Array} Array des lignes filtrées
 */
export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count =
        typeof preFilteredRows === 'object' && preFilteredRows.length !== 0
            ? preFilteredRows.length
            : 0;
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} Enregistrement(s)...`}
        />
    );
}

// This is a custom filter for selecting
// a unique option from a list

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
}) {
    const MemoizedOptions = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">Tous</option>
            {MemoizedOptions.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id }
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [MemoizedMin, MemoizedMax] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0]?.values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0]?.values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={MemoizedMin}
                max={MemoizedMax}
                value={filterValue || MemoizedMin}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button type="button" onClick={() => setFilter(undefined)}>
                Off
            </button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
    const [MemoizedMin, MemoizedMax] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0]?.values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0]?.values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: 'flex'
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1]
                    ]);
                }}
                placeholder={`Min (${MemoizedMin})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem'
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined
                    ]);
                }}
                placeholder={`Max (${MemoizedMax})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem'
                }}
            />
        </div>
    );
}

export function fuzzyTextFilter(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.value[id]] });
}

fuzzyTextFilter.autoRemove = (val) => !val;

// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';
