import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Finance } from '../../../module';
import {
    ApproCashCreationRelPath,
    ApproCashDetailRelPath,
    ApproCashListRelPath,
    ApproCompteEmoneyCreateRelPath,
    ApproCompteEmoneyDetailRelPath,
    ApproCompteEmoneyViewLayoutRelPath,
    ChangeCompteCreateRelPath,
    ChangeCreateRelPath,
    ChangeDetailRelPath,
    ChangeGuichetCreateRelPath,
    ChangeGuichetDetailRelPath,
    ChangeGuichetViewLayoutRelPath,
    ChangeViewLayoutRelPath,
    BalanceOrganisationViewLayoutRelPath,
    CompteBancaireOrganisationCreateRelPath,
    CompteBancaireOrganisationDetailRelPath,
    CompteBancaireOrganisationViewLayoutRelPath,
    CrediterCompteCreateRelPath,
    CrediterCompteDetailRelPath,
    CrediterCompteViewLayoutRelPath,
    OperationBancaireCreateRelPath,
    OperationBancaireDetailRelPath,
    OperationBancaireViewLayoutRelPath,
    DeviseCreateRelPath,
    DeviseDetailRelPath,
    DeviseViewLayoutRelPath,
    EncaissementDecaissementCreateRelPath,
    EncaissementDecaissementDetailRelPath,
    EncaissementDecaissementViewLayoutRelPath,
    LigneCreditCreateRelPath,
    LigneCreditDetailRelPath,
    LigneCreditViewLayoutRelPath,
    ReportStockSuperDealerViewLayoutRelPath,
    TauxCreateRelPath,
    TauxDetailRelPath,
    TauxViewLayoutRelPath,
    TauxActifsViewLayoutRelPath,
    TransfertCreateRelPath,
    TransfertDetailRelPath,
    TransfertFondsCreateRelPath,
    TransfertFondsDetailRelPath,
    TransfertFondsViewLayoutRelPath,
    TransfertViewLayoutRelPath,
    VirementCreateRelPath,
    VirementDetailRelPath,
    VirementViewLayoutRelPath,
    DecaissementViewLayoutRelPath,
    DecaissementCreateRelPath,
    DecaissementDetailRelPath,
    RaisonViewLayoutRelPath,
    RaisonCreateRelPath,
    RaisonDetailRelPath,
    DepensesViewLayoutRelPath,
    DepensesCreateRelPath,
    DepensesDetailRelPath,
    CompteCautionOrganisationViewLayoutRelPath,
    CompteTransfertOrganisationViewLayoutRelPath,
    VirementKinDBViewLayoutRelPath,
    VirementKinDBCreateRelPath,
    VirementKinDBDetailRelPath,
    CashbookViewLayoutRelPath,
    TransfertInterInstitutionnelViewLayoutRelPath,
    TransfertInterInstitutionnelDetailRelPath,
    TransfertInterInstitutionnelCreateRelPath,
    CrediterBalanceCautionCreateRelPath,
    CrediterBalanceCautionDetailRelPath,
    CrediterBalanceCautionViewLayoutRelPath,
    TauxKindistributionViewLayoutRelPath,
    TauxKindistributionCreateRelPath,
    TauxKindistributionDetailRelPath
    // TransfertFondsCaisseKindbViewLayoutRelPath,
    // TransfertFondsCaisseKindbCreateRelPath,
    // TransfertFondsCaisseKindbDetailRelPath
} from './finance-rel-path';

/**
 * @desc Ligne Crédit dealer
 */

export const LigneCreditViewLayoutPath = {
    ...LigneCreditViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.LigneCreditViewLayout,
    element: <Finance.LigneCreditViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_LIGNE_CREDIT,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_LIGNE_CREDIT_BY_ORGANISATION,
        CODE_SERVICE.FINANCE_ORGANISATION.METTRE_A_JOUR_LIGNE_CREDIT
    ]
};

export const LigneCreditCreatePath = {
    ...LigneCreditCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationLigneCreditForm,
    element: <Finance.CreationLigneCreditForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_LIGNE_CREDIT
    ]
};

export const LigneCreditDetailPath = {
    ...LigneCreditDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailLigneCreditForm,
    element: <Finance.DetailLigneCreditForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_LIGNE_CREDIT,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_LIGNE_CREDIT_BY_ORGANISATION,
        CODE_SERVICE.FINANCE_ORGANISATION.METTRE_A_JOUR_LIGNE_CREDIT
    ]
};
/**
 * @desc Compte bancaire organisation
 */

export const CompteBancaireOrganisationViewLayoutPath = {
    ...CompteBancaireOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Finance.CompteBancaireOrganisationViewLayout,
    element: <Finance.CompteBancaireOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_CREATION_COMPTE_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_LIST_COMPTE_BANCAIRE
    ]
};

export const CompteBancaireOrganisationCreatePath = {
    ...CompteBancaireOrganisationCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Finance.CreationCompteBancaireOrganisationForm,
    element: <Finance.CreationCompteBancaireOrganisationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_CREATION_COMPTE_BANCAIRE
    ]
};

export const CompteBancaireOrganisationDetailPath = {
    ...CompteBancaireOrganisationDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Finance.DetailCompteBancaireOrganisationForm,
    element: <Finance.DetailCompteBancaireOrganisationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_CREATION_COMPTE_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_LIST_COMPTE_BANCAIRE
    ]
};
/**
 * @desc Transfert fonds
 */

export const TransfertFondsViewLayoutPath = {
    ...TransfertFondsViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TransfertFondsViewLayout,
    element: <Finance.TransfertFondsViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_MARCHAND
            .INITIER_TRANSFERT_FOND,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_MARCHAND
            .LECTURE_TRANSFERT_FOND
    ]
};

export const TransfertFondsCreatePath = {
    ...TransfertFondsCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationTransfertFondsForm,
    element: <Finance.CreationTransfertFondsForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_MARCHAND
            .INITIER_TRANSFERT_FOND
    ]
};

export const TransfertFondsDetailPath = {
    ...TransfertFondsDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailTransfertFondsForm,
    element: <Finance.DetailTransfertFondsForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_MARCHAND
            .INITIER_TRANSFERT_FOND,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_MARCHAND
            .LECTURE_TRANSFERT_FOND
    ]
};

/**
 * @desc Transfert Caisse KinDB
 */

// export const TransfertFondsCaisseKindbViewLayoutPath = {
//     ...TransfertFondsCaisseKindbViewLayoutRelPath,
//     isVisible: true,
//     dependencies: ['PrincipalMenu'],
//     Component: Finance.TransfertCaisseViewKinDBLayout,
//     element: <Finance.TransfertCaisseViewKinDBLayout />,
//     code: [
//         { code: 'test' },
//         CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_DECAISSEMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
//     ]
// };

// export const TransfertFondsCaisseKindbCreatePath = {
//     ...TransfertFondsCaisseKindbCreateRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Finance.CreationTransfertCaisseKinDBForm,
//     element: <Finance.CreationTransfertCaisseKinDBForm />,
//     code: [
//         { code: 'test' },
//         CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB
//     ]
// };

// export const TransfertFondsCaisseKindbDetailPath = {
//     ...TransfertFondsCaisseKindbDetailRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Finance.DetailTransfertCaisseKinDBForm,
//     element: <Finance.DetailTransfertCaisseKinDBForm />,
//     code: [
//         { code: 'test' },
//         CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_VIREMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_DECAISSEMENT_KINDB,
//         CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
//     ]
// };

/**
 * @desc Créditer compte
 */

export const CrediterCompteViewLayoutPath = {
    ...CrediterCompteViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CrediterCompteViewLayout,
    element: <Finance.CrediterCompteViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_CREDITER_COMPTE_VIA_CASH,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_CREDITER_COMPTE
            .LECTURE_CREDITER_COMPTE_BY_ORGANISATION,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_CREDITER_COMPTE
    ]
};

export const CrediterCompteCreatePath = {
    ...CrediterCompteCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationCrediterCompteForm,
    element: <Finance.CreationCrediterCompteForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_CREDITER_COMPTE_VIA_CASH
    ]
};

export const CrediterCompteDetailPath = {
    ...CrediterCompteDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailCrediterCompteForm,
    element: <Finance.DetailCrediterCompteForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_CREDITER_COMPTE_VIA_CASH,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_CREDITER_COMPTE
            .LECTURE_CREDITER_COMPTE_BY_ORGANISATION,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_CREDITER_COMPTE
    ]
};
/**
 * @desc Appro compte emoney
 */

export const ApproCompteEmoneyViewLayoutPath = {
    ...ApproCompteEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ApproCompteEmoneyViewLayout,
    element: <Finance.ApproCompteEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_APPRO_COMPTE_EMONEY,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_APPRO_COMPTE_EMONEY,
        CODE_SERVICE.FINANCE_ORGANISATION.METTRE_A_JOUR_APPRO_COMPTE_EMONEY
    ]
};

export const ApproCompteEmoneyCreatePath = {
    ...ApproCompteEmoneyCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ApproCompteEmoneyForm,
    element: <Finance.ApproCompteEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_APPRO_COMPTE_EMONEY
    ]
};

export const ApproCompteEmoneyDetailPath = {
    ...ApproCompteEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailApproCompteEmoneyForm,
    element: <Finance.DetailApproCompteEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_APPRO_COMPTE_EMONEY,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_APPRO_COMPTE_EMONEY,
        CODE_SERVICE.FINANCE_ORGANISATION.METTRE_A_JOUR_APPRO_COMPTE_EMONEY
    ]
};
/**
 * @desc Taux
 */

export const TauxActifsViewLayoutPath = {
    ...TauxActifsViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TauxActifsViewLayout,
    element: <Finance.TauxActifsViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_TAUX_ACTIF_MONNAIE_LOCALE
    ]
};

export const TauxViewLayoutPath = {
    ...TauxViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TauxViewLayout,
    element: <Finance.TauxViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};

export const TauxCreatePath = {
    ...TauxCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Finance.CreationTauxForm,
    element: <Finance.CreationTauxForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};

export const TauxDetailPath = {
    ...TauxDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Finance.DetailTauxValidationForm,
    element: <Finance.DetailTauxValidationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};

/**
 * @desc Balance Organisation
 */

export const BalanceOrganisationViewLayoutPath = {
    ...BalanceOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.BalanceOrganisationViewLayout,
    element: <Finance.BalanceOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_LIST_BALANCE_COMPTE_ORGANISATION,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_LIST_BALANCE_COMPTE_USER
    ]
};

/**
 * @desc Balance Organisation
 */

export const CompteCautionOrganisationViewLayoutPath = {
    ...CompteCautionOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CompteCautionOrganisationViewLayout,
    element: <Finance.CompteCautionOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_CREDITER_CAUTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREDITER_CAUTION
    ]
};

/**
 * @desc Comptes Transfert Fonds
 */

export const CompteTransfertOrganisationViewLayoutPath = {
    ...CompteTransfertOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CompteTransfertOrganisationViewLayout,
    element: <Finance.CompteTransfertOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_CREATION_COMPTE_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_LIST_COMPTE_BANCAIRE
    ]
};

/**
 * @desc Balance Organisation
 */

export const ReportStockSuperDealerViewLayoutPath = {
    ...ReportStockSuperDealerViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ReportStockSuperDealerViewLayout,
    element: <Finance.ReportStockSuperDealerViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_LIST_STOCK_SUPER_DEALER
    ]
};

/**
 * @desc Transfert
 */

export const TransfertViewLayoutPath = {
    ...TransfertViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TransfertViewLayout,
    element: <Finance.TransfertViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.LECTURE_TRANSFERT_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE
    ]
};

export const TransfertCreatePath = {
    ...TransfertCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationTransfertForm,
    element: <Finance.CreationTransfertForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE
    ]
};

export const TransfertDetailPath = {
    ...TransfertDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailTransfertForm,
    element: <Finance.DetailTransfertForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.LECTURE_TRANSFERT_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE
    ]
};

/**
 * @desc Change
 */

export const ChangeViewLayoutPath = {
    ...ChangeViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ChangeViewLayout,
    element: <Finance.ChangeViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_COMPTE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_ESPECE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.VALIDER_CHANGE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CHANGE_ESPECE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.ATTENTE_VALIDATION_CHANGE
    ]
};

export const ChangeCreatePath = {
    ...ChangeCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationChangeForm,
    element: <Finance.CreationChangeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_ESPECE
    ]
};

export const ChangeCompteCreatePath = {
    ...ChangeCompteCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationChangeCompteForm,
    element: <Finance.CreationChangeCompteForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_COMPTE
    ]
};

export const ChangeDetailPath = {
    ...ChangeDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailChangeForm,
    element: <Finance.DetailChangeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_COMPTE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_CHANGE_ESPECE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.VALIDER_CHANGE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CHANGE_ESPECE,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.ATTENTE_VALIDATION_CHANGE
    ]
};

/**
 * @desc Change Guichet
 */

export const ChangeGuichetViewLayoutPath = {
    ...ChangeGuichetViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ChangeGuichetViewLayout,
    element: <Finance.ChangeGuichetViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.CREATION_CHANGE_GUICHET,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CHANGE_GUICHET
    ]
};

export const ChangeGuichetCreatePath = {
    ...ChangeGuichetCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationChangeGuichetForm,
    element: <Finance.CreationChangeGuichetForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.CREATION_CHANGE_GUICHET
    ]
};

export const ChangeGuichetDetailPath = {
    ...ChangeGuichetDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailChangeGuichetForm,
    element: <Finance.DetailChangeGuichetForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.CREATION_CHANGE_GUICHET,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CHANGE_GUICHET
    ]
};

/**
 * @desc Encaissement
 */

export const EncaissementDecaissementViewLayoutPath = {
    ...EncaissementDecaissementViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.EncaissementViewLayout,
    element: <Finance.EncaissementViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT
    ]
};

export const EncaissementDecaissementCreatePath = {
    ...EncaissementDecaissementCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationEncaissementForm,
    element: <Finance.CreationEncaissementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT
    ]
};

export const EncaissementDecaissementDetailPath = {
    ...EncaissementDecaissementDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailEncaissementForm,
    element: <Finance.DetailEncaissementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT
    ]
};

/**
 * @desc Decaissement
 */

export const DecaissementViewLayoutPath = {
    ...DecaissementViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DecaissementViewLayout,
    element: <Finance.DecaissementViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .DECAISSER_DECAISSEMENT
    ]
};

export const DecaissementCreatePath = {
    ...DecaissementCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationDecaissementForm,
    element: <Finance.CreationDecaissementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .DECAISSER_DECAISSEMENT
    ]
};

export const DecaissementDetailPath = {
    ...DecaissementDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailDecaissementForm,
    element: <Finance.DetailDecaissementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DECAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .ENCAISSER_ENCAISSEMENT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .DECAISSER_DECAISSEMENT
    ]
};

/**
 * @desc Devise
 */
// FIXME: Ajout des services
export const DeviseViewLayoutPath = {
    ...DeviseViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DeviseViewLayout,
    element: <Finance.DeviseViewLayout />,
    code: [{ code: 'test' }]
};

export const DeviseCreatePath = {
    ...DeviseCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationDeviseForm,
    element: <Finance.CreationDeviseForm />,
    code: [{ code: 'test' }]
};

export const DeviseDetailPath = {
    ...DeviseDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailDeviseForm,
    element: <Finance.DetailDeviseForm />,
    code: [{ code: 'test' }]
};

/**
 * @desc Virement
 */

export const VirementViewLayoutPath = {
    ...VirementViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.VirementViewLayout,
    element: <Finance.VirementViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.ATTENTE_VALIDATION_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.VALIDER_VIREMENT
    ]
};

export const VirementCreatePath = {
    ...VirementCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationVirementForm,
    element: <Finance.CreationVirementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_VIREMENT
    ]
};

export const VirementDetailPath = {
    ...VirementDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailVirementForm,
    element: <Finance.DetailVirementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.INITIER_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.ATTENTE_VALIDATION_VIREMENT,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.VALIDER_VIREMENT
    ]
};

/**
 * @desc Virement KinDB
 */

export const VirementKinDBViewLayoutPath = {
    ...VirementKinDBViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.VirementViewKinDBLayout,
    element: <Finance.VirementViewKinDBLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_DECAISSEMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
    ]
};

export const VirementKinDBCreatePath = {
    ...VirementKinDBCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationVirementKinDBForm,
    element: <Finance.CreationVirementKinDBForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB
    ]
};

export const VirementKinDBDetailPath = {
    ...VirementKinDBDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailVirementKinDBForm,
    element: <Finance.DetailVirementKinDBForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_VIREMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_DECAISSEMENT_KINDB,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_VIREMENT_KINDB
    ]
};

/**
 * @desc Depot
 */

export const OperationBancaireViewLayoutPath = {
    ...OperationBancaireViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.OperationBancaireViewLayout,
    element: <Finance.OperationBancaireViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE
    ]
};

export const OperationBancaireCreatePath = {
    ...OperationBancaireCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationOperationBancaireForm,
    element: <Finance.CreationOperationBancaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE
    ]
};

export const OperationBancaireDetailPath = {
    ...OperationBancaireDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailOperationBancaireForm,
    element: <Finance.DetailOperationBancaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.INITIER_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.DECAISSEMENT_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.ATTENTE_VALIDATION_OPERATION_BANCAIRE,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_OPERATION_BANCAIRE
    ]
};

// versement

export const ApproCashListPath = {
    ...ApproCashListRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.ApproCashViewLayout,
    element: <Finance.ApproCashViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH_POUR_AUTRE
    ]
};
export const ApproCashCreationPath = {
    ...ApproCashCreationRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationApproCashForm,
    element: <Finance.CreationApproCashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH_POUR_AUTRE
    ]
};
export const ApproCashDetailPath = {
    ...ApproCashDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailApproCashForm,
    element: <Finance.DetailApproCashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_APPRO_CASH,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_APPRO_CASH_POUR_AUTRE
    ]
};

/**
 * @desc Raison
 */

export const RaisonViewLayoutPath = {
    ...RaisonViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.RaisonViewLayout,
    element: <Finance.RaisonViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_RAISON,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .CREER_RAISON
    ]
};

export const RaisonCreatePath = {
    ...RaisonCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationRaisonForm,
    element: <Finance.CreationRaisonForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .CREER_RAISON
    ]
};

export const RaisonDetailPath = {
    ...RaisonDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailRaisonForm,
    element: <Finance.DetailRaisonForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_RAISON,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .CREER_RAISON
    ]
};

/**
 * @desc Depenses
 */

export const DepensesViewLayoutPath = {
    ...DepensesViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DepensesViewLayout,
    element: <Finance.DepensesViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DEPENSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DEPENSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DEPENSE
    ]
};

export const DepensesCreatePath = {
    ...DepensesCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationDepensesForm,
    element: <Finance.CreationDepensesForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DEPENSE
    ]
};

export const DepensesDetailPath = {
    ...DepensesDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailDepensesForm,
    element: <Finance.DetailDepensesForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_DEPENSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_DEPENSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .VALIDER_DEPENSE
    ]
};

/**
 * @desc Cashbook
 */

export const CashbookViewLayoutPath = {
    ...CashbookViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CashbookViewLayout,
    element: <Finance.CashbookViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

// export const CashbookCreatePath = {
//     ...CashbookCreateRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Finance.CreationCashbookForm,
//     element: <Finance.CreationCashbookForm />,
//     code: [
//         { code: 'test' },
//     ]
// };

// export const CashbookDetailPath = {
//     ...CashbookDetailRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Finance.DetailCashbookForm,
//     element: <Finance.DetailCashbookForm />,
//     code: [
//         { code: 'test' },
//     ]
// };

/**
 * @desc Transfert inter institutionnel
 */

export const TransfertInterInstitutionnelViewLayoutPath = {
    ...TransfertInterInstitutionnelViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TransfertInterInstitutionnelViewLayout,
    element: <Finance.TransfertInterInstitutionnelViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .INITIER_TRANSFERT_CAISSE_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .LECTURE_TRANSFERT_CAISSE_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .ATTENTE_ENCAISSEMENT_TRANSFERT_CAISSE_ORGANISATION_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE_KINDB
    ]
};

export const TransfertInterInstitutionnelCreatePath = {
    ...TransfertInterInstitutionnelCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationTransfertInterInstitutionnelForm,
    element: <Finance.CreationTransfertInterInstitutionnelForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.INITIER_TRANSFERT_CAISSE_KINDB
    ]
};

export const TransfertInterInstitutionnelDetailPath = {
    ...TransfertInterInstitutionnelDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailTransfertInterInstitutionnelForm,
    element: <Finance.DetailTransfertInterInstitutionnelForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .INITIER_TRANSFERT_CAISSE_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .LECTURE_TRANSFERT_CAISSE_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .ATTENTE_VALIDATION_TRANSFERT_CAISSE_ORGANISATION_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .ATTENTE_ENCAISSEMENT_TRANSFERT_CAISSE_ORGANISATION_KINDB,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_TRANSFERT_CAISSE_KINDB
    ]
};

/**
 * @desc Crediter balance caution
 */

export const CrediterBalanceCautionViewLayoutPath = {
    ...CrediterBalanceCautionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CrediterBalanceCautionViewLayout,
    element: <Finance.CrediterBalanceCautionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_TRANSFERT_BALANCE,
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE
    ]
};

export const CrediterBalanceCautionCreatePath = {
    ...CrediterBalanceCautionCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationCrediterBalanceCautionForm,
    element: <Finance.CreationCrediterBalanceCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE
    ]
};

export const CrediterBalanceCautionDetailPath = {
    ...CrediterBalanceCautionDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailCrediterBalanceCautionForm,
    element: <Finance.DetailCrediterBalanceCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_TRANSFERT_BALANCE,
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_TRANSFERT_BALANCE,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_TRANSFERT_BALANCE
    ]
};

/**
 * @desc Taux
 */

export const TauxKindistributionViewLayoutPath = {
    ...TauxKindistributionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.TauxOrganisationKindistributionViewLayout,
    element: <Finance.TauxOrganisationKindistributionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};

export const TauxKindistributionCreatePath = {
    ...TauxKindistributionCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationTauxOrganisationKindistributionForm,
    element: <Finance.CreationTauxOrganisationKindistributionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};

export const TauxKindistributionDetailPath = {
    ...TauxKindistributionDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailTauxOrganisationKindistributionForm,
    element: <Finance.DetailTauxOrganisationKindistributionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_TAUX_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .DEFINIR_TAUX_MONNAIE_LOCALE
    ]
};
