import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { CgDetailsMore } from 'react-icons/cg';
import { useLocation, useParams } from 'react-router-dom';
import { BackComponent, ShowNewComponent } from '../../../../components';
import { ElaborationAmendementTableSynthese } from './table-elaboration-synthese-view';

export function VoirPlus() {
    const { id } = useParams();
    const { search, pathname } = useLocation();
    const { /** firebaseUser, */ entity } = useSelector((reduxState) => ({
        // firebaseUser: reduxState.firebaseUser,
        entity: reduxState.elaborations.find((element) => element?.id === id)
    }));
    const searchParams = new URLSearchParams(search.replace('?', ''));
    const getDestination = useCallback(() => {
        if (!entity?.estVariable && entity?.typeProprietaire === 'Charge')
            return 'fixe';
        if (entity?.estVariable && entity?.typeProprietaire === 'Charge')
            return 'variable';
        if (entity?.typeProprietaire === 'Ressource') return 'ressource';
        return '';
    }, [entity?.estVariable, entity?.typeProprietaire]);
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <BackComponent className="btn btn-primary" />
                {/* <span className="h2">Details</span> */}
                <ShowNewComponent
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
                            .INITIER_ELABORATION_AMENDEMENT
                    ]}
                    destination={`${pathname}/${getDestination()}?start=${Number(
                        searchParams.get('start')
                    )}&end=${Number(searchParams.get('end'))}`}
                    text="Amender"
                    className="btn btn-outline-dark"
                    icon={<CgDetailsMore />}
                    title="Amender..."
                />
            </div>
            <hr />
            <ElaborationAmendementTableSynthese
                id={id}
                destination={getDestination()}
                payloadUrl={{
                    start: Number(searchParams.get('start')),
                    end: Number(searchParams.get('end'))
                }}
                entity={entity}
            />
        </div>
    );
}
