/* eslint-disable react/no-array-index-key */
import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import { IoMdAdd } from 'react-icons/io';
import {
    ConditionalRenderingWrapper,
    CustomButton,
    CustomLiteDynamicDateInput,
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
    // StateShowerComponent
} from '../../../../components';

export function RightPannel({
    formFields,
    handleSelectChange,
    tasks = [],
    handleChange,
    handleRemove,
    handleAddHighLevel,
    users = [],
    setFormFields
}) {
    return (
        <>
            {formFields.map((field, index) => (
                <ConditionalRenderingWrapper
                    isShouldBeRendered={!!field.isTooggled}
                    key={index}
                >
                    <div className="row d-flex align-items-center">
                        <CustomLiteDynamicDropdown
                            containerClassName="col-12"
                            ariaLabel="Tâches"
                            name="codeTache|designationTache"
                            value={field?.codeTache}
                            options={tasks || []}
                            hiddenOption="Selectionnez Tâche..."
                            onChange={handleSelectChange}
                            handleProps={{
                                index,
                                setFormFields,
                                formFields
                            }}
                            labelStyle={{
                                fontSize: '13px',
                                paddingBottom: '8px',
                                lineHeight: '20px',
                                minHeight: '20px',
                                display: 'block',
                                color: 'rgba(0,0,0,0.5)'
                            }}
                            label="Tâches"
                            selectClassName="form-select form-select-sm col-3"
                        />
                    </div>
                    {formFields[index].details.map((detail, detailIndex) => (
                        <div key={detailIndex} className="">
                            <div className="d-flex flex-row align-items-center">
                                <div
                                    style={{
                                        height: '46px',
                                        width: '16px'
                                    }}
                                >
                                    <p />
                                </div>
                                <CustomLiteDynamicDateInput
                                    containerClassName="col-9 ms-5"
                                    labelStyle={{
                                        fontSize: '13px',
                                        paddingBottom: '8px',
                                        lineHeight: '20px',
                                        minHeight: '20px',
                                        display: 'block',
                                        color: 'rgba(0,0,0,0.5)'
                                    }}
                                    label="Date Debut"
                                    inputClassName="form-control form-control-sm col-3"
                                    ariaLabel="Date Debut"
                                    name="dateString"
                                    customproperty="dateDebut"
                                    value={detail.dateString}
                                    onChange={handleChange}
                                    inputProps={{
                                        index,
                                        indexAtSecondLevel: detailIndex,
                                        setFormFields,
                                        formFields
                                    }}
                                />
                                <div className="col-auto pt-2 me-3">
                                    <span
                                        role="button"
                                        title="Supprimer Details"
                                        className="text-secondary"
                                        tabIndex={0}
                                        onClick={() =>
                                            handleRemove({
                                                index,
                                                indexAtSecondLevel: detailIndex,
                                                formFields,
                                                setFormFields
                                            })
                                        }
                                        onKeyDown={() =>
                                            handleRemove({
                                                index,
                                                indexAtSecondLevel: detailIndex,
                                                formFields,
                                                setFormFields
                                            })
                                        }
                                    >
                                        <AiFillMinusCircle className="fs-4" />
                                    </span>
                                </div>
                                <div className="col-auto pt-2">
                                    <span
                                        role="button"
                                        title="Ajouter Details"
                                        className="text-primary"
                                        tabIndex={0}
                                        onClick={() =>
                                            handleAddHighLevel({
                                                type: 'Detail',
                                                ancestorId: index,
                                                indexAtSecondLevel: detailIndex,
                                                formFields,
                                                setFormFields
                                            })
                                        }
                                        onKeyDown={() =>
                                            handleAddHighLevel({
                                                type: 'Detail',
                                                ancestorId: index,
                                                indexAtSecondLevel: detailIndex,
                                                formFields,
                                                setFormFields
                                            })
                                        }
                                    >
                                        <IoMdAdd className="fs-4" />
                                    </span>
                                </div>
                            </div>
                            <div className="ms-5">
                                {formFields[index].details[
                                    detailIndex
                                ].agents.map((agentItem, agentIndex) => (
                                    <div key={agentIndex} className=" ms-5">
                                        <div className="d-flex flex-row align-items-center">
                                            <div
                                                style={{
                                                    height: '46px',
                                                    width: '16px'
                                                }}
                                            >
                                                <p />
                                            </div>
                                            <CustomLiteDynamicDropdown
                                                containerClassName="col-4 me-3"
                                                ariaLabel="Agent"
                                                idProperty="reference"
                                                name="reference|designation"
                                                value={agentItem?.reference}
                                                options={users || []}
                                                hiddenOption="Selectionnez un agent"
                                                onChange={handleSelectChange}
                                                handleProps={{
                                                    index,
                                                    indexAtSecondLevel:
                                                        detailIndex,
                                                    indexAtThirdLevel:
                                                        agentIndex,
                                                    setFormFields,
                                                    formFields
                                                }}
                                                labelStyle={{
                                                    fontSize: '13px',
                                                    paddingBottom: '8px',
                                                    lineHeight: '20px',
                                                    minHeight: '20px',
                                                    display: 'block',
                                                    color: 'rgba(0,0,0,0.5)'
                                                }}
                                                label="Agent"
                                                selectClassName="form-select form-select-sm col-3"
                                            />
                                            <CustomLiteDynamicInput
                                                placeholder="Saississez un nombre"
                                                containerClassName="col-4 me-3"
                                                labelStyle={{
                                                    fontSize: '13px',
                                                    paddingBottom: '8px',
                                                    lineHeight: '20px',
                                                    minHeight: '20px',
                                                    display: 'block',
                                                    color: 'rgba(0,0,0,0.5)'
                                                }}
                                                label="Target"
                                                inputClassName="form-control form-control-sm col-2"
                                                ariaLabel="Target"
                                                name="target"
                                                value={agentItem?.target}
                                                onChange={handleChange}
                                                inputProps={{
                                                    index,
                                                    indexAtSecondLevel:
                                                        detailIndex,
                                                    indexAtThirdLevel:
                                                        agentIndex,
                                                    setFormFields,
                                                    formFields
                                                }}
                                            />
                                            <div className="col-auto pt-2 me-3">
                                                <span
                                                    role="button"
                                                    title="Supprimer Informations de la Période"
                                                    className="text-secondary"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        handleRemove({
                                                            index,
                                                            indexAtSecondLevel:
                                                                detailIndex,
                                                            indexAtThirdLevel:
                                                                agentIndex,
                                                            formFields,
                                                            setFormFields
                                                        })
                                                    }
                                                    onKeyDown={() =>
                                                        handleRemove({
                                                            index,
                                                            indexAtSecondLevel:
                                                                detailIndex,
                                                            indexAtThirdLevel:
                                                                agentIndex,
                                                            formFields,
                                                            setFormFields
                                                        })
                                                    }
                                                >
                                                    <AiFillMinusCircle className="fs-4" />
                                                </span>
                                            </div>
                                            <div className="col-auto pt-2">
                                                <span
                                                    role="button"
                                                    title="Ajouter Période"
                                                    className="text-primary"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        handleAddHighLevel({
                                                            type: 'Agent',
                                                            ancestorId: index,
                                                            indexAtSecondLevel:
                                                                detailIndex,
                                                            formFields,
                                                            setFormFields
                                                        })
                                                    }
                                                    onKeyDown={() =>
                                                        handleAddHighLevel({
                                                            type: 'Agent',
                                                            ancestorId: index,
                                                            indexAtSecondLevel:
                                                                detailIndex,
                                                            formFields,
                                                            setFormFields
                                                        })
                                                    }
                                                >
                                                    <IoMdAdd className="fs-4" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </ConditionalRenderingWrapper>
            ))}
            <div className="d-flex justify-content-center mt-3">
                <CustomButton
                    type="button"
                    text="+ Ajouter"
                    className="btn-success btn"
                    onClick={() =>
                        handleAddHighLevel({
                            type: 'Objectif',
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="mt-5" />
            {/* <div>
                <StateShowerComponent state={formFields} />
            </div> */}
        </>
    );
}
