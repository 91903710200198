import * as yup from 'yup';

/**
 * @description : Schema de Virement
 */
export const VirementFormSchema = yup.object().shape({
    selectedSuperDealer: yup.object().shape({
        code: yup.string(),
        designation: yup.string()
    }),
    selectedInstitutionFinanciere: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedCompte: yup.object().shape({
        id: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedTypeOperation: yup.object().shape({
        code: yup.string().required()
    }),
    selectedPartenaire: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    taux: yup.number(),
    montant: yup.number().min(1),
    estCommission: yup.boolean(),
    commission: yup.number()
});
