import { useCallback } from 'react';
import { ETAT, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';

export function RapportRegularisationStockForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedSelector: { code: 'USD', designation: 'USD' },
            deviseFilter: [],
            listHeaders: []
        }
    });
    const groupDataByKey = useCallback(
        (array) =>
            (array || []).map((item) => ({
                ...item,
                imputationDesignation: item.imputation
                    ? item?.imputation?.designation
                    : item?.imputationCompte?.designation
            })),
        []
    );
    // const { fields } = formState;
    const listFormater = useCallback(
        (donnees) => {
            const data = donnees?.[0]?.length
                ? (donnees?.[0] || [])?.filter(
                      (item) => item.etat === ETAT.ETAT_VALIDER
                  )
                : (donnees || [])?.filter(
                      (item) => item.etat === ETAT.ETAT_VALIDER
                  ) || [];
            const resulat = groupDataByKey(data);
            return resulat;
        },
        [groupDataByKey]
    );

    const cutomTableHeader = () => (
        <>
            <tr className="border text-center text-justify">
                <th rowSpan={2} className="border">
                    #
                </th>
                <th rowSpan={2} className="border">
                    Organisation
                </th>
                <th rowSpan={2} className="border">
                    Imputation
                </th>
                <th rowSpan={2} className="border">
                    Emplacement
                </th>
                <th colSpan={2} className="border">
                    Montant
                </th>
                <th rowSpan={2} className="border">
                    Date Création
                </th>
            </tr>
            <tr>
                <th>Unité</th>
                <th>USD</th>
            </tr>
        </>
    );

    const getEmplacement = (item) => {
        let typeBusiness = '';
        if (item?.typeBusiness?.length) {
            typeBusiness = item?.typeBusiness?.join(' , ');
            typeBusiness = typeBusiness.replace('Businessmodel', 'BM');
            typeBusiness = typeBusiness.replace('BusinessModel', 'BM');
            typeBusiness = typeBusiness.replace('Traditionnelbusiness', 'TB');
            typeBusiness = typeBusiness.replaceAll(',', '-');
        }
        if (!item?.typeBusiness?.length && item.numero)
            typeBusiness = item.numero;

        return typeBusiness;
    };

    const mapper = () => [
        {
            displayName: 'Organisation',
            field: 'valeurTypeEntite.designation'
        },
        {
            field: 'imputationDesignation',
            displayName: 'Imputation'
        },
        {
            field: 'numero',
            displayName: 'Numéro',
            tdExtractor: (item) => getEmplacement(item)
        },
        {
            field: 'quantite',
            displayName: 'Quantité'
        },
        {
            field: 'montant',
            displayName: 'Montant',
            isTotal: true
        },
        {
            displayName: 'Creation',
            field: 'dateCreation'
        }
    ];

    // const titleToRender = (code) => {
    //     if (code === 'ALL') return 'Tous les comptes';
    //     return `Comptes ${code}`;
    // };

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                cutomTableHeader={cutomTableHeader}
                // listTitle={titleToRender(fields.selectedSelector.code)}
                dynamicNodeName={`evolutionsRegularisationStock-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_REGULARISATION_STOCK_INITIALS}
                listFormater={listFormater}
                mapper={mapper()}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959())
                }}
                defaultPayloadMapperValues={{}}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                {/* <CustomRow>
                    <CustomDropdown
                        isSort={false}
                        options={[{ code: 'USD', designation: 'USD' }]}
                        label="Valeur : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedSelector"
                        id="selectedSelectortypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow> */}
            </ReduxReportTemplate>
        </>
    );
}
