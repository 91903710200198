import { useCallback } from 'react';
import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    ETAT,
    FORM_CONST,
    TIME,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import {
    CustomDropdown,
    CustomRow,
    MouvementCardsComponent,
    ReportCommandeFlashSelector
} from '../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedSuperDealer: {},
    selectedTypeBusiness: {},
    selectedEtat: {},
    selectedProperty: {},
    selectedNature: { code: 'ALL', designation: 'Tous' }
};

export function RapportCommandeFlashForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    const fetchReport = () => {
        const {
            dateFin,
            dateDebut,
            selectedSuperDealer,
            selectedProperty,
            selectedEtat
        } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = { property: 'dateAttenteValidation' };
        if (selectedEtat.code) {
            payloadToAdd.etat = selectedEtat.code;
        }
        if (
            selectedEtat.code === ETAT.ETAT_ANNULER ||
            selectedEtat.code === ETAT.ETAT_VALIDER
        ) {
            payloadToAdd.property = selectedProperty.code;
        }
        if (selectedSuperDealer.code) {
            payloadToAdd.codeSuperDealer = selectedSuperDealer.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            ...payloadToAdd,
            property: 'dateCreation',
            estNonSendeur: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_COMMANDE_FLASH_BY_ETAT,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    const formaterList = useCallback(() => {
        const { code, designation } = fields.selectedNature;
        if (!elements.mouvements?.value || !elements.mouvements?.value?.length)
            return [];
        if (elements.mouvements?.value?.length) {
            const dataToReturn = elements.mouvements?.value?.map((item) => ({
                ...item,
                typeNature:
                    TIME.utc000000(item?.dateCreation) ===
                    TIME.utc000000(TODAY.at000000())
                        ? 'Actuel'
                        : 'Précédent',
                business: displayTypeBussiness(item.typeBusiness)
            }));
            if (code !== 'ALL') {
                return dataToReturn.filter(
                    (item) => item.typeNature === designation
                );
            }
            return dataToReturn;
        }
        return [];
    }, [elements.mouvements?.value, fields.selectedNature])();

    const mapper = [
        {
            displayName: 'Marchand',
            field: 'marchand.designation'
        },
        {
            field: 'produit.designation',
            displayName: 'produit'
        },
        {
            displayName: 'Revendeur',
            field: 'valeurTypeEntite.designation'
        },
        {
            displayName: 'User',
            dataExtractor: (item) =>
                `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`
        },
        {
            field: 'client.designation',
            displayName: 'Client'
            // dataExtractor: (item) => {
            //     if (item?.client?.designation) return item?.client?.designation;
            //     return item?.valeurTypeEntite?.designation;
            // }
        },
        {
            field: 'numero',
            displayName: 'Numéro'
        },
        {
            field: 'quantite',
            displayName: 'quantite',
            isNumber: true
        },
        {
            field: 'etat',
            displayName: 'Etat'
        },
        {
            field: 'typeNature',
            displayName: 'Nature'
        },
        {
            field: 'numeroReference',
            displayName: 'Référence'
        },
        {
            field: 'business',
            displayName: 'Type Business'
        },
        {
            field: 'motif',
            displayName: 'Motif'
        },
        {
            field: 'dateCreation',
            displayName: 'Création'
        },
        {
            field: 'dateDerniereModification',
            displayName: 'Dernière modification'
        }
    ];

    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={mapper}
                dataList={formaterList || []}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
                isCommandeLitigieuse
            >
                <ReportCommandeFlashSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
                <CustomRow>
                    <CustomDropdown
                        options={[
                            {
                                code: 'PAST',
                                designation: 'Précédent'
                            },
                            {
                                code: 'ACTUAL',
                                designation: 'Actuel'
                            },
                            { code: 'ALL', designation: 'Tous' }
                        ]}
                        label="Nature : "
                        defaultOption="Nature "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedNature"
                        id="selectedNatureId"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
                <CustomRow
                    isShouldBeRendered={
                        fields.selectedEtat.code === ETAT.ETAT_LIVRER ||
                        fields.selectedEtat.code === ETAT.ETAT_VALIDER ||
                        fields.selectedEtat.code === ETAT.ETAT_ANNULER
                    }
                >
                    <CustomDropdown
                        options={[
                            {
                                code: 'dateCreation',
                                designation: 'Date Création'
                            },
                            {
                                code: 'dateAttenteValidation',
                                designation: 'Date Partenaire'
                            }
                        ]}
                        label="Property : "
                        defaultOption="Date ordonnancement"
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedProperty"
                        id="selectedPropertytypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
