import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useUrlParse } from '../util/hook';

export function CheckLogout({ children }) {
    const { location, urlParamsObject } = useUrlParse();
    const path = urlParamsObject.path ? urlParamsObject.path : '/';
    const firebaseUser = useSelector((reduxState) => reduxState.firebaseUser);
    if (firebaseUser && firebaseUser.uid) {
        return <Navigate to={path} state={{ from: location }} replace />;
    }
    return children;
}
