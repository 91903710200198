import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    TYPE_ORGANISATION,
    checkPlageDate,
    formInitialState
    // fromTimestampToString
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../util';
import { MainReportComponentLarge } from '../../report/releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false
};

const setDefaultProperties = ({ type }) => {
    const defaultMapper = [
        {
            displayName: 'Compte | Caisse',
            field: 'designationMoyenPaiement'
        },
        {
            displayName: 'Marchand',
            field: 'marchand.designation'
            // dataExtractor: (item) =>
            //     item?.superDealer?.designation
            //         ? item?.superDealer?.designation
            //         : 'NAPP'
        },
        {
            displayName: 'Stock',
            field: 'numero'
        },
        {
            displayName: 'Paiement',
            field: 'typeEntite'
        },
        {
            displayName: 'Facturation',
            dataExtractor: (item) => (item?.estSim ? 'SIM' : 'FLASH')
        },
        {
            displayName: 'Devise',
            field: 'valeurTypeEntite.devise'
        },
        {
            displayName: 'Quantité',
            field: 'quantite'
        },
        {
            displayName: 'Montant',
            field: 'montant',
            isKeepFormat: true
        },
        {
            displayName: 'Etat',
            field: 'etat'
        },
        {
            displayName: 'Creation',
            field: 'dateCreation'
        },
        {
            displayName: 'Modification',
            field: 'dateDerniereModification'
        }
    ];
    // DefaultListerners

    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.REVENDEUR)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: [
                {
                    displayName: 'Compte',
                    field: 'valeurTypeEntite.numeroCompteExterne'
                },
                {
                    displayName: 'Stock',
                    field: 'numero'
                },
                {
                    displayName: 'Type Facturation',
                    field: 'typeEntite'
                },
                {
                    displayName: 'Devise',
                    field: 'valeurTypeEntite.devise'
                },
                {
                    displayName: 'Quantité',
                    field: 'quantite'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Creation',
                    field: 'dateCreation'
                }
            ]
        };
    return { mapper: [] };
};

export function HistoriqueFacturationStock() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { mapper } = setDefaultProperties({ type: typeEntite });
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin
            // estOrganisation: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_APPRO_STOCK_SUPER_DEALER,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    const formatDesignation = (item) =>
        item?.typeEntite === 'Caisse'
            ? `${item?.valeurTypeEntite?.designation || ''} ${
                  item?.valeurTypeEntite?.devise || ''
              }`
            : `${item?.valeurTypeEntite?.numeroCompteExterne || ''} ${
                  item?.valeurTypeEntite?.devise || ''
              }`;
    return (
        <MainReportComponentLarge
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={
                elements.mouvements?.value?.map((item) => ({
                    ...item,
                    designationMoyenPaiement: formatDesignation(item)
                })) || []
            }
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        />
    );
}
