/* eslint-disable react/jsx-props-no-spreading */
import {
    Add,
    CODE_SERVICE,
    Str,
    resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';
import React, { useMemo, useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import { FcCancel } from 'react-icons/fc';
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGlobalFilter,
    useRowSelect
} from 'react-table';
import {
    MdOutlineArrowDropDown,
    MdOutlineArrowDropUp,
    MdRemoveCircleOutline
} from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import {
    fuzzyTextFilter,
    filterGreaterThan,
    DefaultColumnFilter,
    GlobalFilter
} from './table-filter';
import { TableAction } from './table-action';
import {
    DateDisplayer,
    EtatDisplayer,
    NumberDisplayer
} from '../special-components';
import { CustomAlert } from '../alert';
import { formatNumber } from '../../util';
import { CustomDropButton, TableRowCheckbox } from '../button';
import {
    CommandeSenderActionsComponent,
    CommandeLitigieuseActionsComponent,
    CommandeLitigieuseEmoneyActionsComponent
} from '../molecules';
import { ConditionalRenderingWrapper, UserServiceWrapper } from '../container';

// FIXME: Add asynchornous behavior and style
// NB: lorsqu'on utilise la class 'table' il faut veuiller à l'affichage mobile qui fait un overflow

filterGreaterThan.autoRemove = (val) => typeof val !== 'number';
fuzzyTextFilter.autoRemove = (val) => !val;

/**
 *
 * @param {Object} param0 Object qui contient les props du composant:
 * @param {Array} param0.columns Array des colonnes du tableau
 * @param {Array} param0.data Array des données du tableau
 * @param {String} param0.tableClass Classe CSS du tableau
 * @param {Boolean} param0.isActionActive Indique si les actions sont activées
 * @param {Boolean} param0.isSelf Indique si on affiche une action sur la ligne
 * @param {Boolean} param0.isTwice Indique si on affiche 2 actions sur la ligne
 * @param {Boolean} param0.filter Indique si on affiche le filtre
 * @param {Boolean} param0.bottomPagination Indique si on affiche la pagination en bas
 * @param {Function} param0.callback Fonction qui sera appelée lorsqu'on clique sur une action
 * @param {Function} param0.dropCallback Fonction qui sera appelée lorsqu'on clique sur une action de suppression
 * @returns
 */
function CustomTable({
    // columns = [], // Description of columns no matter what scheme is used
    data = [], // Main Date
    tableClass, // className for the main table
    // theadClass, // className for the table header
    // tbodyClass, // className for the table body
    // thClass, // className for the table header th element
    // trClass, // className for the table row element
    // tdClass, // className for the table data element
    // loading, // the property we will use to show loaders
    callback,
    dropCallback,
    isActionActive = false,
    isSelf = false,
    isTwice = false,
    filter = false,
    bottomPagination = true, // property to specify if the filter or pagination parameters will be shown: by default true
    isExportAllowed,
    isRefetchAllowed,
    dataList,
    fileName,
    sheetName,
    format,
    refetchFunctionName,
    functionName,
    nodeName,
    isDynamicNode,
    dynamicNodeName,
    payload,
    formater,
    onError,
    listTitle,
    isShowDropButton = false,
    isUrl = false,
    isSenderFlash = false,
    isSenderEmoney = false,
    isCommandeLitigieuse = false,
    isCommandeLitigieuseEmoney = false,
    trToAddOnTop,
    trToAddOnBottom,
    tfootTrToAdd,
    isDsiplayEmptyTableNotification = false,
    devise,
    nombreApresVirgule,
    isCompact,
    isLongCompactDisplay,
    isPercent,
    useGrouping,
    isForceParse,
    isLinkToDetail,
    identifier = 'id',
    listSelectedItemName,
    formDispatcher,
    downloadFileFunction,
    mapper,
    isSweet,
    isRowSelector = true,
    formState,
    reduxDispatcher,
    idToken,
    viewSender,
    cutomTableHeader,
    isAddTotalTr = false,
    transformExportDataFunction,
    customTotal,
    notFormatedData,
    dynamicMapper,
    dynamicBottom,
    tablesRender,
    filterComplement,
    renderSpecificly
}) {
    const tHeadClassToSend = isSweet ? ' ' : 'fw-normal';
    const thClassToSend = isSweet ? ' ' : 'border-start';
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(false);
    const gotoDetails = (id) => {
        if (!pathname || !isLinkToDetail) return;
        navigate(`${pathname}/${id}`, { replace: true });
    };
    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilter filter type.
            fuzzyText: fuzzyTextFilter,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) =>
                rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                })
        }),
        []
    );

    const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

    // Destructuring useTable (react table hook)
    const mainMapper = useMemo(
        () =>
            dynamicMapper && typeof dynamicMapper === 'function'
                ? dynamicMapper(notFormatedData)
                : mapper,
        [dynamicMapper, mapper, notFormatedData]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns: mainMapper,
            data,
            initialState: { pageIndex: 0 },
            defaultColumn,
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (isRowSelector) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <TableRowCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <TableRowCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        )
                    },
                    ...columns
                ]);
            }
        }
    );

    // this function will set the icon to show on filter

    const filterIconToRender = (column) => {
        if (column.isSortedDesc) return <MdOutlineArrowDropDown />;
        if (column.isSorted) return <MdOutlineArrowDropUp />;
        return '';
    };
    // UseEffect to control side effects
    useEffect(() => {
        if (canNextPage) {
            setIsFetching(false); // FIXME: This function has to be changed
            setTimeout(() => {
                setIsFetching(false);
            }, 1000);
        }
        return () => {};
    }, [pageSize, pageIndex, canNextPage]);

    useEffect(() => {
        if (!bottomPagination) {
            setPageSize(data.length); // FIXME: This function has to be changed
        }
        return () => {};
    }, [bottomPagination, setPageSize, data.length]);

    const dataToDisplay = (cellData, cell, line) => {
        let valueToDisplay = cellData;
        if (cell?.column?.id === 'selection') {
            return (
                <div>
                    <TableRowCheckbox {...line.getToggleRowSelectedProps()} />
                </div>
            );
        }
        if (
            cell.column.dataExtractor &&
            typeof cell.column.dataExtractor === 'function'
        ) {
            valueToDisplay = cell.column.dataExtractor(line.original);
        } else {
            valueToDisplay = resolvePathPropertyOfObject(
                cell.column.field,
                line.original
            );
        }
        if (
            cell.column.tdExtractor &&
            typeof cell.column.tdExtractor === 'function'
        ) {
            return (
                <td
                    className={`${thClassToSend} ${
                        isSweet ? cell.column.fieldClassname : ''
                    }`}
                >
                    {cell.column.tdExtractor(line.original)}
                </td>
            );
        }
        if (cell.column.isDate || cell.column.id.includes('date'))
            return cell.value ? <DateDisplayer date={cell.value} /> : '-';
        if (
            (cell.column.isEtat || cell.column.id.includes('etat')) &&
            !cell.column.isNotEtat
        )
            return <EtatDisplayer etat={cell.value} />;

        if (cell.column.isProgress)
            return (
                <EtatDisplayer
                    value={cell.value}
                    progressType={cell.column.progressType}
                />
            );
        if (typeof cell.value === 'boolean' || cell.column.isBoolean) {
            if (cell.value) return <BsCheckCircle className="text-success" />;
            if (!cell.value)
                return <MdRemoveCircleOutline className="text-danger" />;
        }
        if (typeof cell.value === 'number') {
            return (
                <>
                    <NumberDisplayer
                        value={cell.value}
                        devise={devise}
                        nombreApresVirgule={nombreApresVirgule}
                        isCompact={isCompact}
                        isLongCompactDisplay={isLongCompactDisplay}
                        isPercent={isPercent}
                        useGrouping={useGrouping}
                        isForceParse={isForceParse}
                    />
                    {cell.column.concat ? ` ${cell.column.concat}` : ''}
                </>
            );
        }
        if (cell.column.isKeepFormat) {
            return valueToDisplay;
        }
        return valueToDisplay
            ? `${Str.ucFirst(valueToDisplay)} ${
                  cell.column.concat ? cell.column.concat : ''
              }`
            : '---';
    };

    const totaux = {};
    mainMapper
        .map((item, index) => ({
            ...item,
            position: index + 1
        }))
        .sort((a, b) => a.position - b.position)
        .filter((item) => item.isNumber)
        .forEach((item) => {
            if (page?.length) {
                totaux[item.displayName] = page.reduce(
                    (a, b) =>
                        Add(
                            a,
                            resolvePathPropertyOfObject(
                                `original.${item.field}`,
                                b
                            )
                        ),
                    0
                );
            }
            // if (data?.length && !dataList?.length) {
            //     totaux[item.displayName.split(' ').join('')] = data.reduce(
            //         (a, b) =>
            //             Add(a, resolvePathPropertyOfObject(item.field, b)),
            //         0
            //     );
            // }
            // if (!data?.length && dataList?.length) {
            //     totaux[item.displayName.split(' ').join('')] = dataList.reduce(
            //         (a, b) =>
            //             Add(a, resolvePathPropertyOfObject(item.field, b)),
            //         0
            //     );
            // }
        });
    const determinateTheColorOfOutPut = ({ red, blue, concurentAccess }) => {
        if (concurentAccess) {
            return { color: 'white', backgroundColor: '#17594A' };
        }
        if (red) {
            return { color: 'red' };
        }
        if (blue) {
            return { color: 'white', backgroundColor: 'red' };
        }
        return {};
    };
    const renderContent = () => {
        if (isDsiplayEmptyTableNotification && !data?.length) {
            return (
                <CustomAlert infoMessage="Aucune donnée à afficher pour l'instant" />
            );
        }
        if (!data?.length) return null;
        return (
            // <div
            //     className={
            //         isSweet
            //             ? ''
            //             : 'py-2 px-1 shadow-sm w-auto fw-light table-responsive'
            //     }
            // >
            <>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={formState?.form?.error}
                >
                    <CustomAlert error={formState?.form?.error} />
                </ConditionalRenderingWrapper>
                {filter ? (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isExportAllowed={isExportAllowed}
                        isRefetchAllowed={isRefetchAllowed}
                        dataList={data || dataList}
                        exportMapper={mainMapper}
                        mapper={mainMapper}
                        fileName={fileName}
                        sheetName={sheetName}
                        format={format}
                        refetchFunctionName={refetchFunctionName}
                        functionName={functionName}
                        nodeName={nodeName}
                        isDynamicNode={isDynamicNode}
                        dynamicNodeName={dynamicNodeName}
                        payload={payload}
                        formater={formater}
                        onError={onError}
                        listTitle={listTitle}
                        isRowSelector={isRowSelector}
                        formState={formState}
                        selectedFlatRows={selectedFlatRows}
                        reduxDispatcher={reduxDispatcher}
                        idToken={idToken}
                        formDispatcher={formDispatcher}
                        isSenderFlash={isSenderFlash}
                        isSenderEmoney={isSenderEmoney}
                        isCommandeLitigieuse={isCommandeLitigieuse}
                        isCommandeLitigieuseEmoney={isCommandeLitigieuseEmoney}
                        viewSender={viewSender}
                        transformExportDataFunction={
                            transformExportDataFunction
                        }
                        filterComplement={filterComplement}
                    />
                ) : null}

                {renderSpecificly && typeof renderSpecificly === 'function' ? (
                    renderSpecificly(
                        (page || []).map((item) => ({ ...item.original }))
                    )
                ) : (
                    <table {...getTableProps()} className={tableClass}>
                        <thead className="lead">
                            <tr>
                                <td colSpan={mainMapper.length + 1}>
                                    <div className="row">
                                        {Object.keys(totaux).map(
                                            (totalItem) => (
                                                <dl
                                                    className="col-6 col-sm-3 text-center"
                                                    key={totalItem}
                                                >
                                                    <dt>
                                                        {Str.titleCase(
                                                            `${totalItem}`
                                                        )}
                                                    </dt>
                                                    <dd className="rounded">
                                                        {formatNumber({
                                                            value: totaux[
                                                                totalItem
                                                            ]
                                                        })}
                                                    </dd>
                                                </dl>
                                            )
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <thead className={tHeadClassToSend}>
                            {cutomTableHeader &&
                            typeof cutomTableHeader === 'function'
                                ? cutomTableHeader(notFormatedData)
                                : headerGroups.map((headerGroup) => (
                                      <tr
                                          {...headerGroup.getHeaderGroupProps()}
                                      >
                                          <th>#</th>
                                          {headerGroup.headers.map((column) => (
                                              <th
                                                  {...column.getHeaderProps(
                                                      column.getSortByToggleProps()
                                                  )}
                                                  className={thClassToSend}
                                              >
                                                  {column?.headers?.[0]
                                                      ?.isKeepFormat ||
                                                  !column?.headers
                                                      ? column.render('Header')
                                                      : Str.titleCase(
                                                            column.render(
                                                                'Header'
                                                            )
                                                        )}
                                                  {/* {column.render('Header')
                                                  ? column.render('Header')
                                                  : Str.titleCase(
                                                        column.render('Header')
                                                    )} */}
                                                  {/* Sort direction indicator */}
                                                  <span>
                                                      {filterIconToRender(
                                                          column
                                                      )}
                                                  </span>
                                              </th>
                                          ))}
                                          {isActionActive ? (
                                              <th className={thClassToSend}>
                                                  Actions
                                              </th>
                                          ) : null}
                                          {isShowDropButton ? (
                                              <th className={thClassToSend}>
                                                  Actions
                                              </th>
                                          ) : null}
                                          {isUrl ? (
                                              <th className={thClassToSend}>
                                                  Fichier
                                              </th>
                                          ) : null}
                                          {isSenderFlash || isSenderEmoney ? (
                                              <th className={thClassToSend}>
                                                  Sender Actions
                                              </th>
                                          ) : null}
                                          <UserServiceWrapper
                                              services={[
                                                  { code: 'test' },
                                                  CODE_SERVICE
                                                      .CODE_SERVICE_SENDER
                                                      .MISE_A_JOUR_COMMANDE_LITIGIEUX
                                              ]}
                                          >
                                              {isCommandeLitigieuse ? (
                                                  <th className={thClassToSend}>
                                                      Actions
                                                  </th>
                                              ) : null}
                                              {isCommandeLitigieuseEmoney ? (
                                                  <th className={thClassToSend}>
                                                      Actions
                                                  </th>
                                              ) : null}
                                          </UserServiceWrapper>
                                      </tr>
                                  ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {trToAddOnTop &&
                                typeof trToAddOnTop === 'function' &&
                                trToAddOnTop(page)}
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        onClick={() =>
                                            isActionActive
                                                ? {}
                                                : gotoDetails(
                                                      row.original[identifier]
                                                  )
                                        }
                                        {...row.getRowProps()}
                                        style={determinateTheColorOfOutPut({
                                            red: row.original.isRed,
                                            concurentAccess:
                                                row.original.isConcurentAccess
                                        })}
                                    >
                                        <td
                                            style={determinateTheColorOfOutPut({
                                                blue: row.original.isBlue,
                                                concurentAccess:
                                                    row.original
                                                        .isConcurentAccess
                                            })}
                                        >
                                            {i + 1}
                                        </td>
                                        {row.cells.map((cell) =>
                                            cell.column.tdExtractor &&
                                            typeof cell.column.tdExtractor ===
                                                'function' ? (
                                                dataToDisplay(
                                                    cell.render('Cell'),
                                                    cell,
                                                    row
                                                )
                                            ) : (
                                                <td
                                                    className={`${thClassToSend} ${
                                                        isSweet
                                                            ? cell.column
                                                                  .fieldClassname
                                                            : ''
                                                    }`}
                                                    {...cell.getCellProps()}
                                                >
                                                    {isFetching ? (
                                                        <span className="d-flex justify-content-start placeholder-glow">
                                                            <span className="placeholder col-12" />
                                                        </span>
                                                    ) : (
                                                        dataToDisplay(
                                                            cell.render('Cell'),
                                                            cell,
                                                            row
                                                        )
                                                    )}
                                                </td>
                                            )
                                        )}
                                        {isActionActive ? (
                                            <td className="border-start text-center">
                                                <TableAction
                                                    isSelf={isSelf}
                                                    isTwice={isTwice}
                                                    rowData={row.original}
                                                    callback={callback}
                                                    dropCallback={dropCallback}
                                                />
                                            </td>
                                        ) : null}
                                        {isUrl ? (
                                            <td className="pointer">
                                                {row.fileNameText ? (
                                                    <FaDownload
                                                        onClick={() =>
                                                            downloadFileFunction(
                                                                row
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <FcCancel />
                                                )}
                                            </td>
                                        ) : null}
                                        {isShowDropButton ? (
                                            <td className={thClassToSend}>
                                                <CustomDropButton
                                                    id={
                                                        row?.original[
                                                            identifier
                                                        ]
                                                    }
                                                    field={listSelectedItemName}
                                                    formDispatcher={
                                                        formDispatcher
                                                    }
                                                    identifier={identifier}
                                                />
                                            </td>
                                        ) : null}
                                        {isSenderFlash || isSenderEmoney ? (
                                            <td className={thClassToSend}>
                                                <CommandeSenderActionsComponent
                                                    isSenderEmoney={
                                                        isSenderEmoney
                                                    }
                                                    isSenderFlash={
                                                        isSenderFlash
                                                    }
                                                    commande={row}
                                                    formDispatcher={
                                                        formDispatcher
                                                    }
                                                    nodeName={nodeName}
                                                />
                                            </td>
                                        ) : null}
                                        {isCommandeLitigieuse ? (
                                            <td className={thClassToSend}>
                                                <CommandeLitigieuseActionsComponent
                                                    commande={row}
                                                    formDispatcher={
                                                        formDispatcher
                                                    }
                                                    formState={formState}
                                                    nodeName={nodeName}
                                                />
                                            </td>
                                        ) : null}
                                        {isCommandeLitigieuseEmoney ? (
                                            <td className={thClassToSend}>
                                                <CommandeLitigieuseEmoneyActionsComponent
                                                    commande={row}
                                                    formDispatcher={
                                                        formDispatcher
                                                    }
                                                    formState={formState}
                                                    nodeName={nodeName}
                                                />
                                            </td>
                                        ) : null}
                                    </tr>
                                );
                            })}
                            {trToAddOnBottom &&
                                typeof trToAddOnBottom === 'function' &&
                                trToAddOnBottom(page)}
                        </tbody>
                        <tfoot>
                            {isAddTotalTr && page?.length && (
                                <tr className="lead fw-bold border">
                                    <td className="text-right font-weight-bold">
                                        Total
                                    </td>
                                    {mainMapper
                                        .map((item, index) => ({
                                            ...item,
                                            position: index + 1
                                        }))
                                        .sort((a, b) => a.position - b.position)
                                        .map((item) => {
                                            if (
                                                !item?.isTotal &&
                                                !item?.isCustomTotal
                                            )
                                                return <td>{` `}</td>;
                                            if (
                                                !item?.isTotal &&
                                                item?.isCustomTotal &&
                                                customTotal
                                            ) {
                                                return (
                                                    <td className="border">
                                                        {formatNumber({
                                                            value: customTotal(
                                                                data,
                                                                item?.devise
                                                            )
                                                        })}
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td className="text-right font-weight-bold border">
                                                    {formatNumber({
                                                        value: page.reduce(
                                                            (a, b) =>
                                                                Add(
                                                                    a,
                                                                    resolvePathPropertyOfObject(
                                                                        `original.${item.field}`,
                                                                        b
                                                                    )
                                                                ),
                                                            0
                                                        )
                                                    })}
                                                </td>
                                            );
                                        })}
                                </tr>
                            )}
                            {tfootTrToAdd &&
                                typeof tfootTrToAdd === 'function' &&
                                tfootTrToAdd(page)}
                            {dynamicBottom &&
                                typeof dynamicBottom === 'function' &&
                                dynamicBottom(notFormatedData)}
                        </tfoot>
                    </table>
                )}
                {bottomPagination && (
                    <div className="d-flex justify-content-center align-items-center fw-lighter mb-5">
                        <div className="pe-2 pt-3">
                            <ul className="pagination">
                                <li className="page-item">
                                    <span
                                        aria-hidden="true"
                                        role="button"
                                        className={`page-link ${
                                            !canPreviousPage
                                                ? 'disabled text-secondary'
                                                : null
                                        }`}
                                        type="button"
                                        title="Revenir à la première page"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        &laquo;
                                    </span>
                                </li>
                                <li className="page-item">
                                    <span
                                        aria-hidden="true"
                                        role="button"
                                        className={`page-link ${
                                            !canPreviousPage
                                                ? 'disabled text-secondary'
                                                : null
                                        }`}
                                        type="button"
                                        title="Précédent"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        &lt;
                                    </span>
                                </li>
                                <li className="page-item">
                                    <span
                                        aria-hidden="true"
                                        role="button"
                                        className={`page-link ${
                                            !canNextPage
                                                ? 'disabled text-secondary'
                                                : null
                                        }`}
                                        type="button"
                                        title="Suivant"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        &gt;
                                    </span>
                                </li>
                                <li className="page-item">
                                    <span
                                        aria-hidden="true"
                                        role="button"
                                        className={`page-link ${
                                            !canNextPage
                                                ? 'disabled text-secondary'
                                                : null
                                        }`}
                                        type="button"
                                        title="Aller à la dernière page"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        &raquo;
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="pe-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} sur {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Aller à la page:{' '}
                                <input
                                    className="ps-3"
                                    type="number"
                                    min="1"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageTargeted = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(pageTargeted);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                className="form-select form-select-sm"
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50, 100].map((pgSize) => (
                                    <option key={pgSize} value={pgSize}>
                                        {pgSize} Lignes
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {tablesRender && typeof tablesRender === 'function'
                    ? tablesRender(notFormatedData)
                    : null}
            </>
            // </div>
        );
    };

    return renderContent();
}

export { CustomTable };
