import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useWhereToGo } from '../../util';

export function BackComponent({
    stepBack = 1,
    className = 'btn btn-primary mt-2',
    params = ''
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { destination } = useWhereToGo(pathname, stepBack);
    const path = `${destination}${params}`;
    return (
        <span
            role="button"
            tabIndex={0}
            onClick={() =>
                navigate(path, {
                    replace: true
                })
            }
            onKeyDown={() =>
                navigate(path, {
                    replace: true
                })
            }
            className={className}
        >
            <MdOutlineArrowBack /> Back
        </span>
    );
}
