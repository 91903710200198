import { useCallback } from 'react';
// import { useSelector } from 'react-redux';
import {
    Add,
    Mul,
    resolvePathPropertyOfObject,
    Sub,
    TIME,
    TODAY,
    TYPE_BUSINESS
} from '@napp-inc/jnapp-util';
import { formatNumber, URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CustomDropdown, CustomRow } from '../../../../components';

// function transformerClesEnIndices(objet) {
//     // Créer un nouveau tableau pour stocker les clés
//     const nouvellesCles = objet ? Object.keys(objet) : [];

//     // Créer un nouvel objet pour stocker les paires clé-valeur
//     const nouvelObjet = {};

//     // Itérer sur les clés et affecter les nouvelles valeurs
//     nouvellesCles.forEach((cle, index) => {
//         nouvelObjet[index + 1] = objet[cle];
//     });

//     return nouvelObjet;
// }

export function RapportGainPerteVenteForm() {
    const VENTE = `Vente`;
    const GAINS_VENTE = `GainsVente`;
    const PERTES_VENTE = `PertesVente`;
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedDevise: { code: 'USD', designation: 'USD' },
            selectedType: { code: 'Montant', designation: 'USD' },
            listHeaders: []
        }
    });
    const { fields } = formState;
    const mergeData = useCallback(({ data = [], levels = {} }) => {
        const dataToRender = [];
        const processValues = (type, obj) =>
            Object.values(obj[type] || {})?.map((item) => ({
                ...item,
                montant: 0
            }));
        const achatsValues = processValues('achats', levels);
        const ventesValues = processValues('ventes', levels);
        data.forEach((element, index, array) => {
            const findSibbling = dataToRender.find(
                (item) => item?.organisation === element?.organisation
            );
            if (!findSibbling) {
                const newElement = {};
                const getSibblingArray = array.filter(
                    (item) => item?.organisation === element?.organisation
                );
                getSibblingArray.forEach(
                    ({ organisation, devise }, indexI, SibblingArray) => {
                        const mappedArray = SibblingArray.map((mappedItem) => ({
                            ...mappedItem.niveaux
                        }));
                        newElement.organisation =
                            newElement.organisation || organisation;
                        newElement.devise = newElement.devise || devise;
                        newElement.gain = SibblingArray.reduce(
                            (a, b) => Add(a, b.gain || 0),
                            0
                        );
                        newElement.perte = SibblingArray.reduce(
                            (a, b) => Add(a, b.perte || 0),
                            0
                        );
                        newElement.profit = SibblingArray.reduce(
                            (a, b) => Add(a, b.profit || 0),
                            0
                        );
                        if (!newElement.niveaux) {
                            const dataObjet = {
                                achats: {},
                                ventes: {},
                                totalQuantite: 0,
                                totalMontant: 0,
                                totalMontantVente: 0,
                                totalQuantiteVente: 0
                            };
                            const processChangesValues = (
                                property,
                                arrayData,
                                arrayMapped
                            ) => {
                                arrayData.forEach(({ value }) => {
                                    const findAchatExistingArray =
                                        arrayMapped.filter(
                                            (mapData) =>
                                                mapData?.[property]?.[value]
                                        );
                                    if (findAchatExistingArray.length) {
                                        const reducedValue =
                                            findAchatExistingArray.reduce(
                                                (a, b) =>
                                                    Add(
                                                        a,
                                                        b?.[property]?.[value]
                                                            ?.quantite || 0
                                                    ),
                                                0
                                            );
                                        if (!dataObjet[property]) {
                                            dataObjet[property] = {};
                                        }

                                        dataObjet[property][value] = {
                                            value,
                                            quantite: reducedValue,
                                            montant: Mul(reducedValue, value)
                                        };
                                    }
                                });
                            };
                            const processReducedValue = (
                                type,
                                items,
                                property
                            ) =>
                                items.reduce(
                                    (a, b) =>
                                        a +
                                        (dataObjet?.[type]?.[`${b.value}`]?.[
                                            property
                                        ] || 0),
                                    0
                                );
                            processChangesValues(
                                'achats',
                                achatsValues,
                                mappedArray
                            );
                            processChangesValues(
                                'ventes',
                                ventesValues,
                                mappedArray
                            );
                            newElement.niveaux = dataObjet;
                            newElement.niveaux.totalMontant =
                                processReducedValue(
                                    'achats',
                                    achatsValues,
                                    'montant'
                                );
                            newElement.niveaux.totalMontantVente =
                                processReducedValue(
                                    'ventes',
                                    ventesValues,
                                    'montant'
                                );
                            newElement.niveaux.totalQuantite =
                                processReducedValue(
                                    'achats',
                                    achatsValues,
                                    'quantite'
                                );
                            newElement.niveaux.totalQuantiteVente =
                                processReducedValue(
                                    'ventes',
                                    ventesValues,
                                    'quantite'
                                );

                            newElement.niveaux.isMontantEqual =
                                newElement.niveaux.totalMontant ===
                                newElement.niveaux.totalMontantVente;
                            newElement.niveaux.isQuantiteEqual =
                                newElement.niveaux.totalQuantite ===
                                newElement.niveaux.totalQuantiteVente;
                        }
                    }
                );
                dataToRender.push(newElement);
            }
        });
        return dataToRender;
    }, []);
    const listFormater = useCallback(
        (donnees) => {
            const dataTorender = [];
            (donnees?.[0]?.listReturn || []).map((item) => {
                const { operation, detail } = item;
                const { devise, valeurTypeProprietaire } = detail;
                let organisation = '';
                const operationKeys = Object.keys(operation || {});
                operationKeys.forEach((key) => {
                    let gain = 0;
                    let perte = 0;
                    if (key.includes(GAINS_VENTE)) {
                        if (key.includes(TYPE_BUSINESS.BUSINESS_MODEL)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                        if (key.includes(TYPE_BUSINESS.TRADITIONNEL_BUSINESS)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                        if (
                            !key.includes(
                                TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                            ) &&
                            !key.includes(TYPE_BUSINESS.BUSINESS_MODEL)
                        ) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                    }
                    if (key.includes(PERTES_VENTE)) {
                        if (key.includes(TYPE_BUSINESS.BUSINESS_MODEL)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                        if (key.includes(TYPE_BUSINESS.TRADITIONNEL_BUSINESS)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                        if (
                            !key.includes(
                                TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                            ) &&
                            !key.includes(TYPE_BUSINESS.BUSINESS_MODEL)
                        ) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                    }

                    dataTorender.push({
                        organisation,
                        devise,
                        gain,
                        perte,
                        profit: Sub(gain, perte),
                        niveaux: {
                            achats: operation?.[key]?.achats,
                            ventes: operation?.[key]?.ventes
                        }
                    });
                });

                return {};
            });
            return mergeData({
                data: dataTorender
                    .filter(
                        (item) => !!item.organisation && item.devise === 'USD'
                    )
                    .filter((item) => item.gain || item.perte),
                levels: donnees?.[0]?.niveaux
            });
        },
        [GAINS_VENTE, PERTES_VENTE, mergeData]
    );

    const cutomTableHeader = useCallback((data) => {
        const headers = data?.[0]?.niveaux;
        return (
            <>
                <tr className="border text-center text-justify">
                    <th className="border" rowSpan={2}>
                        #
                    </th>
                    <th className="border" rowSpan={2}>
                        Organisation
                    </th>
                    <th
                        className="border"
                        colSpan={Object?.keys(headers?.ventes || {})?.length}
                        rowSpan={
                            Object?.keys(headers?.ventes || {})?.length === 0
                                ? 2
                                : 1
                        }
                    >
                        Prix de Vente
                    </th>
                    <th className="border" rowSpan={2}>
                        Total Vente
                    </th>
                    <th
                        className="border"
                        colSpan={Object?.keys(headers?.achats || {})?.length}
                        rowSpan={
                            Object?.keys(headers?.achats || {})?.length === 0
                                ? 2
                                : 1
                        }
                    >
                        Prix d&apos;Achat
                    </th>
                    <th className="border" rowSpan={2}>
                        Total Achat
                    </th>
                    <th className="border" rowSpan={2}>
                        Gain
                    </th>
                    <th className="border" rowSpan={2}>
                        Perte
                    </th>
                    <th className="border" rowSpan={2}>
                        Profit
                    </th>
                </tr>
                <tr className="border text-center">
                    {Object.keys(headers?.ventes ? headers?.ventes : {}).map(
                        (item) => (
                            <th key={item} className="border">
                                {item}
                            </th>
                        )
                    )}
                    {Object.keys(headers?.achats ? headers?.achats : {}).map(
                        (item) => (
                            <th key={item} className="border">
                                {item}
                            </th>
                        )
                    )}
                </tr>
            </>
        );
    }, []);

    const mapper = useCallback(
        (data) => {
            const headers = data?.[0]?.niveaux;
            const getColumns = (type) => {
                const headersData = Object.keys(headers?.[type] || {}).length
                    ? Object.keys(headers[type]).map((item) => ({
                          displayName: item,
                          field: `niveaux.${type}.${item}.montant`,
                          tdExtractor: (element) =>
                              fields.selectedType.code === 'Quantite'
                                  ? element?.niveaux?.[type]?.[item]
                                        ?.quantite || 0
                                  : element?.niveaux?.[type]?.[item]?.montant ||
                                    0,
                          isTotal: true
                      }))
                    : [
                          {
                              displayName: `Taux Ope`,
                              field: `niveaux.${type}.montant`,
                              tdExtractor: () => 0,
                              isTotal: true
                          }
                      ];
                return headersData;
            };
            const achats = getColumns('achats');
            const ventes = getColumns('ventes');
            return [
                {
                    displayName: 'Organisation',
                    field: 'organisation'
                },
                ...ventes,
                {
                    displayName: 'Total Vente',
                    field:
                        fields.selectedType.code === 'Quantite'
                            ? 'niveaux.totalQuantiteVente'
                            : 'niveaux.totalMontantVente',
                    isTotal: true
                },
                ...achats,
                {
                    displayName: 'Total Achat',
                    field:
                        fields.selectedType.code === 'Quantite'
                            ? 'niveaux.totalQuantite'
                            : 'niveaux.totalMontant',
                    isTotal: true
                },
                {
                    displayName: 'Gain',
                    field: 'gain',
                    isTotal: true
                },
                {
                    displayName: 'Perte',
                    field: 'perte',
                    isTotal: true
                },
                {
                    displayName: 'Profit',
                    field: 'profit',
                    isTotal: true
                }
            ].map((item) => ({
                ...item,
                Header: item?.displayName,
                accessor: item?.field
            }));
        },
        [fields.selectedType.code]
    );

    const dynamicBottom = useCallback(
        (data) => {
            const mainMapper = mapper(data);
            const result = listFormater(data);
            return (
                <tr className="lead fw-bold border">
                    <td className="text-right font-weight-bold">Total</td>
                    {mainMapper
                        .map((mainItem, index) => ({
                            ...mainItem,
                            position: index + 1
                        }))
                        .sort((a, b) => a.position - b.position)
                        .map((mainItem) => {
                            if (!mainItem?.isTotal && !mainItem?.isCustomTotal)
                                return <td>{` `}</td>;
                            return (
                                <td className="text-right font-weight-bold border">
                                    {formatNumber({
                                        value: (result || [])?.reduce(
                                            (a, b) =>
                                                Add(
                                                    a,
                                                    resolvePathPropertyOfObject(
                                                        `${mainItem.field}`,
                                                        b
                                                    )
                                                ),
                                            0
                                        )
                                    })}
                                </td>
                            );
                        })}
                </tr>
            );
        },
        [listFormater, mapper]
    );

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                dynamicBottom={dynamicBottom}
                cutomTableHeader={cutomTableHeader}
                listTitle={`En ${fields.selectedType.designation}`}
                dynamicNodeName={`evolutionsGainPerteVente-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                dynamicMapper={mapper}
                mapper={[]}
                // isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: VENTE,
                    typesEntite: ['VenteSuperDealer'],
                    estObjet: true,
                    estOrganisation: true,
                    estNotDate: true,
                    typeProprietaire: 'SuperDealer'
                    // estAllOrganisation: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: VENTE,
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer'],
                    estOrganisation: true,
                    // estAllOrganisation: true,
                    estNotDate: true,
                    typeProprietaire: 'SuperDealer'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { code: 'Quantite', designation: 'Unité' },
                            { code: 'Montant', designation: 'USD' }
                        ]}
                        label="Valeur : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        defaultOption="Selectionner une valeur"
                        formDispatcher={formDispatcher}
                        name="selectedType"
                        id="selectedTypetypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
