import React, { Fragment } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { TiDeleteOutline } from 'react-icons/ti';
import { FORM_CONST, TIME } from '@napp-inc/jnapp-util';
import { BsDot } from 'react-icons/bs';
import {
    ConditionalRenderingWrapper,
    CustomConfirmButton
} from '../../../../components';

export function LeftPannel({
    formFields,
    setFormFields,
    toggledVisibilityView,
    toggledView,
    handleRemove,
    handleSubmit,
    form
}) {
    return (
        <>
            <div className="alert alert-secondary d-flex justify-content-between">
                <span>Liste des Objectifs</span>
            </div>
            {formFields.filter((item) => !item.isTooggled && !!item?.codeTache)
                .length ? (
                <>
                    <div>
                        <div>
                            {formFields.map((field, index) => (
                                <Fragment key={field?.codeTache}>
                                    {!field?.isTooggled &&
                                    !!field?.codeTache ? (
                                        <ul
                                            style={{
                                                listStyleType: 'none',
                                                margin: 0,
                                                padding: 0
                                            }}
                                        >
                                            <li>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        {field?.isVisible ? (
                                                            <IoMdArrowDropdown
                                                                role="button"
                                                                onClick={() =>
                                                                    toggledVisibilityView(
                                                                        {
                                                                            code: field?.codeTache,
                                                                            formFields,
                                                                            setFormFields
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <IoMdArrowDropright
                                                                role="button"
                                                                onClick={() =>
                                                                    toggledVisibilityView(
                                                                        {
                                                                            code: field?.codeTache,
                                                                            formFields,
                                                                            setFormFields
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                toggledView({
                                                                    code: field?.codeTache,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields
                                                                })
                                                            }
                                                            onKeyDown={() =>
                                                                toggledView({
                                                                    code: field?.codeTache,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields
                                                                })
                                                            }
                                                            className="ms-2"
                                                        >
                                                            {
                                                                field?.designationTache
                                                            }
                                                        </span>
                                                    </div>
                                                    <TiDeleteOutline
                                                        role="button"
                                                        title="Supprimer la charge ainsi que toutes les informations la concernant!"
                                                        className="text-secondary fs-6"
                                                        onClick={() =>
                                                            handleRemove({
                                                                index
                                                            })
                                                        }
                                                    />
                                                </div>
                                                {field.details.length &&
                                                field.isVisible
                                                    ? field.details.map(
                                                          (
                                                              detail,
                                                              indexAtSecondLevel
                                                          ) => (
                                                              <ConditionalRenderingWrapper
                                                                  key={`${field.designationTache}${detail.dateDebut}`}
                                                                  isShouldBeRendered={
                                                                      !!detail.dateDebut
                                                                  }
                                                              >
                                                                  <ul
                                                                      style={{
                                                                          marginLeft:
                                                                              '18px',
                                                                          listStyleType:
                                                                              'none',
                                                                          //   margin: 0,
                                                                          padding: 0
                                                                      }}
                                                                  >
                                                                      <li>
                                                                          {detail.isVisible ? (
                                                                              <IoMdArrowDropdown
                                                                                  role="button"
                                                                                  onClick={() =>
                                                                                      toggledVisibilityView(
                                                                                          {
                                                                                              code: field.codeTache,
                                                                                              index,
                                                                                              indexAtSecondLevel,
                                                                                              formFields,
                                                                                              setFormFields
                                                                                          }
                                                                                      )
                                                                                  }
                                                                              />
                                                                          ) : (
                                                                              <IoMdArrowDropright
                                                                                  role="button"
                                                                                  onClick={() =>
                                                                                      toggledVisibilityView(
                                                                                          {
                                                                                              code: field.codeTache,
                                                                                              index,
                                                                                              indexAtSecondLevel,
                                                                                              formFields,
                                                                                              setFormFields
                                                                                          }
                                                                                      )
                                                                                  }
                                                                              />
                                                                          )}
                                                                          <span
                                                                              role="button"
                                                                              tabIndex={
                                                                                  0
                                                                              }
                                                                              onClick={() =>
                                                                                  toggledView(
                                                                                      {
                                                                                          code: field.codeTache,
                                                                                          index,
                                                                                          indexAtSecondLevel,
                                                                                          isTargeted: true,
                                                                                          formFields,
                                                                                          setFormFields
                                                                                      }
                                                                                  )
                                                                              }
                                                                              onKeyDown={() =>
                                                                                  toggledView(
                                                                                      {
                                                                                          code: field.codeTache,
                                                                                          index,
                                                                                          indexAtSecondLevel,
                                                                                          isTargeted: true,
                                                                                          formFields,
                                                                                          setFormFields
                                                                                      }
                                                                                  )
                                                                              }
                                                                              className="ms-2"
                                                                          >
                                                                              {TIME.getMonthByDate(
                                                                                  detail.dateDebut
                                                                              ).toUpperCase()}
                                                                          </span>
                                                                          {detail
                                                                              .agents
                                                                              .length &&
                                                                          detail.isVisible
                                                                              ? detail.agents.map(
                                                                                    (
                                                                                        agent
                                                                                        // indexAtThirdLevel
                                                                                    ) => (
                                                                                        <ConditionalRenderingWrapper
                                                                                            key={`${field.codeTache}${detail.dateDebut}${agent.reference}${agent.designation}`}
                                                                                            isShouldBeRendered={
                                                                                                agent.reference
                                                                                            }
                                                                                        >
                                                                                            <ul
                                                                                                style={{
                                                                                                    marginLeft:
                                                                                                        '18px',
                                                                                                    listStyleType:
                                                                                                        'none',
                                                                                                    //   margin: 0,
                                                                                                    padding: 0
                                                                                                }}
                                                                                            >
                                                                                                <li>
                                                                                                    <span>
                                                                                                        <BsDot />
                                                                                                        {
                                                                                                            agent.designation
                                                                                                        }
                                                                                                        <span className="ms-2 badge bg-warning text-dark">
                                                                                                            {
                                                                                                                agent.target
                                                                                                            }
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </ConditionalRenderingWrapper>
                                                                                    )
                                                                                )
                                                                              : null}
                                                                      </li>
                                                                  </ul>
                                                              </ConditionalRenderingWrapper>
                                                          )
                                                      )
                                                    : null}
                                            </li>
                                        </ul>
                                    ) : null}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    <ConditionalRenderingWrapper>
                        <CustomConfirmButton
                            modalText="Voulez-vous soumettre cette élaboration?"
                            type="button"
                            backdrop="static"
                            text="Créer Objectifs"
                            className="btn-success btn mt-5 w-100"
                            confirmOnClick={handleSubmit}
                            disabled={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                            isActionEncours={
                                form.status === FORM_CONST.CREATION_EN_COURS
                            }
                        />
                    </ConditionalRenderingWrapper>
                </>
            ) : (
                <div className="text-center">
                    <span>Liste vide...</span>
                </div>
            )}
        </>
    );
}
