import { add, getMonthByDate } from '@napp-inc/jnapp-util';

const groupeByProperty = (data = [], property = '') =>
    data.reduce((acc, item) => {
        const key = item[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

export const elaborartionReformat = ({ liste, codeCharge }) => {
    const elements = [];
    const dataToFormat = (liste || []).map((item) => ({
        ...item,
        keyCharge: `${item?.elaborationAmendementPeriode?.valeurTypeProprietaire?.code}`,
        keyChargeSecteur: `${item?.elaborationAmendementPeriode?.valeurTypeProprietaire?.code}__${item?.direction?.code}_${item?.secteur?.code}`,
        keyChargeSecteurBeneficiaire: `${item?.elaborationAmendementPeriode?.valeurTypeProprietaire?.code}__${item?.direction?.code}_${item?.secteur?.code}_${item?.valeurTypeEntite?.reference}`
    }));
    const groupedData = groupeByProperty(dataToFormat, 'keyCharge');
    dataToFormat.forEach((data) => {
        const findMatchInElements = elements.find(
            (item) =>
                item?.element?.code ===
                data?.elaborationAmendementPeriode?.valeurTypeProprietaire?.code
        );
        if (!findMatchInElements) {
            const chargeElement = {
                element: {
                    code: data?.elaborationAmendementPeriode
                        ?.valeurTypeProprietaire?.code,
                    designation:
                        data?.elaborationAmendementPeriode
                            ?.valeurTypeProprietaire?.designation
                },
                devise: data?.devise,
                typeMoyenPaiement: data?.typeMoyenPaiement,
                idCaisse: data?.idCaisse,
                caisseDesignation: `${data?.valeurMoyenPaiement?.designation}-${data?.devise}`,
                estQuantite: true,
                estRempliAutomatique: false,
                isDeleted: false,
                isTooggled:
                    codeCharge ===
                    data?.elaborationAmendementPeriode?.valeurTypeProprietaire
                        ?.code,
                isVisible: false,
                details: []
            };
            // Get data by secteur;
            const groupedDataBySecteur = groupeByProperty(
                groupedData[data?.keyCharge],
                'keyChargeSecteur'
            );
            const chargesSecteurKeys = Object.keys(groupedDataBySecteur);
            chargesSecteurKeys.forEach((cs) => {
                const listChargeSecter = groupedDataBySecteur[cs];
                listChargeSecter.forEach((lcs) => {
                    const findSecteur = chargeElement.details.find(
                        (item) => item?.secteur?.code === lcs?.secteur?.code
                    );
                    if (!findSecteur) {
                        const secteur = {
                            direction: lcs?.direction?.code,
                            directionDesignation: lcs?.direction?.designation,
                            isTooggled: false,
                            isVisible: false,
                            idCaisse: data?.idCaisse,
                            caisseDesignation: `${data?.valeurMoyenPaiement?.designation}-${data?.devise}`,
                            secteur: {
                                code: lcs?.secteur?.code,
                                designation: lcs?.secteur?.designation
                            },
                            beneficiaires: []
                        };
                        // Get data by beneficiaire
                        const groupedDataBySecteurBeneficiaire =
                            groupeByProperty(
                                listChargeSecter,
                                'keyChargeSecteurBeneficiaire'
                            );
                        // Key of data By Beneficiaire
                        const chargesSecteurBeneficiaireKeys = Object.keys(
                            groupedDataBySecteurBeneficiaire
                        );
                        chargesSecteurBeneficiaireKeys.forEach((ben) => {
                            const listBeneChargeSecteur =
                                groupedDataBySecteurBeneficiaire[ben];
                            const MappelistBeneChargeSecteur =
                                listBeneChargeSecteur.map((item) => ({
                                    dateDebut: item?.periode?.dateDebut,
                                    dateFin: item?.periode?.dateFin,
                                    datePaiement:
                                        item?.periode?.datePaiement ||
                                        add(item?.periode?.dateDebut || 0, {
                                            days: 6
                                        }),
                                    quantite: item.quantite,
                                    ancienneQuantite: item.quantite,
                                    datePaiementString: new Date(
                                        item?.periode?.datePaiement ||
                                            add(item?.periode?.dateDebut || 0, {
                                                days: 6
                                            })
                                    )
                                        .toISOString()
                                        .split('T')[0],
                                    mois: getMonthByDate(
                                        item?.periode?.dateDebut
                                    )
                                }));
                            // Find beneficiaire
                            const findBeneficiaire = secteur.beneficiaires.find(
                                (benItem) =>
                                    benItem.valeurTypeEntite.reference ===
                                    listBeneChargeSecteur?.[0]?.valeurTypeEntite
                                        ?.reference
                            );
                            if (!findBeneficiaire) {
                                const beneficiaire = {
                                    valeurTypeEntite:
                                        listBeneChargeSecteur?.[0]
                                            ?.valeurTypeEntite,
                                    typeEntite:
                                        listBeneChargeSecteur?.[0]?.typeEntite,
                                    isTooggled: false,
                                    isVisible: false,
                                    idCaisse:
                                        listBeneChargeSecteur?.[0]
                                            ?.valeurMoyenPaiement?.reference,
                                    caisseDesignation: `${listBeneChargeSecteur?.[0]?.valeurMoyenPaiement?.designation}-${listBeneChargeSecteur?.[0]?.devise}`,
                                    prix: listBeneChargeSecteur?.[0]?.prix,
                                    ancienPrix:
                                        listBeneChargeSecteur?.[0]?.prix,
                                    montant: 0,
                                    periodes: MappelistBeneChargeSecteur
                                };
                                secteur.beneficiaires.push(beneficiaire);
                            }
                        });

                        // console.log("keyChargeSecteurBeneficiaire", groupedDataBySecteurBeneficiaire);

                        chargeElement.details.push(secteur);
                    }
                });
            });
            elements.push(chargeElement);
        }
    });
    return elements;
};
