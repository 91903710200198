import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { TYPE_ORGANISATION, formInitialState } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { CreationTaux } from './creation-form';

const defaultFields = {
    montant: 0,
    selectedMonnaie: {},
    selectedType: {},
    list: [],
    selectedTypeOrganisation: {},
    selectedCashCollecteur: {},
    selectedSuperDealer: {},
    previewValue: '',
    fictifName: {}
};

const defaultDependencies = {
    fields: ['monnaieLocale']
};

const defaultElements = ['monnaies'];

const setDefaultProperties = ({ type }) => {
    const CommonUiValidator = yup.object().shape({
        montant: yup.number(),
        selectedMonnaie: yup.object().shape({
            id: yup.string(),
            devise: yup.string()
        }),
        selectedType: yup.object().shape({
            code: yup.string(),
            designation: yup.string()
        }),
        list: yup
            .array()
            .of(
                yup.object().shape({
                    designation: yup.string().required(),
                    type: yup.string().required(),
                    valeur: yup.number().min(1)
                })
            )
            .required()
            .min(1, 'Ajouter au moins un élément à la liste')
    });

    const nappUiValidator = yup.object().shape({
        montant: yup.number().min(0),
        selectedType: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedTypeOrganisation: yup.object().shape({
            code: yup.string().required(),
            designation: yup.string().required()
        }),
        selectedSuperDealer: yup.object().when('selectedTypeOrganisation', {
            is: (selectedTypeOrganisation) =>
                selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.SUPER_DEALER,
            then: yup.object().shape({
                code: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                code: yup.string(),
                designation: yup.string()
            })
        }),
        selectedCashCollecteur: yup.object().when('selectedTypeOrganisation', {
            is: (selectedTypeOrganisation) =>
                selectedTypeOrganisation.code ===
                TYPE_ORGANISATION.CASH_COLLECTEUR,
            then: yup.object().shape({
                code: yup.string().required(),
                designation: yup.string().required()
            }),
            otherwise: yup.object().shape({
                code: yup.string(),
                designation: yup.string()
            })
        }),
        selectedMonnaie: yup.object().shape({
            id: yup.string().required(),
            devise: yup.string().required()
        }),
        list: yup
            .array()
            .of(
                yup
                    .object({
                        type: yup.string().required(),
                        designation: yup.string().required(),
                        valeur: yup.number()
                    })
                    .shape({})
            )
            .required()
            .min(1, 'Ajoutez au moins un élément à la liste des Dévises')
    });

    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: {
                fields: ['monnaieLocale', 'superDealer', 'cashCollecteur']
            },
            elements: defaultElements,
            uiValidator: nappUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.MARCHAND) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: CommonUiValidator
        };
    }
    if (type === TYPE_ORGANISATION.REVENDEUR) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: defaultElements,
            uiValidator: CommonUiValidator
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({})
    };
};

export function CreationTauxForm() {
    /**
     * Get data on redux store
     */
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );
    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const { fields, dependencies, elements, uiValidator } =
        setDefaultProperties({ type: typeEntite });
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });
    const renderContent = () =>
        initialState?.dependencies ? (
            <CreationTaux
                idToken={idToken}
                typeEntite={typeEntite}
                initialState={initialState}
                uiValidator={uiValidator}
                organisation={organisation}
            />
        ) : null;
    return renderContent();
}
