import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { ShowNewComponent } from '../../../components';
import {
    FacturationStockCreateRelPath,
    FacturationStockViewLayoutRelPath
} from '../../../routes/registration/stock/stock-rel-path';
import { ListFacturationStockForm } from './list-facturation-stock';
import { HistoriqueFacturationStock } from './historique';

export function FacturationStockViewLayout() {
    const { pathname } = useLocation();
    const [tab, setTab] = useState('list');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Facturation stock</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${FacturationStockViewLayoutRelPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.STOCK.FACTURATION_STOCK
                                        .INITIER_CREATION_FACTURATION_STOCK
                                ]}
                                destination={FacturationStockCreateRelPath.path}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="list"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setTab}
            >
                <Tab eventKey="list" title="Opérations Actuelles">
                    {tab === 'list' && <ListFacturationStockForm />}
                </Tab>
                <Tab eventKey="historique" title="Historique">
                    {tab === 'historique' && <HistoriqueFacturationStock />}
                </Tab>
            </Tabs>
        </div>
    );
}
