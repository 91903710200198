import { useCallback } from 'react';
import { Array, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { URL_CONST } from '../../../util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';

const groupementCompteByOrganisation = (caisses = []) => {
    const group = {};
    caisses.forEach((caisse) => {
        const codeGroup = caisse?.code;
        if (Object.prototype.hasOwnProperty.call(group, codeGroup)) {
            group?.[codeGroup].caisses.push(caisse);
        } else {
            group[codeGroup] = {
                designation: caisse?.designation,
                code: codeGroup,
                estEmoney: caisse?.estEmoney,
                caisses: [caisse]
            };
        }
    });
    // const result = [];
    const newResult = [];
    // Object.getOwnPropertyNames(group).forEach((item) => {
    //     result.push({
    //         id: item,
    //         designation: group[item]?.designation,
    //         estEmoney: group[item]?.estEmoney,
    //         caisses: group[item]?.caisses
    //     });
    // });
    Object.getOwnPropertyNames(group).forEach((item) => {
        const element = {
            id: item,
            designation: group[item]?.designation,
            estEmoney: group[item]?.estEmoney
        };
        const getKeys = Array.groupByProperty({
            array: group[item]?.caisses,
            property: 'devise'
        });

        const keys = Object.keys(getKeys);

        keys.forEach((key) => {
            element[key] = getKeys?.[key]?.[0];
        });

        newResult.push(element);
    });
    return newResult;
};
const setDefaultProperties = ({ type }) => {
    const payload = {
        estOrganisation: true,
        typeProprietaire: 'SuperDealer'
    };
    const reduxData = {
        nodeName: REDUX_NODE_NAME.SOLDE_CAISSE_EMONEY,
        nodeReduxProperty: 'soldeCaissesEmoney'
    };

    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            cfPayload: payload,
            reduxPayload: reduxData
        };
    }
    if (type === TYPE_ORGANISATION.REVENDEUR) {
        return {
            cfPayload: { ...payload, estEmoney: true },
            reduxPayload: reduxData
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            cfPayload: payload,
            reduxPayload: reduxData
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        return {
            cfPayload: payload,
            reduxPayload: reduxData
        };
    }
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR) {
        return {
            cfPayload: payload,
            reduxPayload: reduxData
        };
    }
    return {
        cfPayload: {},
        reduxPayload: {}
    };
};
export function ListCaisseEmoneyAllSuperDealerForm() {
    const { nappUser, soldeCaissesEmoney } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        soldeCaissesEmoney: reduxState.soldeCaissesEmoney
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const { cfPayload, reduxPayload } = setDefaultProperties({
        type: typeEntite
    });

    const getConclusion = (item) => {
        if (item?.estEmoney)
            return (
                <span className="badge bg-danger rounded-pill">E-MONEY</span>
            );
        return <span className="badge bg-primary rounded-pill">CASH</span>;
    };

    const mapper = useCallback(() => {
        const getKeys = Array.groupByProperty({
            array: soldeCaissesEmoney,
            property: 'devise'
        });

        const keys = Object.keys(getKeys).sort();
        const addOtherPaths = keys.map((item) => ({
            displayName: `Caisse ${item}`,
            field: `${item}.solde`,
            isTotal: true
        }));
        const newMapper = [
            {
                displayName: 'Organistion',
                field: 'designation',
                position: 1
            },
            {
                displayName: 'Type Caisse',
                field: 'estEmoney',
                tdExtractor: (item) => getConclusion(item),
                position: 2
            },
            ...addOtherPaths
        ];

        return newMapper;
    }, [soldeCaissesEmoney])();
    // const tdExtractor = (item) =>
    //     item?.caisses?.map((bl) => (
    //         <td key={`${bl?.id}`}>
    //             {formatNumber({
    //                 value: bl?.solde,
    //                 devise: bl?.devise
    //             })}
    //         </td>
    //     ));
    return typeEntite &&
        reduxPayload.nodeName &&
        reduxPayload.nodeReduxProperty ? (
        <ListGenericForm
            reduxPropertyName={reduxPayload.nodeReduxProperty}
            functionName={URL_CONST.GET_LIST_CAISSE_ACTIVE_USER}
            nodeName={reduxPayload.nodeName}
            payload={cfPayload}
            isLinkToDetail={false}
            listFormater={groupementCompteByOrganisation}
            mapper={mapper}
        />
    ) : null;
}
