import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { DB_NODE, TIME, TODAY } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';

export function ListRegularisationSuperDealerForm() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({});
    const { isNapp } = useGetUserDetail({ nappUser });
    const mapper = isNapp
        ? [
              {
                  displayName: 'SuperDealer',
                  field: 'valeurTypeEntite.designation'
              },
              {
                  field: 'numero',
                  displayName: 'Numéro'
              },
              {
                  field: 'quantite',
                  displayName: 'Quantité'
              },
              {
                  //   field: 'typeOperation',
                  displayName: 'Type Opération',
                  dataExtractor: (item) => {
                      if (item.details.length)
                          return item.details?.[0]?.typeOperation;
                      return item?.typeOperation;
                  }
              },
              {
                  //   field: 'imputationCompte.designation',
                  displayName: 'Imputation',
                  dataExtractor: (item) => {
                      if (item.imputation) return item?.imputation?.designation;
                      return item?.imputationCompte?.designation;
                  }
              },
              {
                  field: 'libelle',
                  displayName: 'Libelle'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  displayName: 'Creation',
                  field: 'dateCreation'
              }
          ]
        : [
              {
                  field: 'numero',
                  displayName: 'Numéro'
              },
              {
                  field: 'quantite',
                  displayName: 'Quantité'
              },
              {
                  //   field: 'typeOperation',
                  displayName: 'Type Opération',
                  dataExtractor: (item) => {
                      if (item.details.length)
                          return item.details?.[0]?.typeOperation;
                      return item?.typeOperation;
                  }
              },
              {
                  field: 'imputationCompte.designation',
                  displayName: 'Imputation'
              },
              {
                  field: 'libelle',
                  displayName: 'Libelle'
              },
              {
                  field: 'etat',
                  displayName: 'Etat'
              },
              {
                  displayName: 'Creation',
                  field: 'dateCreation'
              }
          ];
    return (
        <ReduxReportTemplate
            listTitle=""
            isLinkToDetail
            reduxPropertyName="regularisationsStock"
            dynamicNodeName={`regularisationsStock-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_REGULARISATION_STOCK_INITIALS}
            nodeName={DB_NODE.REGULARISATION_STOCK}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={mapper}
            listFormater={(data) => {
                if (data?.length && data?.[0]?.length) return data?.[0];
                if (data?.length && !data?.[0]?.length) return [];
                return data;
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
        />
    );
}
