import { CustomAlert } from '../alert';
import { SweetAndSimpleWrapper } from './sweet-simple-wrapper';
import { CustomTable } from './table';

/**
 * Composant Tableau
 * @param {Object} param0 objet contenant les propriétés suivantes
 * @param {Array} param0.data liste des éléments à afficher sur le tableau
 * @param {String} param0.pathname chemin vers le formulaire détail
 * @param {Array} param0.mapper liste des éléments à afficher, contient les noms des colonnes qu'il faut afficher ainsi que les noms des champs associés dans la liste des données
 * @param {String} param0.identifier identifiant à utiliser comme key pour les différentes tr, fait référence à une information unique de la liste des données
 * @param {Array} param0.listSelectedItemName
 * @param {Function} param0.formDispatcher dispatcher du formulaire
 * @param {String} param0.tableClass class name css du tableau
 * @param {Boolean} param0.isUrl utilse s'il faut télécharger un fichier
 * @param {Function} param0.downloadFileFunction function pour télécharger le fichier
 * @param {Boolean} param0.isShowDropButton défini s'il faut afficher ou pas l'icon pour supprimer un élément du tableau
 * @param {Boolean} param0.isSenderFlash défini s'il s'agit d'un sender flash afin d'afficher des actions supplémentaires sur des éléments de la liste
 * @param {Boolean} param0.isSenderEmoney défini s'il s'agit d'un sender flash afin d'afficher des actions supplémentaires sur des éléments de la liste
 * @param {Boolean} param0.nodeName nodeName redux
 * @param {Array} param0.exportMapper mapper alternatif pour l'exportation vers un classeur
 * @param {Boolean} param0.isExportAllowed s'il faut afficher le bouton d'exportation (true par défaut)
 * @param {String} param0.fileName nom du fichier à exporter
 * @param {String} param0.sheetName nom de la feuille dans le fichier exporté
 * @param {String} param0.format format du fichier exporté (xlsx par défaut) *
 * @param {String} param0.devise @alias formatNumber
 * @param {Number} param0.nombreApresVirgule @alias formatNumber
 * @param {Boolean} param0.isCompact @alias formatNumber
 * @param {Boolean} param0.isLongCompactDisplay @alias formatNumber
 * @param {Boolean} param0.isPercent @alias formatNumber
 * @param {Boolean} param0.useGrouping @alias formatNumber
 * @param {Boolean} param0.isForceParse @alias formatNumber
 * @param {Boolean} param0.isSweet s'il faut utiliser le style du sweet table
 * @param {Function} param0.trToAddOnTop function pour une (des) ligne en haut
 * @param {Function} param0.trToAddOnTop function pour une (des) ligne en bas
 * @param {Function} param0.tfootTrToAdd function pour ajouter  une (des) ligne en bas du tableau
 * @param {Boolean} param0.isLinkToDetail s'il faut ajouter un lien vers le détail
 * @param {String} param0.refetchFunctionName nom de la fonction pour le refetch
 * @param {String} param0.functionName nom de la fonction pour le refetch
 * @param {String} param0.listTitle titre du tableau
 * @param {Array} param0.dataList liste des données
 * @param {Boolean} param0.isRefetchAllowed s'il faut autoriser le refetch
 * @param {Boolean} param0.isDynamicNode s'il faut s'agit d'un noeud dynamic
 * @param {String} param0.dynamicNodeName nom du noeud dynamic
 * @param {Object} param0.payload payload pour le refetch
 * @param {Function} param0.formater fonction pour formatter les données
 * @param {Function} param0.onError fonction pour gérer les erreurs
 * @param {Boolean} param0.isColumnClickable s'il faut rendre les colonnes cliquables
 * @param {Boolean} param0.filter s'il faut afficher le filtre
 * @param {Boolean} param0.bottomPagination s'il faut afficher la pagination en bas
 * @param {Boolean} param0.isRowSelector s'il faut afficher la sélection des lignes
 * @param {Function} param0.reduxDispatcher dispatcher redux
 * @param {Function} param0.viewSender fonction pour afficher le sender
 * @param {Boolean} param0.isCommandeLitigieuse s'il s'agit d'une commande litigieuse
 * @param {Boolean} param0.isCommandeLitigieuseEmoney s'il s'agit d'une commande litigieuse emoney
 * @param {Object} param0.formState état du formulaire
 * @param {String} param0.sweetTableTitle titre du sweet table
 * @param {String} param0.sweetTableType type du sweet table
 * @param {Boolean} param0.isActionActive s'il faut afficher les actions
 * @param {Array} param0.mapper mapper alternatif pour le tableau
 * @returns React.Component
 */
export function SimpleTable({
    data = [],
    identifier = 'code',
    listSelectedItemName,
    formDispatcher,
    tableClass = 'table border-top table-hover table-striped table-responsive-sm',
    isUrl = false,
    downloadFileFunction,
    isShowDropButton = true,
    isSenderFlash = false,
    isSenderEmoney = false,
    nodeName,
    isDsiplayEmptyTableNotification = false,
    isExportAllowed = false,
    fileName,
    sheetName,
    format,
    devise,
    nombreApresVirgule,
    isCompact,
    isLongCompactDisplay,
    isPercent,
    useGrouping,
    isForceParse,
    isSweet = false,
    trToAddOnBottom,
    tfootTrToAdd,
    trToAddOnTop,
    mapper = [],
    isLinkToDetail = true,
    refetchFunctionName,
    functionName,
    listTitle,
    dataList,
    isRefetchAllowed = true,
    isDynamicNode,
    dynamicNodeName,
    payload,
    formater,
    onError,
    isColumnClickable,
    filter = true,
    bottomPagination = true,
    isRowSelector = false,
    reduxDispatcher,
    viewSender,
    isCommandeLitigieuse = false,
    isCommandeLitigieuseEmoney = false,
    formState,
    sweetTableTitle,
    sweetTableType,
    isActionActive = false,
    idToken,
    cutomTableHeader,
    isAddTotalTr,
    transformExportDataFunction,
    customTotal,
    dynamicMapper,
    notFormatedData,
    dynamicBottom,
    tablesRender,
    renderSpecificly,
    filterComplement
}) {
    const columns = mapper?.map((item) => ({
        ...item,
        Header: item?.displayName,
        accessor: item?.field
    }));
    const renderContent = () => {
        if (isDsiplayEmptyTableNotification && !data?.length) {
            return (
                <CustomAlert infoMessage="Aucune donnée à afficher pour l'instant" />
            );
        }
        if (!data?.length) return null;
        return (
            <SweetAndSimpleWrapper
                isSweet={isSweet}
                sweetTableTitle={sweetTableTitle}
                sweetTableType={sweetTableType}
            >
                <CustomTable
                    notFormatedData={notFormatedData}
                    dynamicMapper={dynamicMapper}
                    dynamicBottom={dynamicBottom}
                    isLinkToDetail={isLinkToDetail}
                    isColumnClickable={isColumnClickable}
                    isExportAllowed={isExportAllowed}
                    isRefetchAllowed={isRefetchAllowed}
                    tableClass={tableClass}
                    isTwice
                    data={data}
                    mapper={columns}
                    isActionActive={isActionActive}
                    isShowDropButton={isShowDropButton}
                    filter={filter}
                    functionName={refetchFunctionName || functionName}
                    nodeName={nodeName}
                    isDynamicNode={isDynamicNode}
                    dynamicNodeName={dynamicNodeName}
                    payload={payload}
                    formater={formater}
                    onError={onError}
                    dataList={dataList}
                    fileName={fileName}
                    sheetName={sheetName}
                    format={format}
                    listTitle={listTitle}
                    identifier={identifier}
                    isUrl={isUrl}
                    downloadFileFunction={downloadFileFunction}
                    isSenderFlash={isSenderFlash}
                    isSenderEmoney={isSenderEmoney}
                    trToAddOnTop={trToAddOnTop}
                    trToAddOnBottom={trToAddOnBottom}
                    tfootTrToAdd={tfootTrToAdd}
                    devise={devise}
                    nombreApresVirgule={nombreApresVirgule}
                    isCompact={isCompact}
                    isLongCompactDisplay={isLongCompactDisplay}
                    isPercent={isPercent}
                    useGrouping={useGrouping}
                    isForceParse={isForceParse}
                    isSweet={isSweet}
                    listSelectedItemName={listSelectedItemName}
                    formDispatcher={formDispatcher}
                    bottomPagination={bottomPagination}
                    isRowSelector={isRowSelector}
                    reduxDispatcher={reduxDispatcher}
                    viewSender={viewSender}
                    isCommandeLitigieuse={isCommandeLitigieuse}
                    isCommandeLitigieuseEmoney={isCommandeLitigieuseEmoney}
                    formState={formState}
                    idToken={idToken}
                    cutomTableHeader={cutomTableHeader}
                    isAddTotalTr={isAddTotalTr}
                    transformExportDataFunction={transformExportDataFunction}
                    customTotal={customTotal}
                    tablesRender={tablesRender}
                    renderSpecificly={renderSpecificly}
                    filterComplement={filterComplement}
                />
            </SweetAndSimpleWrapper>
        );
    };
    return renderContent();
}
