import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { HistoriqueTransfertExterne } from './historique';
import { ShowNewComponent } from '../../../components';
import { FinancePath } from '../../../routes/registration';
import { ListTransfertInterInstitutionnelForm } from './list-transfert-inter-institutionnel';

export function TransfertInterInstitutionnelViewLayout() {
    const { pathname } = useLocation();
    const [tab, setTab] = useState('list');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Transfert Externes</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${FinancePath.TransfertInterInstitutionnelViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                                        .INITIER_TRANSFERT_CAISSE_KINDB
                                ]}
                                destination={
                                    FinancePath
                                        .TransfertInterInstitutionnelCreatePath
                                        .path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="list"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setTab}
            >
                <Tab eventKey="list" title="Opérations Actuelles">
                    {tab === 'list' && <ListTransfertInterInstitutionnelForm />}
                </Tab>
                <Tab eventKey="historique" title="Historique">
                    {tab === 'historique' && <HistoriqueTransfertExterne />}
                </Tab>
            </Tabs>
        </div>
    );
}
