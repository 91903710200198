import {
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    formInitialState,
    fromTimestampToString
} from '@napp-inc/jnapp-util';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    MdBookmarkRemove,
    MdOutlinePending,
    MdOutlinePendingActions
} from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit /** FaListAlt */ } from 'react-icons/fa';
import { IoIosDoneAll, IoMdRemoveCircleOutline } from 'react-icons/io';
import {
    CommandeSenderActionsComponent,
    CustomBadge,
    CustomCenteredModal
} from '../../../components';
import { ListCommandeFlashSenderForm } from './list-commande-sender';
import { REDUX_NODE_NAME } from '../../../redux';
import { ListTotauxSenderFlash } from './totaux-sender';
import { EtatConfigurationSender } from '../sender-emoney/form-detail-configuration';
import { InfoCardSender } from '../sender-emoney/info-card-sender';
import { StateContext } from '../../../context/state-context';

const defaultFields = {
    isOpened: {},
    isSmsSynchro: {},
    intervalGetCommande: 0,
    intervalPostSms: 0,
    selectedDevise: {},
    devisesRupture: []
};

export function CommandeFlashSenderViewLayout() {
    const reduxDispatcher = useDispatch();
    const [key, setKey] = useState(ETAT.ETAT_ENCOURS);
    const [show, setShow] = useState(false);
    const {
        count,
        firebaseUser,
        nappUserMarchandType,
        ecouteurEtatConfigurationSender,
        etatConfigurationSender,
        organisation
    } = useSelector((reduxState) => ({
        count: {
            [ETAT.ETAT_ENCOURS]: reduxState.commandesFlashEncours.filter(
                (item) => !item?.demandeAnnulation
            ).length,
            attente_annulation: reduxState.commandesFlashEncours.filter(
                (item) => item?.demandeAnnulation
            ).length,
            [ETAT.ETAT_ATTENTE_VALIDATION]:
                reduxState.commandesFlashAttente.length,
            [ETAT.ETAT_ANNULER]: reduxState.commandesFlashAnnuler.length,
            [ETAT.ETAT_LIVRER]: reduxState.commandesFlashLivrer.length
        },
        firebaseUser: reduxState.firebaseUser,
        nappUserMarchandType: reduxState.nappUser?.marchand?.type,
        ecouteurEtatConfigurationSender:
            reduxState.etat.etatConfigurationSender.ecouteur,
        etatConfigurationSender: reduxState.etatConfigurationSender,
        organisation: reduxState.organisation
    }));
    const setShowModal = () => setShow((e) => !e);
    const { idToken, uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isDocument: true,
                path: DB_NODE.CONFIG_SENDER,
                id: uid,
                nodeName: REDUX_NODE_NAME.ETAT_CONFIGURATION_SENDER,
                ecouteur: ecouteurEtatConfigurationSender
            }
        ]
    });

    const { screenSize, setScreenSize } = useContext(StateContext);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize, setScreenSize]);

    useEffect(() => {
        if (screenSize <= 900) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [screenSize]);

    const nonActiveClass = `badge border border-secondary text-dark p-3`;
    const activeClass = `badge bg-primary p-3`;

    const getDesignation = (item) => {
        if (item.client.nom) {
            return `${item.client.nom || ''} ${item.client.prenom || ''}`;
        }
        if (item.designation) {
            return `${item.designation || ''}`;
        }
        if (item.client.numero) {
            return `${item.client.numero || ''}`;
        }
        return item.numero;
    };

    const renderSpecificly = useCallback((data) => {
        const etat = data?.[0]?.etat;
        let nodename = '';
        if (etat === ETAT.ETAT_ENCOURS) {
            nodename = REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS;
        }
        if (etat === ETAT.ETAT_ATTENTE_ANNULATION) {
            nodename = REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS;
        }
        if (etat === ETAT.ETAT_ATTENTE_VALIDATION) {
            nodename = REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE;
        }
        if (etat === ETAT.ETAT_ATTENTE_ANNULATION) {
            nodename = REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER;
        }
        if (etat === ETAT.ETAT_LIVRER) {
            nodename = REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER;
        }
        return data.map((item) => (
            <div key={item?.id} className="my-2">
                <div className="card">
                    <div className="h5 card-header">
                        <span className="fw-bolder">
                            {getDesignation(item)}
                        </span>
                    </div>
                    <div className="card-body fw-bolder">
                        <div className="card-text">
                            Numéro:{' '}
                            <span className="fw-bolder">{item.numero}</span>
                        </div>
                        <div className="card-text">
                            Quantité:{' '}
                            <span className="fw-bolder">{item.quantite}</span>
                        </div>
                        <div className="card-text">
                            Date création:{' '}
                            <span className="fw-bolder">
                                {fromTimestampToString(item.dateCreation)}
                            </span>
                        </div>
                        <CommandeSenderActionsComponent
                            commande={{ original: item }}
                            nodeName={nodename}
                            isSenderEmoney={false}
                            isSenderFlash
                            isMobileView
                        />
                    </div>
                </div>
            </div>
        ));
    }, []);

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <CustomCenteredModal
                    header="Configuration Sender"
                    show={show}
                    onHide={() => setShow(false)}
                >
                    <EtatConfigurationSender
                        formDispatcher={formDispatcher}
                        formState={formState}
                        organisation={organisation}
                        nappUserMarchandType={nappUserMarchandType}
                        etatConfigurationSender={etatConfigurationSender}
                        reduxDispatcher={reduxDispatcher}
                        idToken={idToken}
                        closeShow={() => setShow(false)}
                    />
                </CustomCenteredModal>
                <h1 className="h2">Commande flash</h1>
                <FaEdit
                    role="button"
                    onClick={setShowModal}
                    className="icon-custom border rounded"
                />
            </div>
            <InfoCardSender
                etatConfigurationSender={etatConfigurationSender}
                isEmoney={false}
            />
            {isMobile ? (
                <>
                    <div className="d-flex flex-row overflow-scroll gap-2 py-1">
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setKey(ETAT.ETAT_ENCOURS)}
                            onKeyDown={() => setKey(ETAT.ETAT_ENCOURS)}
                            className={
                                key === ETAT.ETAT_ENCOURS
                                    ? activeClass
                                    : nonActiveClass
                            }
                        >
                            <MdOutlinePending className="me-2" />
                            Encours{' '}
                            <CustomBadge text={count[ETAT.ETAT_ENCOURS]} />
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setKey(ETAT.ETAT_ATTENTE_ANNULATION)}
                            onKeyDown={() =>
                                setKey(ETAT.ETAT_ATTENTE_ANNULATION)
                            }
                            className={
                                key === ETAT.ETAT_ATTENTE_ANNULATION
                                    ? activeClass
                                    : nonActiveClass
                            }
                        >
                            <MdBookmarkRemove className="me-2" />
                            Attente Annulation{' '}
                            <CustomBadge text={count.attente_annulation} />
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setKey(ETAT.ETAT_ATTENTE_VALIDATION)}
                            onKeyDown={() =>
                                setKey(ETAT.ETAT_ATTENTE_VALIDATION)
                            }
                            className={
                                key === ETAT.ETAT_ATTENTE_VALIDATION
                                    ? activeClass
                                    : nonActiveClass
                            }
                        >
                            <MdOutlinePendingActions className="me-2" /> Attente
                            Validation{' '}
                            <CustomBadge
                                text={count[ETAT.ETAT_ATTENTE_VALIDATION]}
                            />
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setKey(ETAT.ETAT_ANNULER)}
                            onKeyDown={() => setKey(ETAT.ETAT_ANNULER)}
                            className={
                                key === ETAT.ETAT_ANNULER
                                    ? activeClass
                                    : nonActiveClass
                            }
                        >
                            <IoMdRemoveCircleOutline className="me-2" />{' '}
                            Annulées{' '}
                            <CustomBadge text={count[ETAT.ETAT_ANNULER]} />
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setKey(ETAT.ETAT_LIVRER)}
                            onKeyDown={() => setKey(ETAT.ETAT_LIVRER)}
                            className={
                                key === ETAT.ETAT_LIVRER
                                    ? activeClass
                                    : nonActiveClass
                            }
                        >
                            <IoIosDoneAll className="me-2" /> Livrées{' '}
                            <CustomBadge text={count[ETAT.ETAT_LIVRER]} />
                        </div>
                        {/* <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setKey('totaux')}
                    onKeyDown={() => setKey('totaux')}
                    className={key === 'totaux' ? activeClass : nonActiveClass}
                >
                    <FaListAlt className="me-2" /> Totaux
                </div> */}
                    </div>
                    <div>
                        {key === ETAT.ETAT_ENCOURS && (
                            <ListCommandeFlashSenderForm
                                isRowSelector
                                isConnectRemoveFilter
                                etat={ETAT.ETAT_ENCOURS}
                                viewSender={ETAT.ETAT_ENCOURS}
                                reduxPropertyName="commandesFlashEncours"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ENCOURS &&
                                    !item?.demandeAnnulation
                                }
                                renderSpecificly={renderSpecificly}
                            />
                        )}
                        {key === 'attente_annulation' && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_ENCOURS}
                                viewSender={ETAT.ETAT_ATTENTE_ANNULATION}
                                reduxPropertyName="commandesFlashEncours"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ENCOURS &&
                                    item?.demandeAnnulation
                                }
                                renderSpecificly={renderSpecificly}
                            />
                        )}
                        {key === ETAT.ETAT_ATTENTE_VALIDATION && (
                            <ListCommandeFlashSenderForm
                                isRowSelector
                                isConnectRemoveFilter
                                etat={ETAT.ETAT_ATTENTE_VALIDATION}
                                viewSender={ETAT.ETAT_ATTENTE_VALIDATION}
                                reduxPropertyName="commandesFlashAttente"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                                }
                                renderSpecificly={renderSpecificly}
                            />
                        )}
                        {key === ETAT.ETAT_ANNULER && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_ANNULER}
                                viewSender={ETAT.ETAT_ANNULER}
                                reduxPropertyName="commandesFlashAnnuler"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ANNULER
                                }
                                isSenderFlash={false}
                                renderSpecificly={renderSpecificly}
                            />
                        )}
                        {key === ETAT.ETAT_LIVRER && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_LIVRER}
                                viewSender={ETAT.ETAT_LIVRER}
                                reduxPropertyName="commandesFlashLivrer"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_LIVRER
                                }
                                isSenderFlash={false}
                                renderSpecificly={renderSpecificly}
                            />
                        )}
                    </div>
                </>
            ) : null}

            {!isMobile ? (
                <Tabs
                    defaultActiveKey={ETAT.ETAT_ENCOURS}
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                    onSelect={setKey}
                >
                    <Tab
                        eventKey={ETAT.ETAT_ENCOURS}
                        title={
                            <p>
                                ENCOURS{' '}
                                <CustomBadge text={count[ETAT.ETAT_ENCOURS]} />{' '}
                            </p>
                        }
                    >
                        {key === ETAT.ETAT_ENCOURS && (
                            <ListCommandeFlashSenderForm
                                isRowSelector
                                isConnectRemoveFilter
                                etat={ETAT.ETAT_ENCOURS}
                                viewSender={ETAT.ETAT_ENCOURS}
                                reduxPropertyName="commandesFlashEncours"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ENCOURS &&
                                    !item?.demandeAnnulation
                                }
                            />
                        )}
                    </Tab>
                    <Tab
                        eventKey="attente_annulation"
                        title={
                            <p>
                                ATTENTE ANNULATION{' '}
                                <CustomBadge text={count.attente_annulation} />{' '}
                            </p>
                        }
                    >
                        {key === 'attente_annulation' && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_ENCOURS}
                                viewSender={ETAT.ETAT_ATTENTE_ANNULATION}
                                reduxPropertyName="commandesFlashEncours"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ENCOURS &&
                                    item?.demandeAnnulation
                                }
                            />
                        )}
                    </Tab>
                    <Tab
                        eventKey={ETAT.ETAT_ATTENTE_VALIDATION}
                        title={
                            <p>
                                ATTENTE VALIDATION{' '}
                                <CustomBadge
                                    text={count[ETAT.ETAT_ATTENTE_VALIDATION]}
                                />{' '}
                            </p>
                        }
                    >
                        {key === ETAT.ETAT_ATTENTE_VALIDATION && (
                            <ListCommandeFlashSenderForm
                                isRowSelector
                                isConnectRemoveFilter
                                etat={ETAT.ETAT_ATTENTE_VALIDATION}
                                viewSender={ETAT.ETAT_ATTENTE_VALIDATION}
                                reduxPropertyName="commandesFlashAttente"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        )}
                    </Tab>
                    <Tab
                        eventKey={ETAT.ETAT_ANNULER}
                        title={
                            <p>
                                ANNULEES{' '}
                                <CustomBadge text={count[ETAT.ETAT_ANNULER]} />{' '}
                            </p>
                        }
                    >
                        {key === ETAT.ETAT_ANNULER && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_ANNULER}
                                viewSender={ETAT.ETAT_ANNULER}
                                reduxPropertyName="commandesFlashAnnuler"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_ANNULER
                                }
                                isSenderFlash={false}
                            />
                        )}
                    </Tab>
                    <Tab
                        eventKey={ETAT.ETAT_LIVRER}
                        title={
                            <p>
                                LIVREES{' '}
                                <CustomBadge text={count[ETAT.ETAT_LIVRER]} />{' '}
                            </p>
                        }
                    >
                        {key === ETAT.ETAT_LIVRER && (
                            <ListCommandeFlashSenderForm
                                etat={ETAT.ETAT_LIVRER}
                                viewSender={ETAT.ETAT_LIVRER}
                                reduxPropertyName="commandesFlashLivrer"
                                nodeName={
                                    REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER
                                }
                                filterDataList={(item) =>
                                    item?.etat === ETAT.ETAT_LIVRER
                                }
                                isSenderFlash={false}
                            />
                        )}
                    </Tab>
                    <Tab eventKey="totaux" title="TOTAUX">
                        {key === 'totaux' && <ListTotauxSenderFlash />}
                    </Tab>
                </Tabs>
            ) : null}
        </div>
    );
}
