import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    FILTER_OPERATOR,
    FILTER_TYPE,
    TIME,
    TODAY
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListObjectifsForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, organisation } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser,
        organisation: reduxState.organisation
    }));
    /**
     * Common form processes
     */
    const { oid, typeEntite } = useGetUserDetail({
        nappUser,
        organisation
    });
    return (
        <ListGenericForm
            listenerFilters={[
                {
                    property: 'typeOrganisation',
                    value: typeEntite,
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL
                },
                {
                    property: 'valeurTypeOrganisation.reference',
                    value: oid,
                    type: FILTER_TYPE.WHERE,
                    operator: FILTER_OPERATOR.EQUAL
                },
                {
                    property: 'dateDerniereModification',
                    value: 'asc',
                    type: FILTER_TYPE.ORDER_BY
                },
                {
                    type: FILTER_TYPE.START_AT,
                    value: TODAY.nowUtc(),
                    isTimestampNowValue: true
                }
            ]}
            reduxPropertyName="objectifs"
            functionName={URL_CONST.GET_LIST_DEFINITION_OBJECTIF_INITIALS}
            nodeName={REDUX_NODE_NAME.OBJECTIFS}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            mapper={[
                {
                    field: 'valeurTypeOrganisation.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'nombreAgent',
                    displayName: 'Agents'
                },
                {
                    field: 'nombrePeriode',
                    displayName: 'Périodes'
                },
                {
                    field: 'nombreTache',
                    displayName: 'Tâches'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
