export const schema = [
    {
        description: 'valeurTypeOrganisation.designation',
        title: 'Organisation',
        className: 'border-bottom'
    },
    {
        description: 'nombreAgent',
        title: 'Agents',
        className: 'border-bottom'
    },
    {
        description: 'nombrePeriode',
        title: 'Périodes',
        className: 'border-bottom'
    },
    {
        description: 'nombreTache',
        title: 'Tâches',
        className: 'border-bottom'
    },
    {
        description: 'etat',
        title: 'Etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
