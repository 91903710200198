import { MdCorporateFare, MdSendToMobile } from 'react-icons/md';
import { HiOutlineCreditCard } from 'react-icons/hi';
import { BsCashCoin, BsCreditCard2Front } from 'react-icons/bs';
import { AiOutlineStock } from 'react-icons/ai';
import { GiReceiveMoney } from 'react-icons/gi';
import { GoOrganization } from 'react-icons/go';

/**
 * @desc Ligne credit dealer rel paths
 */
export const LigneCreditViewLayoutRelPath = {
    name: 'Ligne Crédit',
    path: 'ligne-credit',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const LigneCreditCreateRelPath = {
    name: '',
    path: 'create'
};

export const LigneCreditDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Compte bancaire organisation rel paths
 */
export const CompteBancaireOrganisationViewLayoutRelPath = {
    name: 'Compte organisation',
    path: 'compte-bancaire-organisation',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const CompteBancaireOrganisationCreateRelPath = {
    name: '',
    path: 'create'
};

export const CompteBancaireOrganisationDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Transfert fonds rel paths
 */
export const TransfertFondsViewLayoutRelPath = {
    name: 'Transfert fonds',
    path: 'transfert-fonds',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const TransfertFondsCreateRelPath = {
    name: '',
    path: 'create'
};

export const TransfertFondsDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Créditer compte rel paths
 */
export const CrediterCompteViewLayoutRelPath = {
    name: 'Créditer compte',
    path: 'crediter-compte',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const CrediterCompteCreateRelPath = {
    name: '',
    path: 'create'
};

export const CrediterCompteDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Appro compte emoney rel paths
 */
export const ApproCompteEmoneyViewLayoutRelPath = {
    name: 'Appro compte emoney',
    path: 'appro-compte-emoney',
    icon: <BsCreditCard2Front className="icon-custom" />
};

export const ApproCompteEmoneyCreateRelPath = {
    name: '',
    path: 'create'
};

export const ApproCompteEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};
/**
 * @desc Taux  rel paths
 */
export const TauxActifsViewLayoutRelPath = {
    name: 'Taux Actifs',
    path: 'taux-act',
    icon: <MdCorporateFare className="icon-custom" />
};

export const TauxViewLayoutRelPath = {
    name: 'Taux',
    path: 'taux',
    icon: <MdCorporateFare className="icon-custom" />
};

export const TauxCreateRelPath = {
    name: '',
    path: 'create'
};

export const TauxDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Balance organisation  rel paths
 */
export const BalanceOrganisationViewLayoutRelPath = {
    name: 'Balance',
    path: 'balance',
    icon: <MdCorporateFare className="icon-custom" />
};

/**
 * @desc Compte Caution organisation  rel paths
 */
export const CompteCautionOrganisationViewLayoutRelPath = {
    name: 'Compte Caution',
    path: 'caution-compte',
    icon: <MdCorporateFare className="icon-custom" />
};

/**
 * @desc Compte Transfert organisation  rel paths
 */
export const CompteTransfertOrganisationViewLayoutRelPath = {
    name: 'Compte Transfert',
    path: 'cmpt-transfert',
    icon: <MdCorporateFare className="icon-custom" />
};

/**
 * @desc Stock super dealer  rel paths
 */
export const ReportStockSuperDealerViewLayoutRelPath = {
    name: 'Niveau Stock',
    path: 'stock-sd',
    icon: <AiOutlineStock className="icon-custom" />
};

/**
 * @desc Transfert rel paths
 */
export const TransfertViewLayoutRelPath = {
    name: 'Caisses',
    path: 'transfert-caisse',
    icon: <BsCashCoin className="icon-custom" />
};

export const TransfertCreateRelPath = {
    name: '',
    path: 'create'
};

export const TransfertDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Change rel paths
 */
export const ChangeViewLayoutRelPath = {
    name: 'Change',
    path: 'change',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ChangeCreateRelPath = {
    name: '',
    path: 'create'
};

export const ChangeCompteCreateRelPath = {
    name: '',
    path: 'create-c'
};

export const ChangeDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Change Guichet rel paths
 */
export const ChangeGuichetViewLayoutRelPath = {
    name: 'Change guichet',
    path: 'change-guichet',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const ChangeGuichetCreateRelPath = {
    name: '',
    path: 'create'
};

export const ChangeGuichetDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Encaissement rel paths
 */
export const EncaissementDecaissementViewLayoutRelPath = {
    name: 'Encaissement',
    path: 'encaissement',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const EncaissementDecaissementCreateRelPath = {
    name: '',
    path: 'create'
};

export const EncaissementDecaissementDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Decaissement rel paths
 */
export const DecaissementViewLayoutRelPath = {
    name: 'Decaissement',
    path: 'decaissement',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const DecaissementCreateRelPath = {
    name: '',
    path: 'create'
};

export const DecaissementDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Devise rel paths
 */
export const DeviseViewLayoutRelPath = {
    name: 'Devise',
    path: 'devise',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const DeviseCreateRelPath = {
    name: '',
    path: 'create'
};

export const DeviseDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Virement rel paths
 */
export const VirementViewLayoutRelPath = {
    name: 'Virement',
    path: 'virement',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const VirementCreateRelPath = {
    name: '',
    path: 'create'
};

export const VirementDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Virement KinDB rel paths
 */
export const VirementKinDBViewLayoutRelPath = {
    name: 'Caisse Autre Super Dealer',
    path: 'vrmt-sd',
    icon: <GoOrganization className="icon-custom" />
};

export const VirementKinDBCreateRelPath = {
    name: '',
    path: 'create'
};

export const VirementKinDBDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Depot rel paths
 */
export const OperationBancaireViewLayoutRelPath = {
    name: 'Opération Bancaire',
    path: 'op-bank',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const OperationBancaireCreateRelPath = {
    name: '',
    path: 'create'
};

export const OperationBancaireDetailRelPath = {
    name: '',
    path: ':id'
};

// Appro Cash

export const ApproCashListRelPath = {
    name: 'Appro-Cash',
    path: 'approcash',
    icon: <GiReceiveMoney className="icon-custom" />
};

export const ApproCashCreationRelPath = {
    name: '',
    path: 'create'
};

export const ApproCashDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Raison rel paths
 */
export const RaisonViewLayoutRelPath = {
    name: 'Raison',
    path: 'raison',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const RaisonCreateRelPath = {
    name: '',
    path: 'create'
};

export const RaisonDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Depenses rel paths
 */
export const DepensesViewLayoutRelPath = {
    name: 'Depenses',
    path: 'depenses',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const DepensesCreateRelPath = {
    name: '',
    path: 'create'
};

export const DepensesDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Cashbook rel paths
 */
export const CashbookViewLayoutRelPath = {
    name: 'Cashbook',
    path: 'cashbook',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

// export const CashbookRelPath = {
//     name: '',
//     path: 'create'
// };

// export const CashbookDetailRelPath = {
//     name: '',
//     path: ':id'
// };

/**
 * @desc Transfert inter institutionnel rel paths
 */
export const TransfertInterInstitutionnelViewLayoutRelPath = {
    name: 'Externes',
    path: 'tsfrt-externes',
    icon: <MdSendToMobile className="icon-custom" />
};

export const TransfertInterInstitutionnelCreateRelPath = {
    name: '',
    path: 'create'
};

export const TransfertInterInstitutionnelDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Crediter balance caution rel paths
 */
export const CrediterBalanceCautionViewLayoutRelPath = {
    name: 'Transfert Balance',
    path: 'trsf-balance',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

export const CrediterBalanceCautionCreateRelPath = {
    name: '',
    path: 'create'
};

export const CrediterBalanceCautionDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Taux Kindistribution  rel paths
 */

export const TauxKindistributionViewLayoutRelPath = {
    name: 'Taux',
    path: 'taux-kin-dist',
    icon: <MdCorporateFare className="icon-custom" />
};

export const TauxKindistributionCreateRelPath = {
    name: '',
    path: 'create'
};

export const TauxKindistributionDetailRelPath = {
    name: '',
    path: ':id'
};
