import {
    DATE_FORMAT,
    FORM_CONST,
    fromTimestampToString
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { BaseReportTemplateForm } from './base-report-template';
import {
    ListGenericForm,
    SimpleCard,
    SimpleChart,
    SimpleTable
} from '../../../components';
import { buildHighchartSeries } from '../../../util';

function TotalCards({ totalReturn, buildTotalCards, data }) {
    if (buildTotalCards && typeof buildTotalCards === 'function' && data) {
        const total = buildTotalCards(data, totalReturn);
        if (total?.isCustomObject) {
            return (
                <div className="row py-">
                    {Object?.keys(total?.customObject || {})?.map(
                        (key, index) => (
                            <div className="col-md-3 pb-1" key={key}>
                                <SimpleCard
                                    text={
                                        total?.customObject?.[key]?.displayName
                                    }
                                    value={
                                        total?.customObject?.[key]?.value || 0
                                    }
                                    devise={total?.customObject?.[key]?.devise}
                                    icon={index + 1}
                                    typeNumber={index + 1}
                                    isCompact={false}
                                />
                            </div>
                        )
                    )}
                </div>
            );
        }
        if (total?.length) {
            return (
                <div className="row py-">
                    {total.map((item, index) => (
                        <div className="col-md-3 pb-1" key={item?.id}>
                            <SimpleCard
                                text={item?.devise}
                                type="dark"
                                value={item?.total || 0}
                                devise={item?.devise}
                                isDefaultDevise={!!item?.isDefaultDevise}
                                icon={index + 1}
                                isCompact={false}
                                nombreApresVirgule={0}
                            />
                        </div>
                    ))}
                </div>
            );
        }
    }
    if (totalReturn && !buildTotalCards) {
        return (
            <div className="row py-3">
                <div className="col-md-4 pb-1">
                    <SimpleCard
                        text="Ouverture"
                        type="primary"
                        value={totalReturn?.soldeOuverture || 0}
                        isDefaultDevise
                        icon={1}
                        isCompact={false}
                    />
                </div>
                <div className="col-md-4 pb-1">
                    <SimpleCard
                        text="Clôture"
                        type="warning"
                        value={totalReturn?.soldeFermeture || 0}
                        isDefaultDevise
                        icon={1}
                        isCompact={false}
                    />
                </div>
                <div className="col-md-4 pb-1">
                    <SimpleCard
                        text="Résultat"
                        type={
                            (totalReturn?.total || 0) >= 0
                                ? 'success'
                                : 'danger'
                        }
                        value={totalReturn?.total || 0}
                        isDefaultDevise
                        icon={1}
                        isCompact={false}
                    />
                </div>
            </div>
        );
    }
    return null;
}

export function ReduxReportTemplate({
    formState,
    formDispatcher,
    mapper,
    functionName,
    payloadMapper,
    defaultPayloadMapperValues,
    isAddDateOnPayloadMapper,
    divContentClassName,
    // formater,
    elementPropertyName = 'mouvements',
    CustomOffCanvasButtonText,
    isShouldShowDateRange,
    debutName,
    finName,
    dateDebutName,
    dateFinName,
    selectedDateRangeReportName,
    searchButtonText,
    offCanvasTitle,
    identifier = 'id',
    children,
    payload,
    nodeName,
    reduxPropertyName,
    listTitle = '',
    onSuccess,
    dynamicNodeName,
    isAddTotalTr,
    listFormater,
    buildTotalCards,
    dynamicColumnFormater,
    dynamicColumnSeriesFormater,
    cutomTableHeader,
    trToAddOnBottom,
    trToAddOnTop,
    bottomPagination = false,
    isLinkToDetail = false,
    // isIncludeSecondTable = false,
    // secondMapper = [],
    // secondTableListFormater,
    chartDataListFormater,
    seriesMapper = [],
    chartTitle = '',
    yAxisChartTitle = '',
    isDisplayChart = false,
    isInitialFetch = true, // Cette props permet de spécifier si on doit récupérer les données initialement ou attendre le filtre
    renderSpecificly,
    transformExportDataFunction,
    customTotal,
    tfootTrToAdd,
    dynamicMapper,
    dynamicBottom,
    notFormatedData, // données brute qui viennent du retour de la fontion
    tablesRender
}) {
    const {
        totalReturn,
        listReturn,
        devisesReturn,
        dynamicColonne,
        dataReturn
    } = useSelector((reduxState) => ({
        totalReturn:
            reduxState.dynamicNode?.[dynamicNodeName]?.[0]?.totalReturn,
        devisesReturn: reduxState.dynamicNode?.[dynamicNodeName]?.[0]?.devises,
        dynamicColonne:
            reduxState.dynamicNode?.[dynamicNodeName]?.[0]?.dynamicColonne,
        listReturn: reduxState.dynamicNode?.[dynamicNodeName],
        dataReturn: reduxState?.[reduxPropertyName]
    }));
    const dataFromRedux =
        listFormater && typeof listFormater === 'function'
            ? listFormater(listReturn)
            : listReturn;
    let chartDataList = [];
    const additionalSeriesMapper = [];
    if (
        formState?.elements?.[elementPropertyName]?.status ===
            FORM_CONST.STATUT.ETAT_INITIAL &&
        isInitialFetch
    ) {
        chartDataList =
            chartDataListFormater && typeof chartDataListFormater === 'function'
                ? chartDataListFormater(listReturn)
                : [];
        if (
            dynamicColumnSeriesFormater &&
            typeof dynamicColumnSeriesFormater === 'function'
        ) {
            additionalSeriesMapper.push(
                ...dynamicColumnSeriesFormater(dynamicColonne)
            );
        }
    } else {
        chartDataList =
            chartDataListFormater && typeof chartDataListFormater === 'function'
                ? chartDataListFormater(
                      formState?.elements?.[elementPropertyName]?.value
                  )
                : [];
        if (
            dynamicColumnSeriesFormater &&
            typeof dynamicColumnSeriesFormater === 'function'
        ) {
            additionalSeriesMapper.push(
                ...dynamicColumnSeriesFormater(
                    formState?.elements?.[elementPropertyName]?.value?.[0]
                        ?.dynamicColonne
                )
            );
        }
    }
    const series = buildHighchartSeries({
        seriesMapper: seriesMapper.concat(additionalSeriesMapper),
        dataList: chartDataList
    });
    const categories = chartDataList.map((item) =>
        fromTimestampToString(item?.date, DATE_FORMAT.DATE_FORMAT_DD_MMM_YYYY)
    );
    const renderTable = () => {
        if (
            formState?.elements?.[elementPropertyName]?.status ===
                FORM_CONST.STATUT.ETAT_INITIAL &&
            isInitialFetch
        ) {
            const dynamicColumns = [];
            if (
                dynamicColumnFormater &&
                typeof dynamicColumnFormater === 'function'
            ) {
                if (dynamicColonne) {
                    dynamicColumns.push(
                        ...dynamicColumnFormater(dynamicColonne)
                    );
                }
                if (devisesReturn) {
                    dynamicColumns.push(
                        ...dynamicColumnFormater(devisesReturn)
                    );
                }
            }
            return (
                <>
                    <TotalCards
                        data={dataFromRedux}
                        buildTotalCards={buildTotalCards}
                        totalReturn={totalReturn}
                    />
                    <ListGenericForm
                        notFormatedData={
                            notFormatedData ||
                            formState?.elements?.[elementPropertyName]?.value ||
                            listReturn
                        }
                        dynamicBottom={dynamicBottom}
                        dynamicMapper={dynamicMapper}
                        reduxPropertyName={reduxPropertyName}
                        functionName={functionName}
                        nodeName={nodeName}
                        payload={payload}
                        isLinkToDetail={isLinkToDetail}
                        identifier={identifier}
                        formater={(data) => [data]}
                        isExportAllowed
                        isPagination={false}
                        bottomPagination={bottomPagination}
                        listTitle={listTitle}
                        sweetTableType="primary"
                        mapper={mapper.concat(dynamicColumns)}
                        onSuccess={onSuccess}
                        isDynamicNode
                        dynamicNodeName={dynamicNodeName}
                        listFormater={listFormater}
                        cutomTableHeader={cutomTableHeader}
                        trToAddOnBottom={trToAddOnBottom}
                        trToAddOnTop={trToAddOnTop}
                        isAddTotalTr={isAddTotalTr}
                        transformExportDataFunction={
                            transformExportDataFunction
                        }
                        customTotal={customTotal}
                        tfootTrToAdd={tfootTrToAdd}
                        tablesRender={tablesRender}
                    />
                </>
            );
        }
        const data = (() => {
            if (
                listFormater &&
                typeof listFormater === 'function' &&
                isInitialFetch
            ) {
                return listFormater(
                    formState?.elements?.[elementPropertyName]?.value
                );
            }
            if (reduxPropertyName && dataReturn?.length) {
                return dataReturn;
            }
            return formState?.elements?.[elementPropertyName]?.value;
        })();
        const dynamicColumns = [];
        if (
            dynamicColumnFormater &&
            typeof dynamicColumnFormater === 'function'
        ) {
            dynamicColumns.push(
                ...dynamicColumnFormater(
                    formState?.elements?.[elementPropertyName]?.value?.[0]
                        ?.devises || {}
                )
            );
            dynamicColumns.push(
                ...dynamicColumnFormater(
                    formState?.elements?.[elementPropertyName]?.value?.[0]
                        ?.dynamicColonne || {}
                )
            );
        }
        if (renderSpecificly) {
            return renderSpecificly({ data });
        }
        return (
            <>
                <TotalCards
                    buildTotalCards={buildTotalCards}
                    data={data || []}
                    totalReturn={
                        formState?.elements?.[elementPropertyName]?.value?.[0]
                            ?.totalReturn
                    }
                />
                <SimpleTable
                    data={data || []}
                    isDsiplayEmptyTableNotification={
                        formState?.elements?.[elementPropertyName]?.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS
                    }
                    dynamicMapper={dynamicMapper}
                    dynamicBottom={dynamicBottom}
                    notFormatedData={
                        notFormatedData ||
                        formState?.elements?.[elementPropertyName]?.value ||
                        listReturn
                    }
                    bottomPagination={bottomPagination}
                    isDisplaySearch
                    tableClassName=""
                    isShowDropButton={false}
                    mapper={mapper.concat(dynamicColumns)}
                    isLinkToDetail={isLinkToDetail}
                    identifier={identifier}
                    isExportAllowed
                    listTitle={listTitle}
                    sweetTableType="primary"
                    isRefetchAllowed={false}
                    cutomTableHeader={cutomTableHeader}
                    trToAddOnBottom={trToAddOnBottom}
                    trToAddOnTop={trToAddOnTop}
                    isAddTotalTr={isAddTotalTr}
                    payload={payload}
                    transformExportDataFunction={transformExportDataFunction}
                    customTotal={customTotal}
                    tfootTrToAdd={tfootTrToAdd}
                    tablesRender={tablesRender}
                />
            </>
        );
    };
    // const renderSecondTable = () => {
    //     if (!isIncludeSecondTable) {
    //         return null;
    //     }
    //     if (
    //         formState?.elements?.[elementPropertyName]?.status ===
    //         FORM_CONST.STATUT.ETAT_INITIAL
    //     ) {
    //         const dynamicColumns = [];
    //         if (
    //             dynamicColumnFormater &&
    //             typeof dynamicColumnFormater === 'function'
    //         ) {
    //             dynamicColumns.push(...dynamicColumnFormater(devisesReturn));
    //         }
    //         return (
    //             <ListGenericForm
    //                 reduxPropertyName={reduxPropertyName}
    //                 functionName={functionName}
    //                 nodeName={nodeName}
    //                 payload={payload}
    //                 isLinkToDetail={isLinkToDetail}
    //                 identifier={identifier}
    //                 formater={(data) => [data]}
    //                 isExportAllowed
    //                 isPagination={false}
    //                 bottomPagination={bottomPagination}
    //                 listTitle={listTitle}
    //                 sweetTableType="primary"
    //                 mapper={secondMapper.concat(dynamicColumns)}
    //                 onSuccess={onSuccess}
    //                 isDynamicNode
    //                 dynamicNodeName={dynamicNodeName}
    //                 listFormater={secondTableListFormater}
    //                 cutomTableHeader={cutomTableHeader}
    //                 trToAddOnBottom={trToAddOnBottom}
    //                 trToAddOnTop={trToAddOnTop}
    //             />
    //         );
    //     }
    //     const data =
    //         secondTableListFormater &&
    //         typeof secondTableListFormater === 'function'
    //             ? secondTableListFormater(
    //                   formState?.elements?.[elementPropertyName]?.value
    //               )
    //             : formState?.elements?.[elementPropertyName]?.value;
    //     const dynamicColumns = [];
    //     if (
    //         dynamicColumnFormater &&
    //         typeof dynamicColumnFormater === 'function'
    //     ) {
    //         dynamicColumns.push(
    //             ...dynamicColumnFormater(
    //                 formState?.elements?.[elementPropertyName]?.value?.[0]
    //                     ?.devises || {}
    //             )
    //         );
    //     }
    //     return (
    //         <SimpleTable
    //             isSweet
    //             data={data || []}
    //             isDsiplayEmptyTableNotification={
    //                 formState?.elements?.[elementPropertyName]?.status ===
    //                 FORM_CONST.FETCH_ELEMENT_SUCCESS
    //             }
    //             bottomPagination={bottomPagination}
    //             isDisplaySearch
    //             tableClassName=""
    //             isShowDropButton={false}
    //             mapper={secondMapper.concat(dynamicColumns)}
    //             isLinkToDetail={isLinkToDetail}
    //             identifier={identifier}
    //             isExportAllowed
    //             listTitle={listTitle}
    //             sweetTableType="primary"
    //             isRefetchAllowed={false}
    //             cutomTableHeader={cutomTableHeader}
    //             trToAddOnBottom={trToAddOnBottom}
    //             trToAddOnTop={trToAddOnTop}
    //         />
    //     );
    // };
    const xAxisTickInterval = Math.round(chartDataList.length / 30 + 0.5);
    const renderSimpleChart = () => {
        if (!isDisplayChart || chartDataList?.length < 1) return null;
        return (
            <div className="row">
                <div className="col-12">
                    <SimpleChart
                        xAxisTickInterval={xAxisTickInterval}
                        series={series}
                        categories={categories}
                        title={chartTitle}
                        yAxisTittle={yAxisChartTitle}
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <BaseReportTemplateForm
                contents={{
                    0: renderSimpleChart,
                    1: renderTable
                    // 2: renderSecondTable
                }}
                isFetching={
                    formState?.elements?.[elementPropertyName]?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                dynamicMapper={dynamicMapper}
                dynamicBottom={dynamicBottom}
                notFormatedData={
                    notFormatedData ||
                    formState?.elements?.[elementPropertyName]?.value ||
                    listReturn
                }
                formState={formState}
                formDispatcher={formDispatcher}
                defaultPayloadMapperValues={defaultPayloadMapperValues}
                payloadMapper={payloadMapper}
                functionName={functionName}
                isAddDateOnPayloadMapper={isAddDateOnPayloadMapper}
                divContentClassName={divContentClassName}
                elementPropertyName={elementPropertyName}
                CustomOffCanvasButtonText={CustomOffCanvasButtonText}
                isShouldShowDateRange={isShouldShowDateRange}
                debutName={debutName}
                finName={finName}
                dateDebutName={dateDebutName}
                dateFinName={dateFinName}
                selectedDateRangeReportName={selectedDateRangeReportName}
                searchButtonText={searchButtonText}
                offCanvasTitle={offCanvasTitle}
                onSuccess={onSuccess}
                tfootTrToAdd={tfootTrToAdd}
            >
                {children}
            </BaseReportTemplateForm>
        </>
    );
}
