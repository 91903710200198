import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    ETAT,
    formInitialState,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../util';
import { CustomToast } from '../alert';
import { CustomConfirmButton } from '../button';
import { UserServiceWrapper } from '../container';

const defaultFields = {
    actionEncours: ''
};
/**
 * Ce composant affiche les actions qu'un sender peut effectuer sur une commandes flash
 * Elle est rajoutée à la liste des commandes flash pour ajouter éventuellement une action
 * à effectuer sur une commande donnée
 * @param {Object} param0 Objet contenant commande et nodeName
 * @param {Object} param0.commande la commande flash
 * @param {Object} param0.nodeName nodeName redux où envoyer les changements de la mise à jour de la commande
 * @returns React.Component
 */
export function CommandeSenderActionsComponent({
    commande,
    nodeName,
    isSenderEmoney = false,
    isSenderFlash = false,
    isMobileView = false
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const updateEtat = (etat) => {
        const actionEncours = etat;
        const payloadToAdd = {};
        if (isSenderFlash && !isSenderEmoney) {
            payloadToAdd.idCommandeFlash = commande?.original?.id;
        }
        if (!isSenderFlash && isSenderEmoney) {
            payloadToAdd.idCommandeEmoney = commande?.original?.id;
        }
        const payload = {
            etat,
            ...payloadToAdd
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: actionEncours
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: isSenderFlash
                    ? URL_CONST.PUT_COMMANDE_FLASH
                    : URL_CONST.PUT_COMMANDE_EMONEY,
                payload,
                fields,
                reduxNodeName: nodeName,
                reduxDispatcher
            })
        );
    };
    // console.log(isSenderEmoney);
    return (
        <UserServiceWrapper
            services={[
                CODE_SERVICE.CODE_SERVICE_SENDER
                    .MISE_A_JOUR_COMMANDE_FLASH_SENDER,
                CODE_SERVICE.CODE_SERVICE_SENDER
                    .MISE_A_JOUR_COMMANDE_EMONEY_SENDER
            ]}
        >
            {form && form.status === FORM_CONST.MODIFICATION_ECHEC && (
                <CustomToast error={form.error} />
            )}

            {((commande?.original?.etat === ETAT.ETAT_ENCOURS &&
                !commande?.original?.demandeAnnulation) ||
                commande?.original?.etat === ETAT.ETAT_ATTENTE_VALIDATION) && (
                <CustomConfirmButton
                    type="button"
                    text={`${isSenderEmoney ? 'Valider' : 'Livrer'}`}
                    className={`${
                        isMobileView ? ' col-5' : ''
                    } btn-success btn-sm`}
                    confirmOnClick={() =>
                        updateEtat(
                            isSenderEmoney
                                ? ETAT.ETAT_VALIDER
                                : ETAT.ETAT_LIVRER
                        )
                    }
                    disabled={form.status === FORM_CONST.MODIFICATION_EN_COURS}
                    isActionEncours={
                        fields.actionEncours === ETAT.ETAT_LIVRER ||
                        fields.actionEncours === ETAT.ETAT_VALIDER
                    }
                />
            )}
            {commande?.original?.etat === ETAT.ETAT_ENCOURS &&
                !commande?.original?.demandeAnnulation && (
                    <CustomConfirmButton
                        type="button"
                        text="Attente"
                        className={`${
                            isMobileView ? ' col-5' : ''
                        } btn-warning btn-sm ms-1`}
                        confirmOnClick={() =>
                            updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                        }
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours ===
                            ETAT.ETAT_ATTENTE_VALIDATION
                        }
                    />
                )}
            {commande?.original?.etat === ETAT.ETAT_ATTENTE_VALIDATION && ' | '}
            {((commande?.original?.etat === ETAT.ETAT_ENCOURS &&
                commande?.original?.demandeAnnulation) ||
                commande?.original?.etat === ETAT.ETAT_ATTENTE_VALIDATION) && (
                <CustomConfirmButton
                    type="button"
                    text="Annuler"
                    className={`${
                        isMobileView ? ' col-5' : ''
                    } btn-danger btn-sm`}
                    confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                    disabled={form.status === FORM_CONST.MODIFICATION_EN_COURS}
                    isActionEncours={fields.actionEncours === ETAT.ETAT_ANNULER}
                    confirmText="Poursuivre"
                    cancelText="Abandonner"
                    header="Confirmation Annulation"
                />
            )}
            {commande?.original?.etat === ETAT.ETAT_ATTENTE_VALIDATION && ' | '}
            {commande?.original?.etat === ETAT.ETAT_ATTENTE_VALIDATION &&
                !commande?.original?.demandeAnnulation && (
                    <CustomConfirmButton
                        type="button"
                        text="Encours"
                        className={`${
                            isMobileView ? ' col-5' : ''
                        } btn-primary btn-sm`}
                        confirmOnClick={() => updateEtat(ETAT.ETAT_ENCOURS)}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            fields.actionEncours === ETAT.ETAT_ENCOURS
                        }
                        confirmText="Poursuivre"
                        cancelText="Abandonner"
                        header="Confirmation Annulation"
                    />
                )}
        </UserServiceWrapper>
    );
}
