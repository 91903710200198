import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    TYPE_ORGANISATION,
    checkPlageDate,
    formInitialState
    // fromTimestampToString
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../util';
import { MainReportComponentLarge } from '../../report/releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false
};

const setDefaultProperties = ({ type }) => {
    const defaultMapper = [
        {
            displayName: 'Initiateur',
            field: 'valeurTypeOrganisation.designation'
        },
        {
            displayName: 'Caisse Source',
            field: 'caisseA.designation'
        },
        {
            displayName: 'Type Caisse Source',
            tdExtractor: (item) =>
                item?.caisseA?.estEmoney ? 'Emoney' : 'Cash'
        },
        {
            displayName: 'Beneficiaire',
            field: 'valeurTypeOrganisationB.designation'
        },
        {
            displayName: 'Caisse Bénéficiaire',
            field: 'caisseB.designation'
        },
        {
            displayName: 'Type Caisse Bénéficiaire',
            tdExtractor: (item) =>
                item?.caisseB?.estEmoney ? 'Emoney' : 'Cash'
        },
        {
            displayName: 'Reference Dépôt',
            field: 'references.depot'
        },
        {
            displayName: 'Reference Retrait',
            field: 'references.retrait'
        },
        {
            displayName: 'Montant',
            field: 'montant'
        },
        {
            displayName: 'Devise',
            field: 'devise'
        },
        {
            displayName: 'Devise Sollicité',
            field: 'deviseSollicite'
        },
        {
            displayName: 'Etat',
            field: 'etat'
        },
        {
            displayName: 'Création',
            field: 'dateCreation'
        },
        {
            displayName: 'Modification',
            field: 'dateDerniereModification'
        }
    ];
    // DefaultListerners

    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.REVENDEUR)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: defaultMapper
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: [
                {
                    displayName: 'Compte',
                    field: 'valeurTypeEntite.numeroCompteExterne'
                },
                {
                    displayName: 'Stock',
                    field: 'numero'
                },
                {
                    displayName: 'Type Facturation',
                    field: 'typeEntite'
                },
                {
                    displayName: 'Devise',
                    field: 'valeurTypeEntite.devise'
                },
                {
                    displayName: 'Quantité',
                    field: 'quantite'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Creation',
                    field: 'dateCreation'
                }
            ]
        };
    return { mapper: [] };
};

export function HistoriqueTransfertExterne() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { mapper } = setDefaultProperties({ type: typeEntite });
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin
            // estOrganisation: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_TRANSFERT_CAISSE_KINDB,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponentLarge
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={elements.mouvements?.value || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        />
    );
}
