/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MOYEN_PAIEMENT } from '@napp-inc/jnapp-util';
import {
    BeneficiaireRowSelector,
    ChargeFixeRowSelector,
    PeriodesRowSelector,
    SecteurRowSelector
} from './organisme';

// formFields,
//     setFormFields
export function ChargeFixeBaseForm({
    formFields,
    setFormFields,
    field,
    index,
    handleAddHighLevel,
    handleSelectChange,
    charges,
    caisses,
    handleChange,
    handleRemove,
    directions,
    secteurs,
    periodes,
    getTypeBeneficiaire,
    onToggleView,
    isViewDetailAction = false
}) {
    const getClassNames = ({ moyenPaiement, estQuantite }) => {
        // second case
        if (moyenPaiement !== MOYEN_PAIEMENT.CASH && estQuantite)
            return {
                chargeClass: 'col-6',
                modePaiementClass: '',
                caisseClass: '',
                prixClass: 'col-6',
                directionClass: 'col-4 me-1',
                secteurClass: 'col-4 me-1',
                caisseSecteurClass: '',
                prixSecteurClass: 'col-2 me-1',
                typeBeneficiaireClass: 'me-1',
                beneficiaireClass: 'me-1',
                beneficiaireCaisseClass: '',
                beneficiairePrixClass: 'me-1',
                periodeClass: '',
                datePaiementClass: '',
                quantiteClass: ''
            };
        // Third case
        if (moyenPaiement === MOYEN_PAIEMENT.CASH && !estQuantite)
            return {
                chargeClass: 'col-6',
                modePaiementClass: '',
                caisseClass: 'col-6',
                prixClass: '',
                directionClass: 'col-4 me-1',
                secteurClass: 'col-4 me-1',
                caisseSecteurClass: 'col-2 me-1',
                prixSecteurClass: '',
                typeBeneficiaireClass: '',
                beneficiaireClass: '',
                beneficiaireCaisseClass: '',
                beneficiairePrixClass: '',
                periodeClass: '',
                datePaiementClass: '',
                quantiteClass: ''
            };
        // Fourth case
        if (moyenPaiement === MOYEN_PAIEMENT.CASH && estQuantite) {
            return {
                chargeClass: 'col-4',
                modePaiementClass: '',
                caisseClass: 'col-4',
                prixClass: 'col-4',
                directionClass: 'col-2 me-1',
                secteurClass: 'col-3 me-1',
                caisseSecteurClass: 'col-3 me-1',
                prixSecteurClass: 'col-2 me-1',
                typeBeneficiaireClass: 'col-2 me-1',
                beneficiaireClass: 'col-3 me-1',
                beneficiaireCaisseClass: 'col-2 me-1',
                beneficiairePrixClass: 'col-2 me-1',
                periodeClass: '',
                datePaiementClass: '',
                quantiteClass: ''
            };
        }

        // first case
        return {
            chargeClass: 'col-12',
            modePaiementClass: '',
            caisseClass: '',
            prixClass: '',
            directionClass: 'col-5 me-1',
            secteurClass: 'col-5 me-1',
            caisseSecteurClass: '',
            prixSecteurClass: '',
            typeBeneficiaireClass: 'col-4 me-1',
            beneficiaireClass: 'col-5 me-1',
            beneficiaireCaisseClass: '',
            beneficiairePrixClass: '',
            periodeClass: '',
            datePaiementClass: '',
            quantiteClass: ''
        };
    };
    return (
        <div key={index} className="my-3">
            <ChargeFixeRowSelector
                formFields={formFields}
                setFormFields={setFormFields}
                handleRemove={handleRemove}
                getClassNames={getClassNames}
                field={field}
                charges={charges}
                handleSelectChange={handleSelectChange}
                caisses={caisses}
                index={index}
                handleChange={handleChange}
                isViewDetailAction={isViewDetailAction}
            />
            {!charges.find((charge) => charge?.code === field?.element?.code)
                ?.estRempliAutomatique ? (
                <div className="d-flex flex-column ms-3">
                    {formFields[index].details.map((detail, detailIndex) => (
                        <div
                            key={detailIndex}
                            className=""
                            id={`${field.element.code}${index}${detailIndex}`}
                        >
                            <SecteurRowSelector
                                setFormFields={setFormFields}
                                handleRemove={handleRemove}
                                handleAddHighLevel={handleAddHighLevel}
                                index={index}
                                detailIndex={detailIndex}
                                formFields={formFields}
                                onToggleView={onToggleView}
                                handleSelectChange={handleSelectChange}
                                directions={directions}
                                detail={detail}
                                charges={charges}
                                caisses={caisses}
                                field={field}
                                secteurs={secteurs}
                                getClassNames={getClassNames}
                                handleChange={handleChange}
                                isViewDetailAction={isViewDetailAction}
                            />
                            {formFields?.[index]?.details?.[detailIndex]
                                ?.isTooggled
                                ? formFields?.[index]?.details?.[
                                      detailIndex
                                  ]?.beneficiaires?.map(
                                      (beneficiaireItem, beneficiaireIndex) => (
                                          <div
                                              key={`${field.element.code}${index}${detailIndex}${beneficiaireIndex}`}
                                              className=""
                                              style={{
                                                  marginLeft: '10px'
                                              }}
                                              id={`${field.element.code}${index}${detailIndex}`}
                                          >
                                              <BeneficiaireRowSelector
                                                  getClassNames={getClassNames}
                                                  key={beneficiaireIndex}
                                                  id={`${field.element.code}${index}${detailIndex}${beneficiaireItem.valeurTypeEntite.reference}`}
                                                  index={index}
                                                  caisses={caisses}
                                                  field={field}
                                                  detailIndex={detailIndex}
                                                  //   periodeIndex={periodeIndex}
                                                  beneficiaireIndex={
                                                      beneficiaireIndex
                                                  }
                                                  handleChange={handleChange}
                                                  handleSelectChange={
                                                      handleSelectChange
                                                  }
                                                  handleRemove={handleRemove}
                                                  handleAddHighLevel={
                                                      handleAddHighLevel
                                                  }
                                                  beneficiaireItem={
                                                      beneficiaireItem
                                                  }
                                                  onToggleView={onToggleView}
                                                  beneficiaresOptions={getTypeBeneficiaire(
                                                      {
                                                          id: formFields[index]
                                                              ?.details[
                                                              detailIndex
                                                          ]?.beneficiaires[
                                                              beneficiaireIndex
                                                          ]?.typeEntite
                                                      }
                                                  )}
                                                  charges={charges}
                                                  formFields={formFields}
                                                  setFormFields={setFormFields}
                                                  isViewDetailAction={
                                                      isViewDetailAction
                                                  }
                                              />

                                              {formFields?.[index]?.details?.[
                                                  detailIndex
                                              ]?.beneficiaires?.[
                                                  beneficiaireIndex
                                              ]?.isTooggled
                                                  ? formFields?.[
                                                        index
                                                    ]?.details?.[
                                                        detailIndex
                                                    ]?.beneficiaires?.[
                                                        beneficiaireIndex
                                                    ]?.periodes.map(
                                                        (
                                                            periodeItem,
                                                            periodeIndex
                                                        ) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <PeriodesRowSelector
                                                                key={`${field.element.code}${index}${detailIndex}${beneficiaireItem.valeurTypeEntite.reference}${periodeIndex}`}
                                                                formFields={
                                                                    formFields
                                                                }
                                                                setFormFields={
                                                                    setFormFields
                                                                }
                                                                index={index}
                                                                detailIndex={
                                                                    detailIndex
                                                                }
                                                                periodeIndex={
                                                                    periodeIndex
                                                                }
                                                                periodeItem={
                                                                    periodeItem
                                                                }
                                                                handleSelectChange={
                                                                    handleSelectChange
                                                                }
                                                                periodes={
                                                                    periodes
                                                                }
                                                                beneficiaireIndex={
                                                                    beneficiaireIndex
                                                                }
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                                onToggleView={
                                                                    onToggleView
                                                                }
                                                                handleRemove={
                                                                    handleRemove
                                                                }
                                                                handleAddHighLevel={
                                                                    handleAddHighLevel
                                                                }
                                                                charges={
                                                                    charges
                                                                }
                                                                isViewDetailAction={
                                                                    isViewDetailAction
                                                                }
                                                            />
                                                        )
                                                    )
                                                  : null}
                                          </div>
                                      )
                                  )
                                : null}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
