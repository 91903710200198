import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { signOut } from 'firebase/auth';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { analytics, logEvent } from '@napp-inc/jnapp-firebase';
import { auth } from '../../firebase/firebase';

export function HeaderOptions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const firebaseUser = useSelector((reduxState) => reduxState.firebaseUser);
    return (
        <div className="me-2 px-3 rounded bg-white pb-3 pt-1 border">
            <div className="diplay-flex-row margin-top-16">
                <div className="wh- avatar">
                    <img
                        height={48}
                        width={48}
                        className="rounded-circle"
                        src="https://geeks-react.netlify.app/static/media/avatar-1.d2fd9642fd3628734b27.jpg"
                        alt=""
                    />
                </div>
                <div className="margin-left diplay-flex-column ms-3 lh-1">
                    <p className="mb-0 text-muted user-email">
                        {firebaseUser && firebaseUser.email
                            ? firebaseUser.email
                            : 'non connecté'}
                    </p>
                </div>
            </div>
            <div className="d-block justify-content-center">
                {/* <NavLink
                    className="display-block padding-2-19 custom-item"
                    to="/"
                >
                    Options 1
                </NavLink>
                <NavLink
                    className="display-block padding-2-19 custom-item"
                    to="/"
                >
                    Profil
                </NavLink>
                <NavLink
                    className="display-block padding-2-19 custom-item"
                    to="/"
                >
                    Configuration
                </NavLink> */}
                <NavLink
                    className="display-block padding-2-19 custom-item"
                    to="/my-account"
                >
                    Paramètres
                </NavLink>
            </div>
            <hr className="separator" />
            <Button
                type="button"
                className="btn-danger mx-4 px-5 btn-lg custom-logout-btn display-block mx-3"
                onClick={() => {
                    signOut(auth).then(() => {
                        dispatch(GENERIC_ITEM_ACTIONS_CREATOR.resetApp());
                        navigate('/sign-in', { replace: true });
                        logEvent(analytics, 'deconnexion');
                    });
                }}
            >
                Déconnexion <RiLogoutCircleRLine />
            </Button>
        </div>
    );
}
