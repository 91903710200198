import { useLocation } from 'react-router-dom';

export const useUrlParse = () => {
    const location = useLocation();
    const searchParms = location.search.replace('?', '').split('&');
    const urlParamsObject = {};
    searchParms.forEach((param) => {
        if (param) {
            const [key, value] = param.split('=');
            urlParamsObject[key] = value;
        }
    });
    return {
        location,
        urlParamsObject
    };
};
