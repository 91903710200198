import { useFormGlobalReducer, useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    ETAT,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    FORM_CONST,
    TODAY,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

const defaultFields = {
    removeFilters: []
};

export function ListCommandeEmoneySenderForm({
    etat,
    filterDataList,
    isSenderEmoney = true,
    functionName = URL_CONST.GET_LIST_COMMANDE_EMONEY_BY_SENDEUR,
    reduxPropertyName,
    nodeName,
    fetchType = FETCH_LISTENING,
    isConnectRemoveFilter = false,
    isRowSelector = false,
    viewSender,
    renderSpecificly
}) {
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;
    const removeFilterBuilder = (elements, meta) => {
        if (!isConnectRemoveFilter) return;
        if (!meta?.debut || !meta?.fin) return;
        const { debut, fin } = meta;
        const removeFilters = [
            {
                property: 'estRetrait',
                value: false,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE
            },
            {
                property: 'sendeur.reference',
                value: uid,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE,
                isRequired: true
            },
            {
                property: 'ancienEtat',
                value: etat,
                operator: FILTER_OPERATOR.EQUAL,
                type: FILTER_TYPE.WHERE
            },
            {
                property:
                    'datePrecedenteDerniereModificationDateDerniereModification',
                type: FILTER_TYPE.ORDER_BY,
                value: 'asc'
            },
            {
                value: `${debut}${TODAY.nowUtc()}`,
                type: FILTER_TYPE.START_AT
            },
            {
                type: FILTER_TYPE.END_AT,
                value: `${fin}~`
            }
        ];
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'removeFilters',
                data: removeFilters
            }
        });
    };
    const mapper =
        viewSender === ETAT.ETAT_VALIDER
            ? [
                  {
                      displayName: 'Client',
                      field: 'designationClient'
                  },
                  {
                      displayName: 'Numéro',
                      field: 'numeroClient'
                  },
                  {
                      field: 'montant',
                      displayName: 'Montant'
                  },
                  {
                      field: 'devise',
                      displayName: 'devise'
                  },
                  {
                      field: 'etat',
                      displayName: 'etat'
                  },
                  {
                      field: 'estNonNumeroReference',
                      displayName: 'Validée Via USSD',
                      isBoolean: true
                  },
                  {
                      field: 'dateCreation',
                      displayName: 'Création'
                  },
                  {
                      field: 'dateDerniereModification',
                      displayName: 'Dernière modification'
                  },
                  {
                      field: 'dateAttenteValidation',
                      displayName: 'Date Attente Validation',
                      isDate: true
                  }
              ]
            : [
                  {
                      displayName: 'Client',
                      field: 'designationClient'
                  },
                  {
                      displayName: 'Numéro',
                      field: 'numeroClient'
                  },
                  {
                      field: 'montant',
                      displayName: 'Montant'
                  },
                  {
                      field: 'devise',
                      displayName: 'devise'
                  },
                  {
                      field: 'etat',
                      displayName: 'etat'
                  },
                  {
                      field: 'dateCreation',
                      displayName: 'Création'
                  },
                  {
                      field: 'dateDerniereModification',
                      displayName: 'Dernière modification'
                  },
                  {
                      field: 'dateAttenteValidation',
                      displayName: 'Date Attente Validation',
                      isDate: true
                  }
              ];
    const formater = (data = []) =>
        data.map((item) => ({
            ...item,
            numeroClient: item?.client?.numero
                ? item?.client?.numero
                : item?.numero,
            designationClient: `${
                item?.client.designation
                    ? item?.client.designation
                    : `${item?.client?.prenom} ${item?.client?.nom}`
            }`
        }));
    return (
        <ListGenericForm
            renderSpecificly={renderSpecificly}
            viewSender={viewSender}
            isRowSelector={isRowSelector}
            onSuccess={removeFilterBuilder}
            filterDataList={filterDataList}
            isLinkToDetail={false}
            formater={formater}
            isSenderEmoney={isSenderEmoney}
            path={DB_NODE.COMMANDE_EMONEY}
            fetchType={fetchType}
            reduxPropertyName={reduxPropertyName}
            functionName={functionName}
            payload={{ etat, estMax: true }}
            nodeName={nodeName}
            listenerFilters={[
                {
                    property: 'estRetrait',
                    value: false,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE
                },
                {
                    property: 'sendeur.reference',
                    value: uid,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE,
                    isRequired: true
                },
                {
                    property: 'etat',
                    value: etat,
                    operator: FILTER_OPERATOR.EQUAL,
                    type: FILTER_TYPE.WHERE
                },
                {
                    type: FILTER_TYPE.ORDER_BY,
                    property: 'dateDerniereModification'
                },
                {
                    value: TODAY.nowUtc(),
                    type: FILTER_TYPE.START_AT
                }
            ]}
            removeFilters={fields.removeFilters}
            mapper={mapper}
        />
    );
}
