import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Add,
    Array,
    Div,
    resolvePathPropertyOfObject,
    TIME,
    TODAY
} from '@napp-inc/jnapp-util';
import { formatNumber, URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import {
    CustomDropdown,
    CustomRow,
    NumberDisplayer
} from '../../../../components';

export function RapportRegularisationProduitForm() {
    // const devises = useRef([]);
    const { deviseReference, taux } = useSelector((reduxState) => ({
        deviseReference: reduxState.deviseReference,
        taux: reduxState.taux.find((item) => item.type === 'TauxSystem')
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedSelector: { code: 'USD', designation: 'USD' },
            deviseFilter: [],
            listHeaders: []
        }
    });
    const { fields } = formState;
    const getType = (type) => {
        if (type === 'ImputationStock') return 'Stock';
        if (type === 'Imputation') return 'Caisse';
        return '';
    };
    const listFormater = useCallback(
        (donnees) => {
            const resulat = (donnees?.[0]?.listReturn || []).map((item) => ({
                organisation: item?.detail?.valeurTypeProprietaire?.designation,
                imputation: item?.detail?.valeurTypeEntite?.designation,
                type: getType(item?.detail?.typeEntite),
                entree: item?.entree || 0,
                sortie: item?.sortie || 0,
                resultat: item?.total || 0,
                devise: item?.detail?.devise
            }));
            // devises.current = Object.keys(donnees?.[0]?.devises || {}).map(
            //     (item) => ({ code: item, designation: item })
            // );
            // const groupedByOrganisation = Array.groupByProperty({
            //     array: resulat,
            //     property: 'organisation'
            // });
            // TODO: grouped elment to render in complexe table
            // const groupedByOrganisationMapped = Object.keys(
            //     groupedByOrganisation || {}
            // ).map((item) => ({
            //     organisation: item,
            //     data: groupedByOrganisation[item]
            // }));
            return resulat
                .filter((item) =>
                    fields.selectedSelector.code === 'ALL'
                        ? true
                        : item.devise === fields.selectedSelector.code
                )
                .filter((item) => item.entree || item.sortie);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields.selectedSelector.code]
    );

    const cutomTableHeader = () => (
        <tr className="border text-center text-justify">
            <th className="border">#</th>
            <th className="border">Organisation</th>
            <th className="border">Type</th>
            <th className="border">Imputation</th>
            <th className="border">Entrée</th>
            <th className="border">Sortie</th>
            <th className="border">Résultat</th>
        </tr>
    );

    const mapper = useCallback(
        () => [
            {
                displayName: 'Organisation',
                field: 'organisation'
            },
            {
                displayName: 'Type',
                field: 'type'
            },
            {
                displayName: 'Imputation',
                field: 'imputation'
            },
            {
                displayName: 'Entrée',
                field: 'entree',
                refField: 'entreeRef',
                isTotal: true
            },
            {
                displayName: 'Sortie',
                field: 'sortie',
                refField: 'sortieRef',
                isTotal: true
            },
            {
                displayName: 'Résultat',
                field: 'resultat',
                refField: 'resultatRef',
                isTotal: true
            }
        ],
        []
    );

    const titleToRender = (code) => {
        if (code === 'ALL') return 'Tous les comptes';
        return `Comptes ${code} | ${deviseReference?.code || ''} 1 = ${
            taux?.valeur || ''
        } ${taux?.devise || ''}`;
    };

    const dynamicBottom = useCallback(
        (data) => {
            const allData = data?.[0]?.listReturn || [];
            const elements = Array.groupByProperty({
                array: (allData || []).map((item) => ({
                    organisation:
                        item?.detail?.valeurTypeProprietaire?.designation,
                    imputation: item?.detail?.valeurTypeEntite?.designation,
                    type: getType(item?.detail?.typeEntite),
                    entree: item?.entree || 0,
                    sortie: item?.sortie || 0,
                    resultat: item?.total || 0,
                    devise: item?.detail?.devise
                })),
                property: 'devise'
            })?.[fields.selectedSelector.code];
            // const getKeys = Object.keys(elements || {});

            return (
                <tr>
                    <td className="border fw-bold" colSpan={4}>
                        Total
                    </td>
                    <td className="border fw-bold">
                        <NumberDisplayer
                            value={elements.reduce((a, b) => a + b.entree, 0)}
                            nombreApresVirgule={5}
                        />
                    </td>
                    <td className="border fw-bold">
                        <NumberDisplayer
                            value={elements.reduce((a, b) => a + b.sortie, 0)}
                            nombreApresVirgule={5}
                        />
                    </td>
                    <td className="border fw-bold">
                        <NumberDisplayer
                            value={elements.reduce((a, b) => a + b.resultat, 0)}
                            nombreApresVirgule={5}
                        />
                    </td>
                </tr>
            );
        },
        [fields.selectedSelector.code]
    );

    const syntheseTable = useCallback(
        (data) => {
            const conditionArray = ['Entrée', 'Sortie', 'Résultat'];
            const mainMapper = mapper(data).filter((mappedItem) =>
                conditionArray.includes(mappedItem.displayName)
            );
            const allData = data?.[0]?.listReturn || [];
            const dataMappeed = (allData || []).map((item) => ({
                organisation: item?.detail?.valeurTypeProprietaire?.designation,
                imputation: item?.detail?.valeurTypeEntite?.designation,
                type: getType(item?.detail?.typeEntite),
                entree: item?.entree || 0,
                entreeRef:
                    (item?.detail?.devise === deviseReference.code
                        ? item.entree
                        : Div(item.entree, taux?.valeur || 1)) || 0,
                sortie: item?.sortie || 0,
                sortieRef:
                    (item?.detail?.devise === deviseReference.code
                        ? item.sortie
                        : Div(item.sortie, taux?.valeur || 1)) || 0,
                resultat: item?.total || 0,
                resultatRef:
                    (item?.detail?.devise === deviseReference.code
                        ? item.total
                        : Div(item.total, taux?.valeur || 1)) || 0,
                devise: item?.detail?.devise
            }));
            const getDevises = Object.keys(data[0].devises || {}).sort();
            const elements = Array.groupByProperty({
                array: (allData || []).map((item) => ({
                    organisation:
                        item?.detail?.valeurTypeProprietaire?.designation,
                    imputation: item?.detail?.valeurTypeEntite?.designation,
                    type: getType(item?.detail?.typeEntite),
                    entree: item?.entree || 0,
                    entreeRef:
                        (item?.detail?.devise === deviseReference.code
                            ? item.entree
                            : Div(item.entree, taux?.valeur || 1)) || 0,
                    sortie: item?.sortie || 0,
                    sortieRef:
                        (item?.detail?.devise === deviseReference.code
                            ? item.sortie
                            : Div(item.sortie, taux?.valeur || 1)) || 0,
                    resultat: item?.total || 0,
                    resultatRef:
                        (item?.detail?.devise === deviseReference.code
                            ? item.resultat
                            : Div(item.resultat, taux?.valeur || 1)) || 0,
                    devise: item?.detail?.devise
                })),
                property: 'devise'
            });

            return (
                <>
                    <div className="mt-5 lead fw-bold">Tableau Synthèse</div>
                    <table className="table border-top table-hover table-striped table-responsive-sm">
                        <thead className="lead">
                            <tr className="border text-center text-justify">
                                <th className="border">#</th>
                                <th className="border">Entrée</th>
                                <th className="border">Sortie</th>
                                <th className="border">Résultat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getDevises.map((item) => (
                                <tr key={item} className="lead fw-bold border">
                                    <td className="border">Total {item}</td>
                                    {mainMapper
                                        .map((mainItem, index) => ({
                                            ...mainItem,
                                            position: index + 1
                                        }))
                                        .sort((a, b) => a.position - b.position)
                                        .map((mainItem) => {
                                            if (
                                                !mainItem?.isTotal &&
                                                !mainItem?.isCustomTotal
                                            )
                                                return null;
                                            return (
                                                <td className="text-right font-weight-bold border">
                                                    {formatNumber({
                                                        value: (
                                                            elements?.[item] ||
                                                            []
                                                        )?.reduce(
                                                            (a, b) =>
                                                                Add(
                                                                    a,
                                                                    resolvePathPropertyOfObject(
                                                                        `${mainItem.field}`,
                                                                        b
                                                                    )
                                                                ),
                                                            0
                                                        )
                                                    })}
                                                </td>
                                            );
                                        })}
                                </tr>
                            ))}
                            <tr className="lead fw-bold border">
                                <td className="border">Total Général</td>
                                {mainMapper
                                    .map((mainItem, index) => ({
                                        ...mainItem,
                                        position: index + 1
                                    }))
                                    .sort((a, b) => a.position - b.position)
                                    .map((mainItem) => {
                                        if (
                                            !mainItem?.isTotal &&
                                            !mainItem?.isCustomTotal
                                        )
                                            return null;
                                        return (
                                            <td className="text-right font-weight-bold border">
                                                {formatNumber({
                                                    value: (
                                                        dataMappeed || []
                                                    )?.reduce(
                                                        (a, b) =>
                                                            Add(
                                                                a,
                                                                resolvePathPropertyOfObject(
                                                                    `${mainItem.refField}`,
                                                                    b
                                                                )
                                                            ),
                                                        0
                                                    )
                                                })}
                                            </td>
                                        );
                                    })}
                            </tr>
                        </tbody>
                    </table>
                </>
            );
        },
        [deviseReference.code, mapper, taux?.valeur]
    );

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                dynamicBottom={dynamicBottom}
                isGroupedRow
                cutomTableHeader={cutomTableHeader}
                tablesRender={syntheseTable}
                listTitle={titleToRender(fields.selectedSelector.code)}
                dynamicNodeName={`evolutionsRegularisationProduit-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={mapper()}
                // isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: 'Imputation',
                    // estObjet: true,
                    estOrganisation: true,
                    estAllOrganisation: true,
                    estNotDate: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: 'Imputation',
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer', 'VenteRevendeur'],
                    estOrganisation: true,
                    estAllOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        isSort={false}
                        options={[
                            { code: 'USD', designation: 'USD' },
                            { code: 'CDF', designation: 'CDF' }
                        ]}
                        label="Valeur : "
                        defaultOption="Selectionner une valeur"
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedSelector"
                        id="selectedSelectortypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
