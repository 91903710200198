import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import { Add } from '@napp-inc/jnapp-util';
import {
    IoIosAddCircleOutline
    // IoIosArrowDropdown,
    // IoIosArrowDropup
} from 'react-icons/io';
import {
    CustomLiteDynamicDateInput,
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
} from '../../../../../../components';

export function PeriodesRowSelector({
    index,
    detailIndex,
    periodeIndex,
    beneficiaireIndex,
    periodeItem,
    handleSelectChange,
    periodes,
    handleChange,
    // onToggleView,
    handleRemove,
    handleAddHighLevel,
    formFields,
    setFormFields,
    charges,
    isViewDetailAction
}) {
    return (
        <div className="d-flex flex-row align-items-center justify-content-center">
            <div
                style={{
                    height: '46px',
                    width: '16px'
                }}
            >
                <p />
            </div>
            {/* Periode */}
            <CustomLiteDynamicDropdown
                containerClassName="col-2 me-3"
                ariaLabel="Periode Selector"
                name="dateDebut|dateFin"
                customproperty="periodes"
                value={periodeItem?.dateDebut}
                options={periodes}
                hiddenOption="Selectionnez un période"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: beneficiaireIndex,
                    id: periodeIndex,
                    setFormFields,
                    formFields,
                    periodes
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Période"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Date Paiement */}
            <CustomLiteDynamicDateInput
                containerClassName="col-3 me-3"
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Date de paiement"
                inputClassName="form-control form-control-sm col-3"
                ariaLabel="date paiement"
                name="datePaiementString"
                customproperty="datePaiement"
                value={periodeItem.datePaiementString}
                onChange={handleChange}
                inputProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: beneficiaireIndex,
                    id: periodeIndex,
                    setFormFields,
                    formFields
                }}
            />
            <CustomLiteDynamicInput
                placeholder="Saississez un nombre"
                containerClassName="col-2 me-3"
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label={`${
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? `${
                              isViewDetailAction
                                  ? `Nouvelle Quantité ${Add(
                                        periodeItem.ancienneQuantite,
                                        periodeItem.quantite
                                    )}`
                                  : 'Quantité'
                          }`
                        : `${
                              isViewDetailAction
                                  ? `Nouveau Montant ${Add(
                                        periodeItem.ancienMontant,
                                        periodeItem.montant
                                    )}`
                                  : 'Montant'
                          }`
                }`}
                inputClassName="form-control form-control-sm col-2"
                ariaLabel="Amount and Quantity Input"
                name={`${
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? 'quantite'
                        : 'montant'
                }`}
                value={
                    charges.find(
                        (charge) =>
                            charge.code === formFields[index].element.code
                    )?.estQuantite
                        ? periodeItem.quantite
                        : periodeItem.montant
                }
                onChange={handleChange}
                inputProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: beneficiaireIndex,
                    id: periodeIndex,
                    setFormFields,
                    formFields
                }}
            />
            <div className="col-auto pt-2 me-3">
                <AiFillMinusCircle
                    role="button"
                    title="Supprimer Informations de la Période"
                    className="text-secondary fs-5"
                    onClick={() =>
                        handleRemove({
                            index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: beneficiaireIndex,
                            id: periodeIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto me-3 pt-2">
                <IoIosAddCircleOutline
                    role="button"
                    title="Ajouter Période"
                    className="text-primary fs-5"
                    onClick={() =>
                        handleAddHighLevel({
                            type: 'Periodes',
                            ancestorId: index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: beneficiaireIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            {/* <div className="col-auto pt-2">
                {formFields[index].details[detailIndex].beneficiaires[beneficiaireIndex].periodes[periodeIndex]
                    .isTooggled ? (
                    <IoIosArrowDropup
                        className="fs-5"
                        title="Regrouper les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields[index].details[detailIndex]
                                    .periodes[periodeIndex].mois,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: beneficiaireIndex,
                                id: periodeIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                ) : (
                    <IoIosArrowDropdown
                        className="fs-5"
                        title="Afficher les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields[index].details[detailIndex]
                                    .periodes[periodeIndex].mois,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: beneficiaireIndex,
                                id: periodeIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                )}
            </div> */}
        </div>
    );
}
