import {
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail,
    useIsLoadComplete
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    ONLY_FETCH,
    ONLY_REMOVING
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSearchFilter } from '../../util/hook';
import { CustomAlert } from '../alert';
import { CustomForm } from '../form';
import { CenteredSpinner } from '../placeholder';
import { SimpleTable } from '../table';

/**
 * Composant qui affiche une liste des data venant de la bd.
 * @param {Object} param0 objet avec les paramètres suivant
 * @param {String} param0.reduxPropertyName nom de la propriété dans le store redux
 * @param {String} param0.functionName : nom de la fonction à appeler
 * @param {String} param0.nodeName : identifiant unique du noeud dans redux
 * @param {String} param0.fetchType facon dont les données doivent être recuperer par defaut ONLY_FETCH
 * @param {String} param0.identifier id utiliser pour la key dans le composant simple table
 * @param {Array} param0.mapper tableau permettant de faire la correspondance entre les éléments de la liste et les colonnes devant être affichées
 * @param {Array} param0.searchFiter tableau des keys à filtrer, tableau d'objet avec la propriété "field"
 * @param {Array} param0.exportMapper mapper alternatif pour l'exportation vers un classeur
 * @param {Boolean} param0.isExportAllowed s'il faut afficher le bouton d'exportation (true par défaut)
 * @param {Boolean} param0.isLinkToDetail s'il faut donner la possiblité ou pas de cliquer sur une ligne du formulaire afin d'être ramené vers le formulaire détail
 * @param {Boolean} param0.isSenderFlash s'il faut afficher ou pas les commandes qu'un sender peut effectuer une ligne de la liste (pour les commandes flash)
 * @param {Boolean} param0.isSenderEmoney s'il faut afficher ou pas les commandes qu'un sender peut effectuer une ligne de la liste (pour les commandes emoney)
 * @param {String} param0.fileName nom du fichier à exporter
 * @param {String} param0.sheetName nom de la feuille dans le fichier exporté
 * @param {String} param0.format format du fichier exporté (xlsx par défaut)
 * @param {Function} param0.filterDataList fonction qui trie les éléments du dataList récuperer dans redux,
 * elle est passée comme callback à la fucntion filter, si elle est définie
 * le reste des propriétés sont ceux demandées par le hook @alias useFetchAndListening
 * @param {Boolean} param0.isRefetchAllowed s'il faut afficher le button refetch
 * @param {Boolean} param0.isSweet s'il faut afficher le tableau en mode sweet
 * @param {String} param0.sweetTableTitle titre du tableau sweet
 * @param {String} param0.sweetTableType type du tableau sweet
 * @param {String} param0.listTitle titre du tableau
 * @param {Boolean} param0.bottomPagination s'il faut afficher la pagination en bas du tableau
 * @param {Boolean} param0.isRowSelector s'il faut afficher la checkbox pour selectionner une ligne
 * @param {Boolean} param0.viewSender s'il faut afficher la colonne sender
 * @param {Boolean} param0.filter s'il faut afficher le filtre
 * @param {Function} param0.trToAddOnTop function pour une (des) ligne en haut
 * @param {Function} param0.trToAddOnBottom function pour une (des) ligne en bas
 * @param {String} param0.refetchFunctionName : nom de la fonction à appeler pour le refetchm si différente de function name
 * @returns
 */

const defaultFields = {
    search: '',
    actionEncours: ''
};

export function ListGenericForm({
    reduxPropertyName,
    functionName,
    nodeName,
    path,
    isDynamicNode,
    dynamicNodeName,
    listenerFilters,
    removeFilters,
    isDocument,
    id,
    payload,
    formater,
    onSuccess,
    onError,
    reduxValidator,
    mapper = [],
    exportMapper,
    fetchType = ONLY_FETCH,
    identifier = 'id',
    isUrl = false,
    downloadFileFunction,
    isColumnClickable,
    isExportAllowed = true,
    fileName,
    sheetName,
    format,
    isSenderFlash,
    isSenderEmoney,
    isLinkToDetail = true,
    filterDataList,
    isRefetchAllowed = true,
    refetchFunctionName,
    searchFiter,
    isSweet = false,
    sweetTableTitle,
    sweetTableType,
    trToAddOnTop,
    trToAddOnBottom,
    tfootTrToAdd,
    listTitle = '',
    bottomPagination = true,
    isRowSelector = false,
    viewSender,
    isTwice = true,
    filter = true,
    listFormater,
    cutomTableHeader,
    isAddTotalTr,
    transformExportDataFunction,
    customTotal,
    notFormatedData,
    dynamicMapper,
    dynamicBottom,
    tablesRender,
    renderSpecificly,
    filterComplement
}) {
    const { pathname } = useLocation();
    const reduxDispatcher = useDispatch();
    const { firebaseUser, dataList, etatRedux } = useSelector((reduxState) => {
        let reduxData;
        let etatExtrated;
        if (isDynamicNode) {
            reduxData = reduxState.dynamicNode?.[dynamicNodeName];
            etatExtrated = reduxState?.dynamicEtatNode?.[dynamicNodeName];
        } else {
            reduxData = reduxState[reduxPropertyName];
            etatExtrated = reduxState?.etat[reduxPropertyName];
        }
        if (listFormater && typeof listFormater === 'function') {
            return {
                dataList: filterDataList
                    ? listFormater(reduxData).filter(filterDataList)
                    : listFormater(reduxData),
                etatRedux: etatExtrated,
                firebaseUser: reduxState.firebaseUser
            };
        }
        return {
            dataList: filterDataList
                ? reduxData.filter(filterDataList)
                : reduxData,
            etatRedux: etatExtrated,
            firebaseUser: reduxState.firebaseUser
        };
    });
    const {
        etat: etatDataList,
        ecouteur: ecouteurDataList,
        ecouteurRemoving: ecouteurRemovingDataList,
        error: errorDataList
    } = etatRedux || {};
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const propertyToAddToList = {};
    if (!isDynamicNode) {
        propertyToAddToList.nodeName = nodeName;
    }
    const list = [
        {
            ...propertyToAddToList,
            fetchType,
            functionName,
            etat: etatDataList,
            ecouteur: ecouteurDataList,
            path,
            isDynamicNode,
            dynamicNodeName,
            listenerFilters,
            isDocument,
            id,
            payload,
            formater,
            onSuccess,
            reduxValidator,
            isSet: true
        }
    ];
    if (removeFilters?.length) {
        list.push({
            ...propertyToAddToList,
            fetchType: ONLY_REMOVING,
            removeFilters,
            ecouteurRemoving: ecouteurRemovingDataList,
            path,
            isDynamicNode,
            dynamicNodeName,
            reduxValidator,
            isSet: true
        });
    }
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list
    });
    const filteredDataList = useSearchFilter({
        dataList,
        search: formState.fields.search,
        mapper,
        searchFiter
    });
    const { isLoadComplete, isLoadFailed } = useIsLoadComplete({
        etatChargement: etatDataList
    });
    const renderContent = () => {
        if (!isLoadComplete && !isLoadFailed) {
            return <CenteredSpinner />;
        }
        if (isLoadFailed) {
            return (
                <CustomAlert
                    error={errorDataList}
                    isRefetchAllowed
                    functionName={refetchFunctionName || functionName}
                    nodeName={nodeName}
                    isDynamicNode={isDynamicNode}
                    dynamicNodeName={dynamicNodeName}
                    payload={payload}
                    formater={formater}
                    onError={onError}
                />
            );
        }
        if (dataList?.length < 1) {
            return (
                <CustomAlert
                    infoMessage="Aucune donnée à afficher pour l'instant"
                    isRefetchAllowed
                    functionName={refetchFunctionName || functionName}
                    nodeName={nodeName}
                    isDynamicNode={isDynamicNode}
                    dynamicNodeName={dynamicNodeName}
                    payload={payload}
                    formater={formater}
                    onError={onError}
                />
            );
        }
        return (
            <CustomForm>
                <SimpleTable
                    notFormatedData={notFormatedData}
                    dynamicMapper={dynamicMapper}
                    dynamicBottom={dynamicBottom}
                    filterComplement={filterComplement}
                    filter={filter}
                    isTwice={isTwice}
                    fileName={fileName}
                    sheetName={sheetName}
                    format={format}
                    isActionActive={false}
                    isRefetchAllowed={isRefetchAllowed}
                    onError={onError}
                    functionName={refetchFunctionName || functionName}
                    isLinkToDetail={isLinkToDetail}
                    isColumnClickable={isColumnClickable}
                    isShowDropButton={false}
                    pathname={isLinkToDetail ? pathname : ''}
                    data={filteredDataList}
                    dataList={dataList}
                    identifier={identifier}
                    mapper={mapper}
                    exportMapper={exportMapper}
                    // mapper={mapperToSend(exportMapper, mapper)}
                    isUrl={isUrl}
                    downloadFileFunction={downloadFileFunction}
                    isSenderFlash={isSenderFlash}
                    isSenderEmoney={isSenderEmoney}
                    nodeName={nodeName}
                    trToAddOnTop={trToAddOnTop}
                    trToAddOnBottom={trToAddOnBottom}
                    tfootTrToAdd={tfootTrToAdd}
                    bottomPagination={bottomPagination}
                    payload={payload}
                    isDynamicNode={isDynamicNode}
                    dynamicNodeName={dynamicNodeName}
                    formater={formater}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    isRowSelector={isRowSelector}
                    idToken={idToken}
                    reduxDispatcher={reduxDispatcher}
                    viewSender={viewSender}
                    tableClass={`${
                        isSweet
                            ? 'table table-hover table-md table-borderless table-striped'
                            : 'table border-top table-hover table-striped table-responsive-sm'
                    }`}
                    isSweet={isSweet}
                    sweetTableTitle={sweetTableTitle}
                    sweetTableType={sweetTableType}
                    listTitle={listTitle}
                    isExportAllowed={isExportAllowed}
                    cutomTableHeader={cutomTableHeader}
                    isAddTotalTr={isAddTotalTr}
                    transformExportDataFunction={transformExportDataFunction}
                    customTotal={customTotal}
                    tablesRender={tablesRender}
                    renderSpecificly={renderSpecificly}
                />
            </CustomForm>
        );
    };
    return renderContent();
}
