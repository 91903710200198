import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import { DB_NODE, FORM_CONST, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, formaterCaisses } from '../../../../util';

export function RegularisationCaisseForm({
    idToken,
    initialState,
    uiValidator,
    cfPayload,
    cfCondition,
    typeEntite
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        imputationsCaisse,
        etatImputationCaisse,
        caisses,
        etatCaisse,
        superDealerKinDB,
        etatSuperDealerKinDB,
        dynamicNode,
        dynamicEtatNode
    } = useSelector((reduxState) => ({
        imputationsCaisse: reduxState.imputationsCaisseOrganisation,
        etatImputationCaisse:
            reduxState.etat.imputationsCaisseOrganisation.etat,
        caisses:
            formaterCaisses(reduxState?.[cfPayload.caisseReduxProperty]) || [],
        etatCaisse: reduxState?.etat?.[cfPayload.caisseReduxProperty]?.etat,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat,
        dynamicNode: reduxState.dynamicNode,
        dynamicEtatNode: reduxState.dynamicEtatNode
    }));

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseNodeName,
                payload: cfPayload?.caisses,
                etat: etatCaisse,
                isCondition: !!cfPayload.caisseNodeName
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_ORGANISATION,
                nodeName: REDUX_NODE_NAME.IMPUTATION_CAISSE_ORGANISATION,
                etat: etatImputationCaisse,
                isCondition:
                    !cfCondition?.isAll && !cfPayload.imputationDynamicNode
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: cfPayload?.superDealer,
                isCondition: !!cfCondition?.isAll
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_IMPUTATION_ORGANISATION,
                dynamicNodeName: cfPayload.imputationDynamicNode,
                isDynamicNode: true,
                etat: dynamicEtatNode[cfPayload.imputationDynamicNode]?.etat,
                payload: cfPayload.imputation,
                isCondition:
                    !!cfCondition?.isAll && !!cfPayload.imputationDynamicNode
            }
        ]
    });

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const getImputationByOrganisation = useCallback(() => {
        if (
            typeEntite === TYPE_ORGANISATION.NAPP ||
            typeEntite === TYPE_ORGANISATION.REVENDEUR
        ) {
            if (fields?.selectedSuperDealer?.code === 'AUTRES') {
                return dynamicNode?.[cfPayload.imputationDynamicNode]?.filter(
                    (item) =>
                        item?.typeEntite !== TYPE_ORGANISATION.SUPER_DEALER
                );
            }
            if (
                fields?.selectedSuperDealer?.code &&
                fields?.selectedSuperDealer?.code !== 'AUTRES'
            ) {
                return dynamicNode?.[cfPayload.imputationDynamicNode]?.filter(
                    (item) =>
                        item?.valeurTypeEntite?.code ===
                        fields?.selectedSuperDealer?.code
                );
            }
        }
        return [];
    }, [
        cfPayload.imputationDynamicNode,
        dynamicNode,
        fields?.selectedSuperDealer?.code,
        typeEntite
    ]);

    const getCaisseByOrganisation = useCallback(() => {
        if (
            typeEntite === TYPE_ORGANISATION.NAPP ||
            typeEntite === TYPE_ORGANISATION.REVENDEUR
        ) {
            if (fields?.selectedSuperDealer?.code === 'AUTRES') {
                return caisses.filter(
                    (item) => item.typeEntite !== TYPE_ORGANISATION.SUPER_DEALER
                );
            }
            if (
                fields?.selectedSuperDealer?.code &&
                fields?.selectedSuperDealer?.code !== 'AUTRES'
            ) {
                return caisses.filter(
                    (item) =>
                        item?.valeurTypeEntite?.code ===
                            fields?.selectedSuperDealer?.code ||
                        item?.superDealer?.code ===
                            fields?.selectedSuperDealer?.code
                );
            }
        }
        return [];
    }, [caisses, fields?.selectedSuperDealer?.code, typeEntite]);

    const getTypeCaisse = useCallback(
        () =>
            caisses?.find(
                (element) => element?.id === fields?.selectedCaisse?.id
            ),
        [caisses, fields.selectedCaisse.id]
    );

    const getTypeImputation = useCallback(() => {
        const data = cfPayload.imputationDynamicNode
            ? getImputationByOrganisation()
            : imputationsCaisse;
        return data?.find(
            (element) => element?.code === fields?.selectedImputation?.code
        );
    }, [
        cfPayload.imputationDynamicNode,
        fields?.selectedImputation?.code,
        getImputationByOrganisation,
        imputationsCaisse
    ]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            selectedCaisse,
            selectedImputation,
            montant,
            selectedSuperDealer
        } = fields;
        if (montant === 0) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations du formulaire`,
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            idCaisse: selectedCaisse.id,
            codeImputation: selectedImputation.code,
            montant
        };
        /** Insertion code superDealer uniquement si code existe et est !== AUTRES */
        if (
            selectedSuperDealer?.code &&
            selectedSuperDealer?.code !== 'AUTRES'
        ) {
            payload.codeSuperDealer = selectedSuperDealer.code;
        }
        /** Insertion numeroReference */
        if (getTypeCaisse()?.estEmoney) {
            payload.numeroReference = fields.numeroReference;
            payload.estNumeroReferenceFictif = fields.isFictif;
        }

        /** Insertion estServiDoublement */
        if (
            getTypeCaisse()?.estEmoney &&
            getTypeImputation()?.typeOperation === 'SORTIE' &&
            fields?.selectedRaison?.code === 'DOUBLEMENT_SERVI'
        ) {
            payload.estServiDoublement = true;
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_REGULARISATION_CAISSE,
                payload,
                fields,
                reduxNodeName: DB_NODE.REGULARISATION_CAISSE,
                reduxDispatcher,
                uiValidator
            })
        );
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisse,
                    isCondition: !!cfPayload.caisseNodeName
                },
                {
                    dependency: 'imputations',
                    etat: etatImputationCaisse,
                    isCondition:
                        !cfCondition?.isAll && !cfPayload.imputationDynamicNode
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition: !!cfCondition?.isAll
                },
                {
                    dependency: 'allImputation',
                    etat: dynamicEtatNode[cfPayload.imputationDynamicNode]
                        ?.etat,
                    isCondition:
                        !!cfCondition?.isAll &&
                        !!cfPayload.imputationDynamicNode
                }
            ]}
        >
            <CustomRow
                isShouldBeRendered={
                    (typeEntite === TYPE_ORGANISATION.NAPP ||
                        typeEntite === TYPE_ORGANISATION.REVENDEUR) &&
                    !!cfCondition?.isAll
                }
            >
                <CustomDropdown
                    isSort={false}
                    options={[
                        { code: 'AUTRES', designation: 'AUTRES' },
                        ...superDealerKinDB
                    ]}
                    defaultOption="Selectionner"
                    label="Organisation*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationOperationBancaireId"
                    // uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!cfCondition?.isAll && !!cfPayload.imputationDynamicNode
                }
            >
                <CustomDropdown
                    label="Imputation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir une imputation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedImputation"
                    id="creationRegularisationCaisseSelectedImputationId"
                    options={getImputationByOrganisation()}
                    disabled={!getImputationByOrganisation()?.length}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !cfCondition?.isAll && !cfPayload.imputationDynamicNode
                }
            >
                <CustomDropdown
                    label="Imputation*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir une imputation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedImputation"
                    id="creationRegularisationCaisseSelectedImputationId"
                    options={imputationsCaisse}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!cfCondition?.isAll && !!cfPayload.imputationDynamicNode
                }
            >
                <CustomDropdown
                    label="Caisse*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    idProperty="id"
                    defaultOption="Choisir la caisse"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedCaisse"
                    id="creationRegularisationCaisseSelectedCaisseId"
                    options={getCaisseByOrganisation()}
                    disabled={!getCaisseByOrganisation()?.length}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !cfCondition?.isAll && !cfPayload.imputationDynamicNode
                }
            >
                <CustomDropdown
                    label="Caisse*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    idProperty="id"
                    defaultOption="Choisir la caisse"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedCaisse"
                    id="creationRegularisationCaisseSelectedCaisseId"
                    options={caisses}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!getTypeCaisse()?.estEmoney &&
                    getTypeImputation()?.typeOperation === 'SORTIE'
                }
            >
                <CustomDropdown
                    label="Raison"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    defaultOption="Choisir la raison de la régularisation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedRaison"
                    id="creationRegularisationCaisseSelectedRaisonId"
                    options={[
                        {
                            code: 'AUCUNE',
                            designation: 'Aucune'
                        },
                        {
                            code: 'DOUBLEMENT_SERVI',
                            designation: 'Doublement Servi'
                        },
                        {
                            code: 'AUTRE',
                            designation: 'Autre raison'
                        }
                    ]}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!getTypeCaisse()?.estEmoney}>
                <CustomInput
                    type="text"
                    label="Numéro Référence* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder={`Référence ${
                        getTypeImputation()?.typeOperation === 'ENTREE'
                            ? 'Dépôt'
                            : 'Retrait'
                    }`}
                    formDispatcher={formDispatcher}
                    name="numeroReference"
                    id="creationNumeroReferenceCreationTransfertInterInstitutionnelId"
                    formState={formState}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!getTypeCaisse()?.estEmoney &&
                    typeEntite === TYPE_ORGANISATION.NAPP
                }
            >
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estNumeroFictifId"
                    text="Numéro Fictif?"
                    value={fields.isFictif}
                    name="isFictif"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    label="Montant*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    type="number"
                    isFloat
                    isInputGroup={false}
                    placeholder="Saisissez le montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="quantiteCreationRegularisationCaisseEmoneyMontantID"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
