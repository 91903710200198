/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import {
    Array,
    CODE_SERVICE,
    formInitialState,
    getMonthByDate
} from '@napp-inc/jnapp-util';
import { useLocation } from 'react-router-dom';
import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
// import { FaRegEdit } from 'react-icons/fa';
import {
    CustomDropdownLite,
    ListGenericForm,
    NumberDisplayer,
    ShowNewComponent
} from '../../../../components';
import { URL_CONST } from '../../../../util';

const defaultFields = {
    selectedPeriode: { code: 'ALL', designation: 'Tous' },
    isShowDetail: false
};

export function ElaborationAmendementTableSynthese({
    id,
    destination,
    entity /** payloadUrl */
}) {
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search.replace('?', ''));
    /**
     * Common form processes
     */
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields } = formState;

    const periodes = [
        { code: 'janvier', designation: 'Janvier' },
        { code: 'février', designation: 'Février' },
        { code: 'mars', designation: 'Mars' },
        { code: 'avril', designation: 'Avril' },
        { code: 'mai', designation: 'Mais' },
        { code: 'juin', designation: 'Juin' },
        { code: 'juillet', designation: 'Juillet' },
        { code: 'août', designation: 'Août' },
        { code: 'septembre', designation: 'Septembre' },
        { code: 'octobre', designation: 'Octobre' },
        { code: 'novembre', designation: 'Novembre' },
        { code: 'décembre', designation: 'Décembre' },
        { code: 'ALL', designation: 'Tous' }
    ];

    const regrouperDataByDirection = useCallback((data = []) => {
        const groupedData = Array.groupByProperty({
            array: data.map((item) => ({
                direction: item?.direction?.designation,
                secteur: item?.secteur?.designation,
                codeSecteur: item?.secteur?.code
            })),
            property: 'direction'
        });
        const getDirectionKeys = Object.keys(groupedData);
        const directions = [];
        const secteurRows = [];
        getDirectionKeys.forEach((item) => {
            const secteurs = [];
            groupedData[item].forEach((groupedItem) => {
                if (
                    !secteurs.find(
                        (element) => element.secteur === groupedItem.secteur
                    )
                ) {
                    secteurs.push(groupedItem);
                }
            });
            directions.push({ direction: { designation: item }, secteurs });
        });
        directions.filter((element) => {
            if (element.secteurs) {
                secteurRows.push(...element.secteurs);
            }
            return null;
        });
        return { top: getDirectionKeys, second: directions, secteurRows };
    }, []);

    const getData = (row, item) =>
        row?.mergeData?.[item?.codeSecteur]?.previsionnel || 0;

    const groupDataByPeriode = useCallback((data) => {
        const dataToRender = [];
        data.forEach((item, index, array) => {
            const newElement = {};
            const filteredElement = array.filter(
                (element) =>
                    item?.valeurTypeProprietaire?.designation ===
                    element?.valeurTypeProprietaire?.designation
            );
            const findedElement = dataToRender.find(
                (element) =>
                    item?.valeurTypeProprietaire?.designation ===
                    element?.valeurTypeProprietaire?.designation
            );
            if (filteredElement.length && !findedElement) {
                filteredElement.forEach((elm) => {
                    newElement[elm?.secteur?.code] = {
                        secteur: elm?.secteur,
                        previsionnel:
                            (newElement?.[elm?.secteur?.code]?.previsionnel ||
                                0) + (elm?.previsionnel || 0),
                        quantite:
                            (newElement?.[elm?.secteur?.code]?.quantite || 0) +
                            (elm?.quantite || 0)
                    };
                });
                dataToRender.push({ ...item, mergeData: newElement });
            }
        });
        return dataToRender;
    }, []);

    const getDataByPeriode = useCallback(
        (data) => {
            if (fields?.selectedPeriode?.code !== 'ALL') {
                return data.filter(
                    (item) =>
                        item?.periodeDesignation ===
                        fields?.selectedPeriode?.code
                );
            }
            return data;
        },
        [fields?.selectedPeriode?.code]
    );

    const bodyToRender = useCallback(
        ({ data = [] }) => {
            const mappedData = data.map((item) => ({
                ...item,
                periodeDesignation: getMonthByDate(item?.periode?.dateDebut)
            }));
            const elements = getDataByPeriode(mappedData);
            const dataJoined = groupDataByPeriode(elements);
            const { second: headers, secteurRows: secondRows } =
                regrouperDataByDirection(data);
            return (
                <table className="table table table-bordered border-top table-hover table-striped table-responsive-sm">
                    <thead className="text-center">
                        <tr>
                            <th rowSpan={2}>Charge</th>
                            <th rowSpan={2}>Devise</th>
                            {headers.map((head, index) => (
                                <th
                                    key={index}
                                    colSpan={(head?.secteurs || [])?.length}
                                >
                                    {head?.direction?.designation}
                                </th>
                            ))}
                            <th rowSpan={2}>Actions</th>
                        </tr>
                        {secondRows.length ? (
                            <tr>
                                {secondRows.map((row, index) => (
                                    <th key={`${row.secteur}${index}`}>
                                        {row.secteur}
                                    </th>
                                ))}
                            </tr>
                        ) : null}
                    </thead>
                    <tbody>
                        {dataJoined.map((row, index) => (
                            <tr
                                key={`${row?.valeurTypeProprietaire?.designation}${index}`}
                            >
                                <td>
                                    {row?.valeurTypeProprietaire?.designation}
                                </td>
                                <td>{row?.devise}</td>
                                {secondRows.map((item, indexSeconRow) => (
                                    <td
                                        key={indexSeconRow}
                                        className="text-end"
                                    >
                                        <NumberDisplayer
                                            value={getData(row, item)}
                                            nombreApresVirgule={2}
                                        />
                                    </td>
                                ))}
                                <td className="text-center">
                                    <ShowNewComponent
                                        services={[
                                            { code: 'test' },
                                            CODE_SERVICE
                                                .CODE_SERVICE_MODULE_BUDGET
                                                .INITIER_ELABORATION_AMENDEMENT
                                        ]}
                                        destination={`${pathname}/${destination}?start=${Number(
                                            searchParams.get('start')
                                        )}&end=${Number(
                                            searchParams.get('end')
                                        )}&charge=${
                                            row.valeurTypeProprietaire.code
                                        }`}
                                        text="Modifier"
                                        className="btn btn-outline-dark"
                                        icon={<div />}
                                        title="Modifier Charge"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            getDataByPeriode,
            groupDataByPeriode,
            pathname,
            regrouperDataByDirection
        ]
    );

    const renderContent = () => {
        if (id && entity) {
            return (
                <ListGenericForm
                    filterComplement={
                        <div className="ms-3">
                            <CustomDropdownLite
                                isSort={false}
                                selectionClassName="form-select-sm col-3"
                                required
                                idProperty="code"
                                defaultOption="Période"
                                propertyToDisplay="designation"
                                id="exerciceLiteSelectorId"
                                formDispatcher={formDispatcher}
                                formState={formState}
                                name="selectedPeriode"
                                options={periodes}
                            />
                        </div>
                    }
                    bottomPagination={false}
                    renderSpecificly={(data) => bodyToRender({ data })}
                    isDynamicNode
                    dynamicNodeName={id}
                    payload={{
                        reference: id,
                        property: 'idElaborationAmendement',
                        collection: 'ElaborationAmendementPeriode'
                    }}
                    functionName={
                        URL_CONST.GET_LIST_ELEMENT_ELABORATION_AMENDEMENT
                    }
                    mapper={[
                        {
                            displayName: 'Charge',
                            field: 'valeurTypeProprietaire.designation'
                        },
                        {
                            displayName: 'Création',
                            field: 'dateCreation'
                        }
                    ]}
                />
            );
        }
        return (
            <div
                className="alert alert-primary d-flex align-items-center"
                role="alert"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                <div>Veuillez reprendre le processus depuis le debut !</div>
            </div>
        );
    };

    return renderContent();
}
